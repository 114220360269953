import { useAPIRequest } from 'dataProvider/postStateData/requestStateData';

/**
 * Change root admin of client
 * @returns [submit, state]
 */
export function useAPIChangeRootAdmin() {
    const { put, states } = useAPIRequest();

    const requestKey = 'system.client.changeroot';
    const submit = (id, data) =>
        put(requestKey, `/clients/${id}/update-root-admin`, data, x => x, {
            // TODO: Translate this
            loading: 'Updating Root Admin',
            success: 'Updating Root Admin  Succeed',
            error: 'Updating Root Admin Failed',
        });

    return [submit, states(requestKey)];
}
