import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const useRouteCallback = () => {
    const { search, pathname } = useLocation();
    const parsedQs = useMemo(() => queryString.parse(search), [search]);

    const shouldBeCallback = useMemo(() => {
        return pathname;
    }, [pathname]);

    const callbackUrl = useMemo(() => {
        return parsedQs.callback;
    }, [parsedQs]);

    // Set Current Page as Callback Parameter
    const setThisPageAsCallback = _url => {
        const { url, query } = queryString.parseUrl(_url);
        const qs = queryString.stringify({ ...query, callback: shouldBeCallback });
        return url + (qs ? `?${qs}` : '');
    };

    // Add callback parameter to the url
    const addCallbackToUrl = _url => {
        const { url, query } = queryString.parseUrl(_url);
        const qs = queryString.stringify({ ...query, callback: callbackUrl });
        return url + (qs ? `?${qs}` : '');
    };

    return {
        callbackUrl,
        setThisPageAsCallback,
        addCallbackToUrl,
    };
};
