import { toast } from 'react-toastify';

export const showOrUpdateToast = (toastId, toastContent, options) => {
    if (toastId) {
        toast.update(toastId, {
            render: toastContent,
            ...options,
        });
        return toastId;
    } else {
        return toast(toastContent, options);
    }
};
