import React from 'react';
import Page from 'templates/Page';
import { useHistory } from 'react-router-dom';

export default function Page404() {
    const history = useHistory();
    const handleClick = () => {
        history.goBack();
    };

    return (
        <Page className="page page-404">
            <div className="message-wrapper">
                <p>お探しのページは見つかりませんでした</p>
                <button className="btn btn-secondary" onClick={handleClick}>
                    前のページに戻る
                </button>
            </div>
        </Page>
    );
}
