import Posts from 'components/icons/Posts';
import PostBox from 'components/icons/PostBox';
import Member from 'components/icons/Member';
import SetUp from 'components/icons/SetUp';
import NewPost from 'components/icons/NewPost';
import Whistleblower from 'components/icons/Whistleblower';
import UserGroup from 'components/icons/UserGroup';
import Division from 'components/icons/Division';
import Account from 'components/icons/Account';
import ExternalPost from 'components/icons/ExternalPost';

// 並び順注意
const HEADER_DATA = [
    { route: '/login', title: 'ログイン', Icon: null },
    { route: '/logout', title: 'ログアウト', Icon: null },
    { route: '/register', title: '新規登録', Icon: null },
    { route: '/posts/create', title: '投稿', Icon: Posts },
    { route: '/posts', title: '投稿', Icon: Posts },
    { route: '/reports/create', title: '新しい内部通報', Icon: NewPost },
    { route: '/reports', title: '内部通報', Icon: Whistleblower },
    { route: '/categories', title: 'カテゴリー', Icon: Division },
    { route: '/users', title: 'ユーザー', Icon: Member },
    { route: '/operators', title: '管理者', Icon: UserGroup },
    { route: '/roles', title: '権限', Icon: Division },
    { route: '/groups', title: 'グループ', Icon: Division },
    { route: '/contents', title: '運用方針', Icon: SetUp },
    { route: '/access_restrictions', title: 'IPアドレス制限', Icon: SetUp },
    { route: '/privacy_policy', title: 'プライバシーポリシー', Icon: SetUp },
    { route: '/transactions_law', title: '特定商取引法に基づく表記', Icon: SetUp },
    { route: '/templates', title: 'テンプレート', Icon: SetUp },
    { route: '/password-rules', title: 'セキュリティポリシー', Icon: SetUp },
    { route: '/announcements', title: 'アナウンス', Icon: SetUp },
    { route: '/profile', title: 'アカウント設定', Icon: Account },
    { route: '/clients', title: '企業', Icon: PostBox },
    { route: '/external-posts', title: '外部投稿', Icon: ExternalPost }
];

const getHeaderInfo = route => {
    return HEADER_DATA.find(({ route: _route }) => {
        return route.slice(0, _route.length) === _route;
    });
};

const NOTIFY_DATA = [
    '[通知] みんばこからのお知らせです。',
    '[通知] 〇〇で新しい3件の返信がありました。',
    '[通知] みんばこからのお知らせです。長いお知らせです。長いお知らせです。長いお知らせです。長いお知らせです。長いお知らせです。',
    '[通知] 〇〇で新しい3件の返信がありました。',
    '[通知] 〇〇で新しい3件の返信がありました。',
    '[通知] 〇〇で新しい3件の返信がありました。',
    '[通知] 〇〇で新しい3件の返信がありました。',
    '[通知] 〇〇で新しい3件の返信がありました。',
];

export { getHeaderInfo, NOTIFY_DATA };
