import React from 'react';

function HeaderHeadline(props) {
    const { label, icon } = props;
    return (
        <div className="headline">
            {icon}
            <h1 className="tool h5">{label}</h1>
        </div>
    );
}

export default HeaderHeadline;
