import useAPIData from 'hooks/useAPIData';
import { useAPIRequest } from 'dataProvider/postStateData/requestStateData';

/**
 * Get Notification List
 * @return [fetch, state]
 */
export function useAPINotificationList(dataMapper = x => x) {
    return useAPIData('/notifications', dataMapper, []);
}

/**
 * Set Notification Read
 * @return [fetch, state]
 */
export function useAPINotificationRead() {
    const { put, states } = useAPIRequest();

    const requestKey = 'user.notification.read';
    const submit = id => put(requestKey, `/notifications/${id}/read`, {}, x => x, null);

    return [submit, states(requestKey)];
}

/**
 * Read all notifications
 * @return [fetch, state]
 */
export function useAPINotificationReadAll() {
    const { put, states } = useAPIRequest();

    const requestKey = 'user.notification.readAll';
    const submit = () => put(requestKey, `/notifications/read-all`, {}, x => x, null);

    return [submit, states(requestKey)];
}
