import * as React from 'react';
import classNames from 'classnames';

export default function ArrowRight(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            width="1em"
            height="1em"
            viewBox="0 0 32 32"
            fill="none"
            {...others}
        >
            <path
                d="M16.7501 7L25.7501 16L16.7501 25"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
            <path
                d="M24.5 16H6.25"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
