import React from 'react';
import classNames from 'classnames';

export default function Button(props) {
    const { children, className, icon, ...others } = props;
    return (
        <button className={classNames('btn', className, !children && 'no-text')} {...others}>
            {icon && icon}
            {children}
        </button>
    );
}
