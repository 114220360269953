import temporaryUserPasswordMailText from '../../../resources/notificationTemplates/temporaryUserPasswordMailText';
import temporaryPasswordSMSText from '../../../resources/notificationTemplates/temporaryPasswordSMSText';
import temporaryAdminPasswordMailText from 'resources/notificationTemplates/temporaryAdminPasswordMailText';

export const EMAIL_WORD_COUNT = 1000;
export const SMS_WORD_COUNT = 70;

// バリデーション
export const CREATE_FORM_VALIDATION = ({
    name,
    email,
    phone_number,
    admin_email_template,
    admin_sms_template,
    disableInvitationByPhone,
}) => {
    return (
        /.+/.test(name) &&
        /.+/.test(email) &&
        (disableInvitationByPhone || /\d+/.test(phone_number)) &&
        0 < admin_email_template.length &&
        admin_email_template.length <= EMAIL_WORD_COUNT &&
        0 < admin_sms_template.length &&
        admin_sms_template.length <= SMS_WORD_COUNT
    );
};

// バリデーション
export const EDIT_FORM_VALIDATION = ({ name, route, admin_email_template, admin_sms_template }) => {
    return (
        /.+/.test(name) &&
        /.+/.test(route) &&
        admin_email_template.length <= EMAIL_WORD_COUNT &&
        admin_sms_template.length <= SMS_WORD_COUNT
    );
};

// フォームの初期値
export const CREATE_FORM_DEFAULT_VALUES = {
    name: '',
    route: '',
    email: '',
    phone_number: '',
    plan_id: 0,
    allowFileUpload: false,
    usernameType: 'both',
    notIncludeReportRole: false,
    disableCSVDownload: false,
    disableAccessRestriction: false,
    disableRoleManagement: false,
    disableReplyModification: false,
    disableAddAdmin: false,
    disableInvitationByPhone: true,
    disableUserGroup: false,
    disablePostClose: true,
    admin_email_template: temporaryAdminPasswordMailText,
    admin_sms_template: temporaryPasswordSMSText,
    user_email_template: temporaryUserPasswordMailText,
    user_sms_template: temporaryPasswordSMSText,
    categories_threshold: 0,
    users_threshold: 0,
};

export const UPDATE_FORM_DEFAULT_VALUES = {
    id: '',
    name: '',
    route: '',
    createdAt: '',
    updatedAt: '',
    plan_id: null,
    allowFileUpload: false,
    allowFileUploadLabel: '-',
    usernameType: 'both',
    usernameTypeLabel: '-',
    notIncludeReportRole: false,
    disableCSVDownload: false,
    disableAccessRestriction: false,
    disableRoleManagement: false,
    disableReplyModification: false,
    disableAddAdmin: false,
    disableInvitationByPhone: true,
    disableUserGroup: false,
    disablePostClose: true,
    admin_email_template: '',
    admin_sms_template: '',
    user_email_template: '',
    user_sms_template: '',
    categories_threshold: 0,
    client_cusers_threshold: 0,
};
