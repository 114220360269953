import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ClientsList from './ClientsList/ClientsList';
import ClientsCreate from './ClientsCreate/ClientsCreate';
import ClientsDetail from './ClientsDetail/ClientsDetail';
import relativePathHandler from 'utils/relativePathHandler';

export default function ClientsRoute(props) {
    const rel = relativePathHandler(props.match.url);

    return (
        <Switch>
            <Route exact path={rel('/')} component={ClientsList} />
            <Route exact path={rel('/create')} component={ClientsCreate} />
            <Route exact path={rel('/:clientId(\\d+)')} component={ClientsDetail} />
            <Redirect to="/404" />
        </Switch>
    );
}
