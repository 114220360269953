import * as React from 'react';
import classNames from 'classnames';

export default function CheckAll(props) {
    const { className, disabled, ...others } = props;
    return (
        <svg
            className={classNames('icon checkbox', className, disabled && 'disabled')}
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            {...others}
        >
            <rect y="0.5" width="18" height="18" rx="2" fill="currentColor" />
            <path
                d="M14.625 5L6.75 14L3.375 10.625"
                stroke="white"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
