import { useState } from 'react';
import { setJSONItem, getJSONItem, setJSONObject } from 'utils/manageLocalStorage';

const mergeFromLocalStorage = (data, storeAtLocalStorage) => {
    const entriesData = Object.keys(data).map(key => {
        if (storeAtLocalStorage.includes(key)) {
            return [key, getJSONItem(key) ?? data[key]];
        } else {
            return [key, data[key]];
        }
    });
    return Object.fromEntries(entriesData);
};

export default function useDataState(_init, storeAtLocalStorage = []) {
    let init = mergeFromLocalStorage(_init, storeAtLocalStorage);
    const [data, setData] = useState(init);
    const _setData = (...props) => {
        if (props.length === 2) {
            const [key, value] = props;
            setData(data => {
                return { ...data, [key]: value };
            });
            // localStorage
            if (storeAtLocalStorage.includes(key)) setJSONItem(key, value);
        } else if (props.length === 1) {
            const [data] = props;
            setData(data);
            // localStorage
            if (storeAtLocalStorage.length !== 0) {
                setJSONObject(data, storeAtLocalStorage);
            }
        } else {
            throw new Error('Argument length is invalid. It must be 1 or 2 arguments.');
        }
    };
    return [data, _setData];
}
