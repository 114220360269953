import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * 複数のクラス名を結合する。
 * @param  {...string} namesArr スペース区切りの複数のクラス名。
 * @returns {string}
 */

/**
 * エレメントを横並びで表示するコンポーネント。エレメント数は2または3のみ対応。幅の比率は以下。
 *
 * エレメント数2の場合 第一要素：第二要素=3:9
 *
 * エレメント数3の場合 第一要素：第二要素：第三要素=3:6:3
 *
 * @param {object} props
 * `children` 横並びで表示するエレメントの配列
 * `className` [option]ラッパーのdivに付与するclassName
 * `numberOfElements` [option]エレメント数
 * `proportion` [option] カラムの比率を独自定義する。和が12となるように比率を配列で表す。`[3,4,5]` など。
 * @param {JSX.Element[]} props.children
 * @param {number} [props.numberOfElements=React.Children.toArray(children).length]
 * @param {string} [props.className]
 * @param {number[]} [props.proportion]
 *
 * @example
 * const [name, setName] = useState("")
 * const handleChange = e => setName(e.target.value)
 * const handleConfirm = () => console.log("button clicked!!")
 * <DataForm
 *  className="p-2"
 * >
 *   {"氏名"}
 *   <input value={name} onChange={handleChange}/>
 *   <button onClick={handleConfirm}>確定</button>
 * </DataForm>
 */

export default function DataForm(props) {
    const {
        children,
        numOfElements = React.Children.toArray(children).length,
        proportion = null,
        className = '',
    } = props;

    const colSumArr = useMemo(() => {
        if (proportion != null) {
            return [...proportion];
        } else if (numOfElements === 2) {
            return [3, 9];
        } else {
            return [3, 6, 3];
        }
    }, [numOfElements, proportion, children]);

    return (
        <div className={classNames('row form-group', className)}>
            {colSumArr.map((colSum, i) => (
                <div
                    className={`col-sm-${colSum} ${i == 0 && 'col-form-label'}`}
                    key={i}
                    style={{ alignItems: 'center' }}
                >
                    {children[i]}
                </div>
            ))}
        </div>
    );
}

DataForm.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    numOfElements: PropTypes.number,
    className: PropTypes.string,
    proportion: function(props, propName, componentName) {
        if (props[propName] == null) return;
        if (!Array.isArray(props[propName])) {
            return new Error(
                'Invalid prop `' +
                    propName +
                    '` supplied to' +
                    ' `' +
                    componentName +
                    '`. It must be array.'
            );
        }
        if (props[propName].reduce((a, b) => a + b) !== 12) {
            return new Error(
                'Invalid prop `' +
                    propName +
                    '` supplied to' +
                    ' `' +
                    componentName +
                    "`. It's sum must be 12."
            );
        }
    },
};
