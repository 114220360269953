import React from 'react';
import BaseFrame from './PageFrame';

export default function Page(props) {
    const { children, title, ...others } = props;

    return (
        <BaseFrame title={title}>
            <div {...others}>{children}</div>
        </BaseFrame>
    );
}
