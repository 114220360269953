import React from 'react';
import UserIcon from 'components/icons/Member';
import CompanyIcon from 'components/icons/Company';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import nl2br from 'utils/nl2br';

export default function ProfileBlock(props) {
    const { clientName, userName, userRoles } = props;

    return (
        <div className="sidebar-profile">
            <div className="button-with-icon sidebar-item">
                <CompanyIcon />
                <div className="button-text">{clientName}</div>
            </div>
            <div className="button-with-icon sidebar-item">
                <UserIcon />
                <div className="button-text">{userName}</div>
            </div>
            {userRoles != null && userRoles.length > 0 && (
                <div className="button-with-icon sidebar-item sidebar-item__list">
                    {/* First role */}
                    <span className="badge badge-primary">{userRoles[0].name}</span>

                    {/* Extra roles */}
                    {userRoles.length > 1 && (
                        <OverlayTrigger
                            placement={'right'}
                            overlay={
                                <Tooltip>
                                    {nl2br(
                                        userRoles
                                            .slice(1)
                                            .map(role => role.name)
                                            .join('\n')
                                    )}
                                </Tooltip>
                            }
                        >
                            <span className="badge badge-narrow badge-primary">
                                +{userRoles.length - 1}
                            </span>
                        </OverlayTrigger>
                    )}
                </div>
            )}
        </div>
    );
}
