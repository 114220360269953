import React from 'react';
import classNames from 'classnames';
import useTransition from 'hooks/useTransition';

export default function BackButton(props) {
    const { className, disabled } = props;

    const { pushHistoryParent } = useTransition();
    const handleBack = () => pushHistoryParent();

    return (
        <button
            className={classNames('btn btn-outline-secondary mt-3', className)}
            disabled={disabled}
            onClick={handleBack}
        >
            一覧へ戻る
        </button>
    );
}
