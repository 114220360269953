import React from 'react';
import classNames from 'classnames';
import useTransition from 'hooks/useTransition';

function LoginButton(props) {
    const { className, ...rest } = props;
    const { pushHistoryAbs } = useTransition();

    const handleOnClick = () => {
        pushHistoryAbs('/login');
    };

    return (
        <button
            className={classNames('btn btn-primary', className)}
            {...rest}
            onClick={handleOnClick}
        >
            ログイン
        </button>
    );
}

export default LoginButton;
