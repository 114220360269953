import * as React from 'react';
import classNames from 'classnames';

export default function ArrowDown(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            {...others}
        >
            <path
                d="M12.5001 8.875L8.00012 13.375L3.50012 8.875"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.00012 12.75V3.625"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
