import * as React from 'react';
import classNames from 'classnames';

export default function Search(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            {...others}
        >
            <path
                d="M14.272 13.1684L11.3317 10.2281C12.0396 9.28573 12.4218 8.13865 12.4205 6.96C12.4205 3.94937 9.97111 1.5 6.96049 1.5C3.94986 1.5 1.50049 3.94937 1.50049 6.96C1.50049 9.97062 3.94986 12.42 6.96049 12.42C8.13914 12.4213 9.28622 12.0391 10.2286 11.3312L13.1689 14.2716C13.3178 14.4046 13.5119 14.4756 13.7114 14.47C13.911 14.4645 14.1009 14.3827 14.242 14.2415C14.3832 14.1004 14.4649 13.9105 14.4705 13.711C14.4761 13.5114 14.4051 13.3173 14.272 13.1684V13.1684ZM3.06049 6.96C3.06049 6.18865 3.28922 5.43463 3.71776 4.79328C4.14629 4.15192 4.75539 3.65205 5.46802 3.35687C6.18065 3.06169 6.96481 2.98445 7.72134 3.13494C8.47786 3.28542 9.17278 3.65686 9.7182 4.20228C10.2636 4.74771 10.6351 5.44262 10.7855 6.19915C10.936 6.95567 10.8588 7.73983 10.5636 8.45246C10.2684 9.1651 9.76856 9.77419 9.12721 10.2027C8.48586 10.6313 7.73183 10.86 6.96049 10.86C5.92652 10.8588 4.93526 10.4475 4.20414 9.71634C3.47302 8.98522 3.06173 7.99396 3.06049 6.96V6.96Z"
                fill="currentColor"
            />
        </svg>
    );
}
