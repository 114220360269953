/**
 * ローカルストレージにデータを保存するメソッド
 * JSON.stringifyを適用した結果undefinedが格納される場合があります
 * @param {string} key localStorageに保存するキー
 * @param {any} value localStorageに保存するデータ（オブジェクト）
 */
const setJSONItem = (key, item) => {
    const json = JSON.stringify(item);
    localStorage.setItem(key, json);
};

/**
 * ローカルストレージにオブジェクトを保存するメソッド
 * JSON.parseができればパース、できなければ文字列をそのまま返す
 * @param {key} key localStorageのキー
 */
const getJSONItem = key => {
    const jsonStr = localStorage.getItem(key);
    try {
        return JSON.parse(jsonStr);
    } catch (e) {
        return jsonStr;
    }
};

/**
 * オブジェクトを展開し、(key, value)の組みをローカルストレージに保存する
 * @param {object} obj localStorageに保存するオブジェクト
 * @param {[string]} includeList localStorageに保存するデータキーの配列。これに含まれるキーのみがlocalStorageに保存される。省略すると、全てのデータを保存する。
 */
const setJSONObject = (obj, includeList = null) => {
    Object.entries(obj).map(([key, value]) => {
        if (includeList == null || includeList.includes(key)) {
            setJSONItem(key, value);
        }
    });
};

/**
 * ローカルストレージのデータを消去するメソッド
 */
const clearJSONData = () => {
    localStorage.clear();
};

export { setJSONItem, getJSONItem, setJSONObject, clearJSONData };
