import * as React from 'react';
import classNames from 'classnames';

export default function ExpandLess(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            viewBox="0 0 24 24"
            width="1em"
            height="1em"
            {...others}
        >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M11.29 8.71L6.7 13.3c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 10.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 8.71c-.38-.39-1.02-.39-1.41 0z" />
        </svg>
    );
}
