import React, { useEffect } from 'react';
import queryString from 'query-string';
import BaseFrame from 'templates/PageFrame';
import TableLayout from 'templates/TableLayout/TableLayout';
import useTransition from 'hooks/useTransition';
import useAPIData from 'hooks/useAPIData';
import column from './clientsColumn';
import { useAPIRequest } from 'dataProvider/postStateData/requestStateData';

export default function ClientsList(props) {
    const {
        location: { search },
    } = props;
    const currentPage = parseInt(queryString.parse(search).page ?? 1);

    // Page Transition
    const { pushHistory } = useTransition();
    const handleClickCreate = () => pushHistory('/create');

    // Fetch Data
    const [fetch, res, metaData] = useAPIData(
        '/clients',
        res =>
            res.map(client => ({
                id: client.id,
                name: client.name,
                route: client.route,
                createdAt: client.created_at,
                updatedAt: client.updated_at,
                is_suspended: !client.is_suspended ? 'valid' : 'invalid',
            })),
        []
    );

    useEffect(() => {
        fetch(currentPage);
    }, [search]);

    const handleClickSearch = keyword => {
        fetch(1, { search: keyword });
    };

    const handleClickSort = (sortKey, isAscend) => {
        fetch(currentPage, { orderBy: sortKey, orderDir: isAscend ? 'asc' : 'desc' });
    };

    const handleClickDetail = clientData => {
        pushHistory(`/${clientData.id}`, clientData);
    };

    // Delete data
    const { deleteCall } = useAPIRequest();
    const deleteRequestKey = 'admin.users.delete';

    const handleClickDelete = deleteClients => () => {
        deleteClients.forEach(clientData =>
            deleteCall(
                deleteRequestKey + clientData.id,
                `/clients/${clientData.id}`,
                null,
                () => {},
                {
                    loading: `クライアントID:${clientData.id} を削除中です`,
                    success: `クライアントID:${clientData.id} を削除しました`,
                    error: `クライアントID:${clientData.id} の削除に失敗しました`,
                }
            ).then(() => {
                if (process.env.NODE_ENV === 'development') {
                    deleteClients.forEach(console.table);
                    console.info('DELETE CLIENTS');
                }

                // 削除が完了したら再度データ取得を行う
                fetch(currentPage);
            })
        );
    };

    // Pagination
    const handleClickPage = page => () => {
        pushHistory(`?page=${page}`);
    };

    // View
    return (
        <BaseFrame title="Clients">
            <TableLayout
                ui={{
                    title: '企業一覧',
                    searchLabel: '企業を検索',
                    createLabel: '新規企業を追加',
                }}
                data={res.data}
                column={column}
                loading={res.loading}
                useSort={['id', 'name', 'route']}
                onClickCreate={handleClickCreate}
                onClickDetail={handleClickDetail}
                onClickDelete={handleClickDelete}
                onClickPage={handleClickPage}
                onClickSearch={handleClickSearch}
                onClickSort={handleClickSort}
                currentPage={currentPage}
                totalPage={metaData.last_page}
            />
        </BaseFrame>
    );
}
