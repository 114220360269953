import { useState } from 'react';
import axios from 'axios';
import { usePathData } from 'dataProvider/pathData';
import { useAuth } from 'dataProvider/authData';
import calcAPIBase from 'utils/calcAPIBase';
import { isFunction } from 'lodash';

// /**
//  * API呼び出しの状態
//  * + `PENDING`: 呼び出し中
//  * + `FULFILLED: 呼び出し成功
//  * + `REJECTED: 呼び出し失敗（エラー発生）
//  */
export const ASYNC_STATE = {
    PENDING: 'pending',
    FULFILLED: 'fulfilled',
    REJECTED: 'rejected',
    WAITING: 'waiting',
};

/**
 * postリクエストを表すオブジェクトを生成する
 * @param {object} pageData ページ内のフォームなどのデータ。一度そのページを離れ、再度訪れた際に、データを復元するために利用する
 * @param {object} requestData Postデータ。
 * @param {string} state 状態。post呼び出しの状態。`ASYNC_STATE.**`が入る。
 * @param {string} responseData 応答データ。
 * @param {string} errMessage post呼び出しでエラーが発生した場合のエラーメッセージ。
 */
const postResBuilder = (pageData, requestData, state, responseData, errMessage, errors) => ({
    errors,
    pageData,
    requestData,
    state,
    responseData,
    loading: state === ASYNC_STATE.PENDING,
    error: state === ASYNC_STATE.REJECTED,
    waiting: state === ASYNC_STATE.WAITING,
    success: state === ASYNC_STATE.FULFILLED,
    errorMessage: state === ASYNC_STATE.REJECTED ? errMessage ?? 'no error' : 'no error',
});

/**
 * POSTリクエストを行い、リクエストの状態を管理するカスタムフック
 * @return [post, states]
 * + `post`はPOSTリクエストを行う関数。第1引数に`key`、第2引数にパス、第3引数にPOSTするデータを入れて呼び出す。返り値はPromise。
 * + `states`はPOSTリクエストの状態を得る関数。post関数で指定した`key`を与えると、その呼び出しの状態を得ることができる。
 *
 * @example
 * function APICallTest(){
 * 	 const [request, states] = useAPIPost()
 *
 *   const handleClick = () => {
 *     request("post")("sampleKey", "/system/clients")
 *   }
 *
 *   return (
 *     <div>
 *       <button onClick={handleClick}>送信</button>
 *       <p>{states("sampleKey").state}</p> ← API呼び出しの状態が表示される
 *       <p>{states("sampleKey").errorMessage}</p> ← APIリクエストがrejectされた場合にはエラーメッセージが格納されている。それ以外では`no error`と言う文字列が返る
 *     </div>
 *   )
 *
 */
const useAPIPostStateManager = () => {
    const [states, setStates] = useState({});
    const { clientId, userType } = usePathData();
    const { handle401, getToken } = useAuth();

    const apiBase = calcAPIBase(userType, clientId);

    const request = method =>
        /**
         * Post/Put/Deleteリクエストを行う関数を返す関数。
         * @param {string} key PostKey。アプリケーション内で一意な値を指定する。
         * @param {string} string post、put、deleteのいずれかを指定する。
         * @param {string} path apiのエンドポイント。`http://..../api`より後のパスを指定する。(ex `/system/clients`)
         * @param {object} data ポストするデータ
         * @param {object} dataModefier ポストするデータを整形する関数。引数として`data`が与えられ、APIのリクエストに対応した形に整形させる。
         */
        (key, path, data = null, dataModefier = o => o) => {
            if (states[key]?.loading) console.error('Another api call is pending. key: ' + key);
            const requestData = isFunction(dataModefier) ? dataModefier(data) : data;
            setStates(states => ({
                ...states,
                [key]: postResBuilder(data, requestData, ASYNC_STATE.PENDING),
            }));
            const token = getToken();
            return axios({
                method: method.toLowerCase(),
                url: apiBase + path,
                data: requestData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(res => {
                    // Set State
                    setStates(states => ({
                        ...states,
                        [key]: postResBuilder(
                            data,
                            requestData,
                            ASYNC_STATE.FULFILLED,
                            res.data.data
                        ),
                    }));
                    // Clear State
                    setStates(states => ({ ...states, [key]: null }));

                    return res;
                })
                .catch(err => {
                    if (err.response?.status == 401 && !key.includes('refreshToken')) {
                        handle401();
                    }
                    const errorMessage = err.response?.data?.errors
                        ? Object.values(err.response.data.errors).join('\n')
                        : err.response?.data?.message ?? '処理に失敗しました';
                    setStates(states => ({
                        ...states,
                        [key]: postResBuilder(
                            data,
                            requestData,
                            ASYNC_STATE.REJECTED,
                            null,
                            errorMessage,
                            err.response?.data?.errors
                        ),
                    }));
                    throw err;
                });
        };

    return [request, key => states[key] ?? postResBuilder(null, null, ASYNC_STATE.WAITING)];
};

export default useAPIPostStateManager;
