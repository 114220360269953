import { mailTemplateType } from 'constants/mailTemplateType';
import Usage from 'constants/usage';
import { eFormMode } from './enums';

export const convertFormIntoPayload = (formData, type = eFormMode.create) => {
    const phoneNumber = formData.disableInvitationByPhone
        ? {}
        : { phone_number: formData.phone_number };
    const admin =
        type === eFormMode.create
            ? {
                ...phoneNumber,
                email: formData.email,
            }
            : null;
    return {
        admin,
        name: formData.name,
        route: formData.route,
        plan_id: formData.plan_id,
        categories_threshold: Number(formData.categories_threshold),
        users_threshold: Number(formData.users_threshold),
        mail_templates: [
            {
                type: mailTemplateType.RegistrationAdmin,
                body_sms: formData.admin_sms_template,
                body_email: formData.admin_email_template,
            },
            {
                type: mailTemplateType.RegistrationUser,
                body_sms: formData.user_sms_template,
                body_email: formData.user_email_template,
            },
        ],
        client_usages: [
            ...(!formData.allowFileUpload ? [Usage.DisableUpload] : []),
            ...(formData.notIncludeReportRole ? [Usage.DisableInternalReport] : []),
            ...(formData.disableCSVDownload ? [Usage.DisableCSV] : []),
            ...(formData.disableAccessRestriction ? [Usage.DisableIpRestriction] : []),
            ...(formData.disableRoleManagement ? [Usage.DisableRoleManagement] : []),
            ...(formData.disableReplyModification ? [Usage.DisablePostUpdate] : []),
            ...(formData.disableAddAdmin ? [Usage.DisableAddAdmin] : []),
            ...(formData.disableInvitationByPhone ? [Usage.DisableInvitationByPhone] : []),
            ...(formData.disableUserGroup ? [Usage.DisableUserGroup] : []),
            ...(formData.disablePostClose ? [Usage.DisablePostClose] : []),
            ...(formData.disableAnouncementFunction ? [Usage.DisableAnouncementFunction] : []),
            ...(formData.disableUserInvitationUrlFunction
                ? [Usage.DisableUserInvitationUrlFunction]
                : []),
            ...(formData.disableGroupFilter ? [Usage.DisableGroupFilter] : []),
            ...(formData.disableSecurityPolicy ? [Usage.DisableSecurityPolicy] : []),
            ...(formData.disableExternalPost ? [Usage.DisableExternalPost] : []),
            ...extractUserNameUsages(formData.usernameType),
        ],
    };
};

const extractUserNameUsages = userType => {
    if (userType === 'onlyNickname') {
        return [Usage.DisableName];
    }
    if (userType === 'onlyRealname') {
        return [Usage.DisableNickname];
    }
    return [];
};

export const convertUsageIdsIntoForm = usageIds => {
    if (!Array.isArray(usageIds)) return {};
    return {
        allowFileUpload: !hasUsageId(usageIds, Usage.DisableUpload),
        notIncludeReportRole: hasUsageId(usageIds, Usage.DisableInternalReport),
        disableCSVDownload: hasUsageId(usageIds, Usage.DisableCSV),
        disableAccessRestriction: hasUsageId(usageIds, Usage.DisableIpRestriction),
        disableRoleManagement: hasUsageId(usageIds, Usage.DisableRoleManagement),
        disableReplyModification: hasUsageId(usageIds, Usage.DisablePostUpdate),
        disableAddAdmin: hasUsageId(usageIds, Usage.DisableAddAdmin),
        disableInvitationByPhone: hasUsageId(usageIds, Usage.DisableInvitationByPhone),
        disableUserGroup: hasUsageId(usageIds, Usage.DisableUserGroup),
        disablePostClose: hasUsageId(usageIds, Usage.DisablePostClose),
        disableAnouncementFunction: hasUsageId(usageIds, Usage.DisableAnouncementFunction),
        disableUserInvitationUrlFunction: hasUsageId(
            usageIds,
            Usage.DisableUserInvitationUrlFunction
        ),
        disableSecurityPolicy: hasUsageId(usageIds, Usage.DisableSecurityPolicy),
        disableExternalPost: hasUsageId(usageIds, Usage.DisableExternalPost),
        disableGroupFilter: hasUsageId(usageIds, Usage.DisableGroupFilter),
        usernameType: getUserTypeOption(usageIds),
    };
};

export const hasUsageId = (clientUsages, usageId) => !!clientUsages.includes(usageId);

// ユーザ名選択の選択肢
export const getUserTypeOption = clientUsages => {
    const disableName = hasUsageId(clientUsages, Usage.DisableName);
    const disableNickname = hasUsageId(clientUsages, Usage.DisableNickname);
    if (!disableName && !disableNickname) {
        return 'both'; // allow both
    }
    if (disableName) {
        return 'onlyNickname'; // only nickname
    }
    return 'onlyRealname'; // only realname
};
