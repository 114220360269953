import { useAPIData } from 'hooks/useAPIData';
import { pick } from 'lodash';
import React from 'react';

function useAPIPasswordRulesList(dataMapper = x => x) {
    return useAPIData('/password-rules', dataMapper, null);
}

function useAPIPasswordRulesByTokenList(token, dataMapper = x => x) {
    return useAPIData(`/password-rules-by-forgot-token/${token}`, dataMapper, null)
}

export default function PasswordRuleMessage({token = null}) {
    const [fetchRulesList, resultRulesList] = useAPIPasswordRulesList();
    const [fetchRulesByToken, resultRulesByToken] = useAPIPasswordRulesByTokenList(token);

    // Choose appropriate fetch and result based on token
    const fetch = token ? fetchRulesByToken : fetchRulesList;
    const result = token ? resultRulesByToken : resultRulesList;
    const rules = result?.data;

    React.useEffect(() => {
        fetch();
    }, []);


    if (!rules) return <p>パスワードを英数含む8文字以上で設定してください。</p>;

    const passwordRuleMessages = Object.entries(
        pick(rules, [
            'contains_uppercase',
            'contains_lowercase',
            'contains_symbol',
            'contains_number',
            'min_length',
        ])
    ).reduce((acc, [key, value]) => {
        if (key === 'contains_uppercase' && value) {
            return acc.concat('大文字含む');
        }
        if (key === 'contains_lowercase' && value) {
            return acc.concat('小文字含む');
        }
        if (key === 'contains_symbol' && value) {
            return acc.concat('記号含む');
        }
        if (key === 'contains_number' && value) {
            return acc.concat('数字含む');
        }
        if (key === 'min_length' && value) {
            return acc.concat(`最小長は ${value} 文字です`);
        }
        return acc;
    }, []);

    if (!passwordRuleMessages.length) return null;

    return <p>パスワードルール: {passwordRuleMessages.join(', ')}.</p>;
}
