import React from 'react';
import ExpandMenuAccordion from './ExpandMenuAccordion';
import ExpandMenuButton from './ExpandMenuButton';

/**
 * サイドバーの開閉状況によって適切なボタンを出し分けるコンポーネント
 */
const StretchButton = props => {
    const { children, contentId, url, label, Icon, handleClickLink, id } = props;
    if (children != null) {
        return (
            <ExpandMenuAccordion
                {...{ children, contentId, url, label, Icon, id, handleClickLink }}
            />
        );
    } else {
        return <ExpandMenuButton {...{ contentId, url, label, Icon, handleClickLink, id }} />;
    }
};

export default StretchButton;
