const column = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: '企業名',
        accessor: 'name',
        textAlign: 'left', //文字を左詰め
    },
    {
        Header: 'URL文字列',
        accessor: 'route',
        textAlign: 'left', //文字を左詰め
    },
];

export default column;
