import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

/**
 * パスワードマスクつきの入力フィールドコンポーネント。マスクの有無をボタンで切り替え可能。
 * @param {object} props
 * `value` 入力フィールドに付与されるvalue属性。
 * `onChange` 入力フィールドに付与されるonChange属性。
 * `inputRender` パスワードがマスク状態か否かを表す変数を受け取り、inputエレメントを返す render prop。
 * `name` [option]入力フィールドに付与されるname属性。
 * `onStateChange` [option]マスク状態を表す変数の変化をトリガーするコールバック関数。
 * @param {string} props.value
 * @param {function(boolean)} props.onChange
 * @param {function(boolean): JSX.Element} props.inputRender
 * @param {string} [props.name]
 * @param {function(boolean)} [props.onStateChange]
 *
 * @example
 * const [password, setPassword] = useState("")
 * const handleChange = (e) => setPassword(e.target.value)
 * <EditableInputField
 *   name="password"
 *   value={password}
 *   onChange={handleChange}
 *   inputRender={(hidden) => <input className="form-control" />}
 * />
 */

export default function PasswordInputField(props) {
    const { name, value, onChange, onStateChange = () => {}, disabled, inputRender } = props;

    const inputEl = useRef();

    const [hidden, setHidden] = useState(true);
    const toggleHidden = () => {
        setHidden(!hidden);
        inputEl.current.focus();
        onStateChange(!hidden);
    };

    const handleChange = e => {
        onChange(e);
    };

    return (
        <div className="position-relative">
            {React.cloneElement(inputRender(hidden), {
                name,
                value,
                onChange: handleChange,
                disabled,
                type: hidden ? 'password' : 'text',
                ref: inputEl,
            })}
            <div className="input-group-loating-addon">
                <button className="btn btn-link" type="button" onClick={toggleHidden}>
                    {hidden ? <EyeSlashFill /> : <EyeFill />}
                </button>
            </div>
        </div>
    );
}

PasswordInputField.propType = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    inputRender: (props, propName, componentName) => {
        PropTypes.checkPropTypes(
            { returnValue: PropTypes.element.isRequired },
            { returnValue: props[propName](true) },
            propName,
            componentName
        );
        PropTypes.checkPropTypes(
            { returnValue: PropTypes.element.isRequired },
            { returnValue: props[propName](false) },
            propName,
            componentName
        );
    },
    name: PropTypes.string,
    disabled: PropTypes.bool,
};
