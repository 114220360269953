import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Form from 'components/Form';
import BackButton from 'components/BackButton';
import AlertModal from 'components/AlertModal';
import LoadingCircle from 'components/LoadingCircle';

export { default as ResendButton } from 'components/ResendButton';
export { default as StatusChangeButton } from 'components/StatusChangeButton';
export { default as DeleteButton } from 'components/DeleteButton';

/**
 * データ編集画面（企業詳細画面など）のテンプレート
 *
 * @param {object} props
 * + `data`: 表示するデータ。Formコンポーネントのvalueに渡す形式
 * + `handleData`: `FormコンポーネントのhandleDataに渡す形式`
 * + `loading`: 表示データのローディング中か
 * + `loadError`: 表示するデータのローディングでエラーが発生したか
 * + `pending`: 変更後データをPUTしている途中か
 * + `requestError`: PUTでエラーが発生しているか
 * + `requestErrorMessage`: PUTでエラーが発生している場合の画面に表示するエラーメッセージ
 * + `onUpdateConfirm`: 変更を確定した時に呼ばれるコールバック関数
 * + `modal`: 変更を確定するかどうか確認するAlertModalに与えるモーダルプロパティ
 * + `validation`: データのバリデーション
 * + `children`: Formコンポーネント内に配置されるInputFormコンポーネントの配列を返すビルダー関数。入力値として現在編集モード中かを表すeditModeの値が与えられる。
 * ただしcustomFormを指定すると、直接子コンポーネントを描画できる
 * + `className`: 外側のdivに与えられるクラス名
 * + `customForm`: trueにするとFormを使わず、children(editMode)が直接レンダリングされる
 * @param {object} props.data
 * @param {function} props.handleData
 * @param {boolean} props.loading
 * @param {boolean} props.loadError
 * @param {boolean} props.pending
 * @param {boolean} props.requestError
 * @param {string} props.requestErrorMessage
 * @param {function} props.onUpdateConfirm
 * @param {object} props.modal
 * @param {function} props.validation
 * @param {function} props.children
 * @param {string} props.className
 */

export default function DataEditLayout(props) {
    const { className, title, ...rest } = props;
    return (
        <div className={classNames('data-edit-template', className)}>
            <h1 className="page-title">{title}</h1>
            <div className="card styled-card my-4">
                <DataEditForm {...rest} />
            </div>
            <BackButton />
        </div>
    );
}

export function DataEditForm(props) {
    const {
        children: childrenBuilder,
        data,
        handleData,
        loading,
        loadError,
        pending,
        requestError,
        requestErrorMessage = null,
        onUpdateConfirm: _onUpdateConfirm,
        disableEdit,
        validation,
        buttomArea,
        modal,
        customeForm,
    } = props;

    // 編集モードかどうか
    const [editMode, setEditMode] = useState(false);
    // 編集モードに入る前の入力値を保存するstate
    const [originalClientData, setOriginalClientData] = useState('');
    // 編集モードに入る
    const handleEdit = () => {
        setEditMode(true);
        setOriginalClientData(data);
    };
    // 編集モード中に変更があったかどうかを返す関数
    const isClientDataChanged = () => {
        for (const key of Object.keys(data)) {
            if (data[key] !== originalClientData[key]) return false;
        }
        return true;
    };

    // PUTエラーが発生した場合は強制的にEditモードにする
    useEffect(() => {
        if (requestError) setEditMode(true);
    }, [requestError]);

    // 確定ボタンをクリックした時の処理
    const onUpdateConfirm = () => {
        setEditMode(false);
        _onUpdateConfirm();
    };

    // View
    const EditButton = () => {
        if (editMode) {
            return (
                <AlertModal className="mt-5 mb-3" modal={modal} onConfirm={onUpdateConfirm}>
                    <button
                        className="btn btn-primary px-5"
                        disabled={!validation(data) || isClientDataChanged()}
                    >
                        変更を確定する
                    </button>
                </AlertModal>
            );
        } else {
            return (
                <div className="mt-5 mb-3">
                    <button
                        className="btn btn-outline-primary px-5"
                        onClick={handleEdit}
                        disabled={pending || disableEdit}
                    >
                        {disableEdit
                            ? 'この情報は編集が禁止されています'
                            : pending
                            ? '処理中'
                            : '変更する'}
                    </button>
                </div>
            );
        }
    };
    return (
        <LoadingCircle isLoading={loading}>
            {loadError ? (
                <pre className="error-message text-center">データの取得に失敗しました</pre>
            ) : customeForm ? (
                <React.Fragment>
                    {childrenBuilder(editMode)}
                    {requestError && (
                        <pre className="error-message text-center">{requestErrorMessage}</pre>
                    )}
                </React.Fragment>
            ) : (
                <Form
                    className="form-m-5"
                    value={data}
                    onChange={handleData}
                    disabled={pending || disableEdit || !editMode}
                >
                    {childrenBuilder(editMode)}
                    {requestError && (
                        <pre className="error-message text-center">{requestErrorMessage}</pre>
                    )}
                </Form>
            )}
            <EditButton />
            {buttomArea && <div className="card-divider mb-4" />}
            {buttomArea}
        </LoadingCircle>
    );
}
