

import * as React from 'react';
import classNames from 'classnames';

export default function ExternalPost(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            viewBox="0 0 24 24"
            width="1em"
            height="1em"
            style={{ fill: 'none' }}
            {...others}
        >
            <path d="M15.75 16.251H17.625C18.1223 16.251 18.5992 16.0534 18.9508 15.7018C19.3025 15.3502 19.5 14.8733 19.5 14.376V4.62598C19.5 4.1287 19.3025 3.65178 18.9508 3.30015C18.5992 2.94852 18.1223 2.75098 17.625 2.75098H6.375C5.87772 2.75098 5.40081 2.94852 5.04917 3.30015C4.69754 3.65178 4.5 4.1287 4.5 4.62598V14.376C4.5 14.8733 4.69754 15.3502 5.04917 15.7018C5.40081 16.0534 5.87772 16.251 6.375 16.251H8.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.25 11.751L12 8.00098L15.75 11.751" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 22.25V8.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

