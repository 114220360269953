import React from 'react';
import classNames from 'classnames';
import ButtonWithIcon from 'components/ButtonWithIcon';

/**
 * サイドバーで展開状態の時に使うボタン
 */
export default function ExpandMenuButton(props) {
    const { contentId, url, label, id, handleClickLink, Icon, className } = props;
    return (
        <ButtonWithIcon
            className={classNames(
                'sidebar-item',
                contentId === id ? ' active-item' : '',
                className
            )}
            Icon={<Icon />}
            onClick={handleClickLink(url)}
        >
            {label}
        </ButtonWithIcon>
    );
}
