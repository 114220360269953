const urlAnalyze = (url, subDomain) => {
    const [_, ...urlFrags] = url.split('/');

    if (subDomain === 'app') {
        if (urlFrags[1] === 'admin') {
            // ADMIN
            return {
                userType: 'admin',
                subDomain,
                clientId: urlFrags[0],
                contentId: urlFrags[2],
                base: '/' + urlFrags[0] + '/admin',
                spaRoute: '/' + urlFrags.slice(2).join('/'),
                pathname: url,
            };
        } else {
            // USER
            // post/create, reports/create に対応
            const contentId =
                urlFrags[1] === 'posts' && urlFrags[2] === 'create'
                    ? 'newpost'
                    : urlFrags[1] === 'reports' && urlFrags[2] === 'create'
                    ? 'newreport'
                    : urlFrags[1];
            return {
                userType: 'user',
                subDomain,
                clientId: urlFrags[0],
                contentId,
                base: '/' + urlFrags[0],
                spaRoute: '/' + urlFrags.slice(1).join('/'),
                pathname: url,
            };
        }
    } else {
        // SYSTEM
        return {
            userType: 'system',
            subDomain,
            clientId: null,
            contentId: urlFrags[0],
            base: '',
            spaRoute: '/' + urlFrags.slice(0).join('/'),
            pathname: url,
        };
    }
};

export default urlAnalyze;
