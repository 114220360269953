import { useForm } from 'components/Form';
import { useEffect, useMemo, useRef } from 'react';
import { useAPIPlanSettings } from 'repo/system/plansRepo';
import { eFormMode, eLimitedTextName } from './enums';
import { convertUsageIdsIntoForm, getUserTypeOption } from './utils';

export const EMAIL_WORD_COUNT = 1000;
export const SMS_WORD_COUNT = 70;

export const useClientsForm = (initialValue, mode) => {
    const isResetForm = useRef(false);
    const [fetchPlans, responsePlans] = useAPIPlanSettings();
    const [formData, handleChangeData, updateData] = useForm(initialValue);

    const plans = responsePlans?.data?.data || null;

    const planOptions = useMemo(
        () =>
            Array.isArray(plans) ? plans.map(plan => ({ value: plan.id, label: plan.name })) : [],
        [plans]
    );
    const handleLimitedTextInput = type => e => {
        const maxCount = type === eLimitedTextName.email ? EMAIL_WORD_COUNT : SMS_WORD_COUNT;
        if (formData[e.target.name].length >= maxCount && e.target.value.length > maxCount) {
            // skip
            return;
        }
        if (e.target.value.length > maxCount) {
            handleChangeData({
                target: {
                    name: e.target.name,
                    value: e.target.value.substring(0, maxCount),
                },
            });
            return;
        }
        handleChangeData(e);
    };

    // Watch Client Usages change
    useEffect(() => {
        if (isResetForm.current) {
            isResetForm.current = false;
            return;
        }
        if (!formData?.plan_id && mode === eFormMode.update) return;

        let selectedPlan = null;
        if (Array.isArray(plans)) {
            selectedPlan = plans.find(plan => plan.id === formData.plan_id);

            if (!selectedPlan && plans.length > 1) {
                selectedPlan = plans[0];
            }
        }

        if (selectedPlan) {
            updateData({
                ...formData,
                ...convertUsageIdsIntoForm(selectedPlan.usage_ids),
                usernameType: getUserTypeOption(selectedPlan.usage_ids),
                categories_threshold: selectedPlan.categories_threshold,
                users_threshold: selectedPlan.users_threshold,
            });
        }
    }, [formData?.plan_id]);

    useEffect(() => {
        updateData('allowFileUploadLabel', formData.allowFileUpload ? 'Yes' : 'No');
    }, [formData?.allowFileUpload]);

    useEffect(() => {
        fetchPlans();
    }, []);

    const resetForm = newData => {
        isResetForm.current = true;
        updateData(newData);
    };

    return {
        handleLimitedTextInput,
        formData,
        handleChangeData,
        planOptions,
        resetForm,
    };
};

// ユーザ名選択の選択肢
export const usernameTypeOptions = [
    { label: '実名もニックネームも許可する', value: 'both' },
    { label: '実名のみ許可する', value: 'onlyRealname' },
    { label: 'ニックネームのみ許可する', value: 'onlyNickname' },
];
