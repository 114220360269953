import React, { useEffect, useState } from 'react';
import AlertModal from 'components/AlertModal';
import Form, { useForm } from 'components/Form';
import InputForm from 'components/InputForm';
import { useAPIAdminChangeRoot } from 'repo/admin/adminRepo';
import { useAPIChangeRootAdmin } from 'repo/system/clientRepo';
import { useAPIData } from 'hooks/useAPIData';
import { routeWebAdmin } from 'utils/routes';
import { useClientData } from 'dataProvider/clientData';
import roleType from 'constants/roleType';
import Button from './Button';
import Sync from './icons/Sync';

/**
 * メール再送ボタン
 * @param {object} props
 * @param {Function} props.onClick
 * @param {boolean} props.disabled
 */
export default function ChangeRootAccountButton(props) {
    const { adminOptions, roleOptions, handleChangeRootAdmin, disabled } = props;
    const [formData, handleFormData, updateFormData] = useForm({ newAdminId: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOnConfirm = async () => {
        await handleChangeRootAdmin({ ...formData });
        updateFormData('newAdminId', '');
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                className="btn-outline-primary"
                onClick={() => {
                    setIsModalOpen(true);
                }}
                disabled={disabled}
            >
                ルートアカウント変更
            </Button>
            <AlertModal
                open={isModalOpen}
                modalClassName="change-root-account-modal"
                modal={{
                    body: (
                        <ChangeRootAccountForm
                            onChange={handleFormData}
                            value={formData}
                            adminOptions={adminOptions.data}
                            roleOptions={roleOptions.data}
                            onCancel={() => {
                                setIsModalOpen(false);
                            }}
                            onSubmit={handleOnConfirm}
                        />
                    ),
                    positiveLabel: '変更する',
                    negativeLabel: 'キャンセル',
                }}
                icon={<Sync />}
                title="ルートアカウントの変更"
                showFooter={false}
                onClose={() => setIsModalOpen(false)}
            />
        </>
    );
}

const ChangeRootAccountForm = props => {
    const {
        adminOptions = [],
        roleOptions = [],
        onChange,
        value,
        loading = false,
        onSubmit,
        onCancel,
    } = props;

    return (
        <div className="change-root-account-form">
            <h3 className="text-bluegray mb-4" style={{ lineHeight: 2.2 }}>
                ルートアカウントの権限を変更してしまうと、その権限を元に戻すことはできません。変更はただちに反映され、この操作を取り消すことはできないのでご注意ください。
            </h3>
            <div className="mt-3" style={{ background: '#f8f8f8', padding: '24px' }}>
                <Form className="form-m-4" value={value} onChange={onChange} disabled={loading}>
                    <InputForm
                        type="select"
                        label="新しいルートアカウント権限をもつ管理者"
                        name="newAdminId"
                        placeholder="管理者を選択してください"
                        proportion={[12, 12]}
                        options={adminOptions}
                    />
                    <InputForm
                        type="select"
                        label="あなたのアカウントに新しく付与する権限"
                        name="newRoleId"
                        placeholder="Select the next role"
                        proportion={[12, 12]}
                        options={roleOptions}
                    />
                    <div className="modal-body-footer">
                        <Button
                            type="button"
                            className="btn-outline-gray btn-lg"
                            data-dismiss="modal"
                            onClick={onCancel}
                        >
                            キャンセル
                        </Button>
                        <Button
                            className={`btn-primary btn-lg`}
                            onClick={onSubmit}
                            disabled={!adminOptions?.length || !roleOptions?.length}
                        >
                            変更する
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

// Hooks

// For usage in Admin screen
export const useChangeRootAccountForAdmin = currentAdmin => {
    const [changeRootAdmin, changeRootAdminState] = useAPIAdminChangeRoot();
    const clientData = useClientData();

    // Fetch Admin Option List
    const [fetchAdminOptions, adminOptions] = useAPIData(
        '/admins?per_page=1000',
        res =>
            res.data
                ?.filter(item => item.id != currentAdmin.id)
                ?.map(item => ({
                    value: item.id,
                    label: item.email,
                })),
        []
    );

    // Fetch Admin Option List
    const [fetchRoleList, roleOptions] = useAPIData(
        `/roles?per_page=1000`,
        res =>
            res.data
                ?.filter(item => item.type != roleType.ROOT)
                .map(item => ({
                    value: item.id,
                    label: item.name,
                })),
        []
    );

    // Handle Change Root Admin Action
    const handleChangeRootAdmin = formData => {
        changeRootAdmin({
            new_id: formData.newAdminId,
            new_role_id: formData.newRoleId,
        });
    };

    // Logout data after root admin changed
    useEffect(() => {
        if (changeRootAdminState.success) {
            setTimeout(() => {
                window.location = routeWebAdmin(clientData.route, '/logout');
            }, 1000);
        }
    }, [changeRootAdminState.success]);

    useEffect(() => {
        // Fetch Data from API
        fetchAdminOptions(1);
        fetchRoleList(1);
    }, []);

    return { adminOptions, roleOptions, handleChangeRootAdmin };
};

// For usage in Client screen
export const useChangeRootAccountForClient = (clientId, currentAdmin, onChanged) => {
    const [changeRootAdmin, changeRootAdminState] = useAPIChangeRootAdmin();

    // Fetch Admin Option List
    const [fetchAdminOptions, adminOptions] = useAPIData(
        `/clients/${clientId}` + '/admins?per_page=1000',
        res =>
            res.data
                ?.filter(item => item.id != currentAdmin?.id)
                ?.map(item => ({
                    value: item.id,
                    label: item.email,
                })),
        []
    );

    // Fetch Admin Option List
    const [fetchRoleList, roleOptions] = useAPIData(
        `/clients/${clientId}` + `/roles?per_page=1000`,
        res =>
            res.data
                ?.filter(item => item.type != roleType.ROOT)
                .map(item => ({
                    value: item.id,
                    label: item.name,
                })),
        []
    );

    // Handle Change Root Admin Action
    const handleChangeRootAdmin = formData => {
        changeRootAdmin(clientId, {
            new_id: formData.newAdminId,
            new_role_id: formData.newRoleId,
        });
    };

    // Logout data after root admin changed
    useEffect(() => {
        if (changeRootAdminState.success) {
            onChanged && onChanged();
        }
    }, [changeRootAdminState.success]);

    useEffect(() => {
        if (clientId) {
            // Fetch Data from API
            fetchAdminOptions(1);
            fetchRoleList(1);
        }
    }, [clientId, currentAdmin]);

    return { adminOptions, roleOptions, handleChangeRootAdmin };
};
