import { useAPIRequest } from 'dataProvider/postStateData/requestStateData';
import { useAuth } from 'dataProvider/authData';
import { authTypeConstants } from 'constants/auth';

/**
 * Get User Detail
 * @return [fetch, state]
 */
export function useAPIUserDetail() {
    const { get, states } = useAPIRequest();

    const requestKey = 'user.auth.me';
    const fetch = () =>
        get(requestKey, '/auth/me', null, null, {
            loading: 'Fetch User Detail',
            success: 'User Fetched',
            error: 'User Fetch Error!',
        });

    return [fetch, states(requestKey)];
}

/**
 * Post User Login
 * @return [fetch, state]
 */
export function useAPIUserLogin() {
    const { post, states } = useAPIRequest();
    const { setAuth } = useAuth();

    const requestKey = 'system.auth.register';
    const fetch = data =>
        post(requestKey, '/auth/login', data, x => x, {
            loading: 'ログイン処理中です',
            success: 'ログインが完了しました',
            error: 'ログインに失敗しました',
        }).then(res => {
            setAuth({
                token: res.data.data.access_token,
                authType: authTypeConstants.SYSTEM,
                user: res.data.data.user,
                tokenExpiresIn: new Date().getTime() + res.data.data.expires_in * 1000, // in millisecond
            });
        });

    return [fetch, states(requestKey)];
}

/**
 * Refresh Token
 * @return [fetch, state]
 */
export function useAPIUserRefreshToken() {
    const { post, states } = useAPIRequest();
    const { auth, setAuth } = useAuth();

    const requestKey = 'system.auth.refreshToken';
    const fetch = () =>
        post(requestKey, '/auth/refresh', {}, x => x, null).then(res => {
            auth.token = res.data.data.access_token;
            auth.tokenExpiresIn = new Date().getTime() + res.data.data.expires_in * 1000; // in millisecond
            setAuth(auth);
        });

    return [fetch, states(requestKey)];
}
