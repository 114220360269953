import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * 編集ボタン付きの入力フィールドコンポーネント。編集ボタンを押すと編集状態に遷移し、入力フィールドの値を変更できるようになります。
 * @param {object} props
 * `value` 入力フィールドに付与されるvalue属性。
 * `onChange` 入力フィールドに付与されるonChange属性。
 * `inputRender` 編集がロック状態か否かを表す変数を受け取り、inputエレメントを返す render prop。
 * `buttonRender` 編集がロック状態か否かを表す変数を受け取り、buttonエレメントを返す render prop。
 * `name` [option]入力フィールドに付与されるname属性。
 * `onStateChange` [option]編集状態を表す変数の変化をトリガーするコールバック関数。
 * @param {string} props.value
 * @param {function(boolean)} props.onChange
 * @param {function(boolean): JSX.Element} props.inputRender
 * @param {function(boolean): JSX.Element} props.buttonRender
 * @param {string} [props.name]
 * @param {function(boolean)} [props.onStateChange]
 *
 * @example
 * const [mailaddress, setMailaddress] = useState("")
 * const handleChange = (e) => setMailaddress(e.target.value)
 * <EditableInputField
 *   name="mailaddress"
 *   value={mailaddress}
 *   onChange={handleChange}
 *   inputRender={(locked) => <input className="form-control" />}
 *   buttonRender={(locked) => <button className={`btn btn${locked ? "-outline" : ""}-primary`} type="button">{locked ? "編集" : "確定"}</button>}
 * />
 */
export default function EditableInputField(props) {
    const {
        name,
        value,
        onChange,
        onStateChange = () => {},
        onConfirm = () => {},
        inputRender,
        buttonRender,
    } = props;

    const inputEl = useRef();

    const [locked, setLocked] = useState(true);

    const [prevValue, setPrevValue] = useState(value);

    const toggleLock = () => {
        const newLocked = !locked;
        setLocked(newLocked);
        setTimeout(() => inputEl.current.focus(), 0);
        onStateChange(newLocked);
        if (newLocked) {
            if (prevValue !== value) onConfirm(name, value);
        } else {
            setPrevValue(value);
        }
    };

    const handleChange = e => {
        onChange(e);
    };

    return (
        <div className="input-group">
            {React.cloneElement(inputRender(locked), {
                name,
                value,
                onChange: handleChange,
                disabled: locked,
                ref: inputEl,
            })}
            <div className="input-group-append">
                {React.cloneElement(buttonRender(locked), {
                    onClick: toggleLock,
                })}
            </div>
        </div>
    );
}

EditableInputField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    inputRender: (props, propName, componentName) => {
        PropTypes.checkPropTypes(
            { returnValue: PropTypes.element.isRequired },
            { returnValue: props[propName](true) },
            propName,
            componentName
        );
        PropTypes.checkPropTypes(
            { returnValue: PropTypes.element.isRequired },
            { returnValue: props[propName](false) },
            propName,
            componentName
        );
    },
    buttonRender: (props, propName, componentName) => {
        PropTypes.checkPropTypes(
            { returnValue: PropTypes.element.isRequired },
            { returnValue: props[propName](true) },
            propName,
            componentName
        );
        PropTypes.checkPropTypes(
            { returnValue: PropTypes.element.isRequired },
            { returnValue: props[propName](false) },
            propName,
            componentName
        );
    },
    name: PropTypes.string,
    onStateChange: PropTypes.func,
};
