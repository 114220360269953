import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
/**
 * アイコンを用いたボタンコンポーネント。子要素にIconを与える。
 * @param {object} props
 * @param {JSX.Element} props.children
 * @param {SVGElement} props.others
 */

export default function IconButton(props) {
    const { children, className, Badge = null, disabled, ...others } = props;
    return (
        <div
            className={classNames('icon-button-wrapper', className, disabled ? 'disabled' : '')}
            tabIndex="0"
            role="button"
            {...others}
        >
            <div className="icon-button">{children}</div>
            {Badge}
        </div>
    );
}

IconButton.propTypes = {
    children: PropTypes.element.isRequired,
};
