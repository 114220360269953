import { usePathData } from 'dataProvider/pathData';
import { useHistory, useLocation } from 'react-router-dom';

export default function useTransition() {
    const location = useLocation();
    const history = useHistory();

    // 現在のURLのサブにアクセスする（ex /users => /users/0001）
    const pushHistory = (newPathname, state) => {
        history.push(
            (location.pathname.slice(-1) === '/'
                ? location.pathname.slice(0, -1)
                : location.pathname) + newPathname,
            state
        );
    };

    // 現在のURLの一つ上のURLにアクセスする（ex /users/0001 => /users）
    const pushHistoryParent = state => {
        const basePathnameStr = getParentPath();
        history.push(basePathnameStr, state);
    };

    // ex /company1/logout => /company2/logout）
    const pushHistorySibling = (newPathname, state) => {
        const basePathnameStr = getParentPath();
        history.push(basePathnameStr + newPathname, state);
    };

    // ex /company1/logout => (string) /company1
    const getParentPath = () => {
        const normalizedPathname =
            location.pathname.slice(-1) === '/'
                ? location.pathname.slice(0, -1)
                : location.pathname;
        const [, ...basePathname] = normalizedPathname.split('/').reverse();
        return basePathname.reverse().join('/');
    };

    // 上記以外のURL遷移（ex /users => /posts/0001）
    const { base /*rerender*/ } = usePathData();
    const pushHistoryAbs = (newPathname, state) => {
        history.push(base + newPathname, state);
    };
    return { pushHistory, pushHistoryParent, pushHistoryAbs, pushHistorySibling, getParentPath };
}
