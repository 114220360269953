import React, { useMemo } from 'react';
import classNames from 'classnames';
import PostechIcon from 'components/icons/Logo';
import useTransition from 'hooks/useTransition';
import ProfileBlock from './components/ProfileBlock';
import { usePathData } from 'dataProvider/pathData';
import { BOTTOM_SIDEBAR_DATA, buildMenu, sidebarData } from './sidebarData';
import { useGlobalData } from 'dataProvider/globalData';
import { useAuth } from 'dataProvider/authData';
import { useClientData } from 'dataProvider/clientData';
import { useClientUsages } from 'dataProvider/clientData';
import StretchButton from './components/StretchButton';
import { authTypeConstants } from 'constants/auth';

export default function Sidebar(props) {
    const { hide, onClickOverlay, onClickLink } = props;
    const { pushHistoryAbs } = useTransition();
    const [{ screen }] = useGlobalData();
    const {
        auth: { user, authType },
    } = useAuth();
    const { clientUsages } = useClientUsages();

    // ページを遷移させる
    const handleClickLink = path => () => {
        pushHistoryAbs(path);
        onClickLink();
    };

    const { spaRoute, userType } = usePathData();
    const { name: clientName } = useClientData();

    const contentId = useMemo(() => spaRoute.substring(1));

    const mainMenuItems = useMemo(() => {
        return sidebarData(userType, user, clientUsages, screen);
    }, [userType, user, clientUsages, screen]);

    const bottomMenuItems = useMemo(() => {
        return buildMenu(BOTTOM_SIDEBAR_DATA, { clientUsages, user, screen });
    }, [clientUsages, user, screen]);

    return (
        <React.Fragment>
            {screen === 'sp' && (
                <div
                    className={classNames('sidebar-overlay', hide ? '' : 'show')}
                    onClick={onClickOverlay}
                />
            )}
            <div id="sidebar" className={hide ? 'hide' : ''}>
                <div className="sidebar-top">
                    <PostechIcon className="sidebar-item icon-item icon-primary" />
                </div>
                <div className="sidebar-middle-fix">
                    <ProfileBlock
                        clientName={clientName ?? ''}
                        userName={user?.name ?? ''}
                        userRoles={
                            authType === authTypeConstants.USER
                                ? [{ name: 'ユーザー' }]
                                : user?.roles
                        }
                    />
                </div>

                {/* Main Menu */}
                <div className="sidebar-main">
                    {mainMenuItems.map(({ label, Icon, url, id, children }) => (
                        <StretchButton
                            key={id}
                            {...{ children, contentId, url, label, Icon, handleClickLink, id }}
                        />
                    ))}
                </div>

                {/* Bottom Menu */}
                <div className="sidebar-bottom">
                    {bottomMenuItems.map(({ label, Icon, url, id, children }) => (
                        <StretchButton
                            key={id}
                            {...{ children, contentId, url, label, Icon, handleClickLink, id }}
                        />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}
