import React, { useState } from 'react';
import { EyeSlashFill, EyeFill } from 'react-bootstrap-icons';

export default function InputField(props) {
    const { value, onChange, name, label, password, error } = props;

    const [hidden, setHidden] = useState(true);
    const toggleHidden = _ => setHidden(!hidden);

    return (
        <div className="mt-4">
            <label>{label}</label>
            <div className="position-relative">
                <input
                    type={password && hidden ? 'password' : 'text'}
                    className="form-control"
                    name={name}
                    value={value}
                    onChange={onChange}
                    {...props}
                />
                {password && (
                    <div className="input-group-loating-addon">
                        <button className="btn btn-link" type="button" onClick={toggleHidden}>
                            {hidden ? <EyeSlashFill /> : <EyeFill />}
                        </button>
                    </div>
                )}
            </div>
            {error && (
                <div className="col-sm-12">
                    <small className="danger">{error}</small>
                </div>
            )}
        </div>
    );
}
