

import * as React from 'react';
import classNames from 'classnames';

export default function QR(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames(className)}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...others}
        >
            <path
                d="M19.125 15.75H16.125C15.9179 15.75 15.75 15.9179 15.75 16.125V19.125C15.75 19.3321 15.9179 19.5 16.125 19.5H19.125C19.3321 19.5 19.5 19.3321 19.5 19.125V16.125C19.5 15.9179 19.3321 15.75 19.125 15.75Z"
                fill="currentColor"
            />
            <path
                d="M15.375 12.75H13.125C12.9179 12.75 12.75 12.9179 12.75 13.125V15.375C12.75 15.5821 12.9179 15.75 13.125 15.75H15.375C15.5821 15.75 15.75 15.5821 15.75 15.375V13.125C15.75 12.9179 15.5821 12.75 15.375 12.75Z"
                fill="currentColor"
            />
            <path
                d="M22.125 19.5H19.875C19.6679 19.5 19.5 19.6679 19.5 19.875V22.125C19.5 22.3321 19.6679 22.5 19.875 22.5H22.125C22.3321 22.5 22.5 22.3321 22.5 22.125V19.875C22.5 19.6679 22.3321 19.5 22.125 19.5Z"
                fill="currentColor"
            />
            <path
                d="M22.125 12.75H20.625C20.4179 12.75 20.25 12.9179 20.25 13.125V14.625C20.25 14.8321 20.4179 15 20.625 15H22.125C22.3321 15 22.5 14.8321 22.5 14.625V13.125C22.5 12.9179 22.3321 12.75 22.125 12.75Z"
                fill="currentColor"
            />
            <path
                d="M14.625 20.249H13.125C12.9179 20.249 12.75 20.4169 12.75 20.624V22.124C12.75 22.3311 12.9179 22.499 13.125 22.499H14.625C14.8321 22.499 15 22.3311 15 22.124V20.624C15 20.4169 14.8321 20.249 14.625 20.249Z"
                fill="currentColor"
            />
            <path
                d="M19.125 4.50098H16.125C15.9179 4.50098 15.75 4.66887 15.75 4.87598V7.87597C15.75 8.08308 15.9179 8.25097 16.125 8.25097H19.125C19.3321 8.25097 19.5 8.08308 19.5 7.87597V4.87598C19.5 4.66887 19.3321 4.50098 19.125 4.50098Z"
                fill="currentColor"
            />
            <path
                d="M21 2.25098H14.25C13.8358 2.25098 13.5 2.58676 13.5 3.00098V9.75097C13.5 10.1652 13.8358 10.501 14.25 10.501H21C21.4142 10.501 21.75 10.1652 21.75 9.75097V3.00098C21.75 2.58676 21.4142 2.25098 21 2.25098Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.875 4.50098H4.875C4.66789 4.50098 4.5 4.66887 4.5 4.87598V7.87597C4.5 8.08308 4.66789 8.25097 4.875 8.25097H7.875C8.0821 8.25097 8.25 8.08308 8.25 7.87597V4.87598C8.25 4.66887 8.0821 4.50098 7.875 4.50098Z"
                fill="currentColor"
            />
            <path
                d="M9.75 2.25098H3C2.58579 2.25098 2.25 2.58676 2.25 3.00098V9.75097C2.25 10.1652 2.58579 10.501 3 10.501H9.75C10.1642 10.501 10.5 10.1652 10.5 9.75097V3.00098C10.5 2.58676 10.1642 2.25098 9.75 2.25098Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M7.875 15.75H4.875C4.66789 15.75 4.5 15.9179 4.5 16.125V19.125C4.5 19.3321 4.66789 19.5 4.875 19.5H7.875C8.0821 19.5 8.25 19.3321 8.25 19.125V16.125C8.25 15.9179 8.0821 15.75 7.875 15.75Z"
                fill="currentColor"
            />
            <path
                d="M9.75 13.499H3C2.58579 13.499 2.25 13.8348 2.25 14.249V20.999C2.25 21.4132 2.58579 21.749 3 21.749H9.75C10.1642 21.749 10.5 21.4132 10.5 20.999V14.249C10.5 13.8348 10.1642 13.499 9.75 13.499Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}

