import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PasswordSend from './PasswordSend/PasswordSend';
import PasswordReset from './PasswordReset/PasswordReset';
import relativePathHandler from 'utils/relativePathHandler';

export default function UserRoute(props) {
    const rel = relativePathHandler(props.match.url);

    return (
        <Switch>
            <Route exact path={rel('/send')} component={PasswordSend} />
            <Route exact path={rel('/reset/:token')} component={PasswordReset} />
            <Redirect to={rel('/send')} />
        </Switch>
    );
}
