import * as React from 'react';
import classNames from 'classnames';

export default function Account(props) {
    const { className, ...others } = props;
    return (
        // eslint-disable-next-line react/no-unknown-property
        <svg
            className={classNames('icon', className)}
            vwidth="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...others}
        >
            <circle
                cx="10.125"
                cy="6.125"
                r="4.125"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
            />
            <path
                d="M2 20.25C2 20.8023 2.44772 21.25 3 21.25C3.55228 21.25 4 20.8023 4 20.25H2ZM11.178 14.7693C11.6721 15.0162 12.2727 14.8158 12.5195 14.3217C12.7664 13.8277 12.566 13.2271 12.072 12.9802L11.178 14.7693ZM4 20.25C4 17.0744 6.57436 14.5 9.75 14.5V12.5C5.46979 12.5 2 15.9698 2 20.25H4ZM9.75 14.5C10.2024 14.5 10.5777 14.5755 10.8348 14.649C10.9628 14.6855 11.0594 14.7209 11.119 14.7448C11.1488 14.7567 11.1691 14.7656 11.1792 14.7701C11.1843 14.7724 11.1868 14.7736 11.1866 14.7735C11.1865 14.7735 11.1858 14.7731 11.1843 14.7724C11.1836 14.7721 11.1827 14.7716 11.1817 14.7711C11.1812 14.7709 11.1806 14.7706 11.18 14.7703C11.1797 14.7701 11.1794 14.77 11.179 14.7698C11.1789 14.7697 11.1786 14.7696 11.1785 14.7696C11.1783 14.7694 11.178 14.7693 11.625 13.8748C12.072 12.9802 12.0717 12.9801 12.0715 12.98C12.0714 12.9799 12.0711 12.9798 12.0709 12.9797C12.0705 12.9795 12.0701 12.9793 12.0697 12.9791C12.0689 12.9787 12.0681 12.9783 12.0672 12.9779C12.0654 12.977 12.0635 12.976 12.0613 12.9749C12.057 12.9728 12.0519 12.9704 12.046 12.9676C12.0343 12.962 12.0196 12.9552 12.0019 12.9472C11.9667 12.9313 11.9196 12.9109 11.8614 12.8876C11.7453 12.8412 11.5841 12.783 11.3839 12.7258C10.9848 12.6119 10.4226 12.5 9.75 12.5V14.5Z"
                fill="currentColor"
            />
            <path
                d="M12 16.5V15.5C11.4477 15.5 11 15.9477 11 16.5H12ZM12 21H11C11 21.5523 11.4477 22 12 22V21ZM21 21V22C21.5523 22 22 21.5523 22 21H21ZM21 16.5H22C22 15.9477 21.5523 15.5 21 15.5V16.5ZM11 16.5V21H13V16.5H11ZM12 22H21V20H12V22ZM22 21V16.5H20V21H22ZM14.25 15.5H12V17.5H14.25V15.5ZM15.25 16.5V14H13.25V16.5H15.25ZM16.25 13H16.75V11H16.25V13ZM21 15.5H18.75V17.5H21V15.5ZM18.75 15.5H14.25V17.5H18.75V15.5ZM17.75 14V16.5H19.75V14H17.75ZM16.75 13C17.3023 13 17.75 13.4477 17.75 14H19.75C19.75 12.3431 18.4069 11 16.75 11V13ZM15.25 14C15.25 13.4477 15.6977 13 16.25 13V11C14.5931 11 13.25 12.3431 13.25 14H15.25Z"
                fill="currentColor"
            />
        </svg>
    );
}
