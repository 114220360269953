import React, { useEffect, createContext, useContext } from 'react';
import { useGlobalData } from 'dataProvider/globalData';
import { useLocation } from 'react-router-dom';
import useData from 'hooks/useDataState';
import urlAnalyze from '../utils/urlAnalyzer';

const PageContext = createContext({});

/**
 * URLをパースしたデータを提供するプロバイダ。
 * 下位コンポーネントから`usePathData`フックを用いてアクセス可能。提供するデータは以下の通り
 * + `userType` : "admin"|"user"|"system"。順に、企業管理側/ユーザー/システム側
 * + `clientId` : 企業ごとに割り振られる一意なID
 * + `base` : URLの前半部分。ユーザーが変わらない限り普遍な部分。
 * + `spaRoute` : URLの後半部分。ユーザーがページ遷移すると変化する部分。動作が安定しないので使わない方が良い。
 *
 * <例>
 * http://app.localhost/sampleCompany/admin/posts/create というURLにアクセスしているときは以下のデータが返される
 *
 * `{ userType: "admin", clientId: "sampleCompmany", base: "/sampleCompany/admin", spaRoute: "posts/create" }`
 *
 *
 * @example
 * import { usePathData } from 'dataProvider/pathData'
 * ...
 * const data = usePathData()
 */
export default function PathDataProvider(props) {
    const { children } = props;
    const location = useLocation();
    const pathname = location.pathname;
    const [{ subDomain }] = useGlobalData();
    const { userType, clientId, spaRoute, base } = urlAnalyze(pathname, subDomain);

    const PageDataProvider = PageContext.Provider;

    const [pageData, updatePageData] = useData({
        userType,
        clientId,
        spaRoute,
        base,
        pathname,
    });
    useEffect(() => {
        const { userType, clientId, spaRoute, base } = urlAnalyze(pathname, subDomain);
        updatePageData('userType', userType);
        updatePageData('clientId', clientId);
        updatePageData('spaRoute', spaRoute);
        updatePageData('base', base);
    }, [pathname]);

    return <PageDataProvider value={pageData}>{children}</PageDataProvider>;
}

/**
 * App Path Data Provider
 * @returns {{userType, clientId, spaRoute, base, pathname}}
 */
const usePathData = () => useContext(PageContext);
export { usePathData };
