import { useAPIRequest } from 'dataProvider/postStateData/requestStateData';

/**
 * Update admin
 * @returns [submit, state]
 */
export function useAPIAdminUpdate() {
    const { put, states } = useAPIRequest();

    const requestKey = 'admin.admin.update';
    const submit = (id, data) =>
        put(requestKey, `/admins/${id}`, data, x => x, {
            // TODO: Translate this
            loading: 'Updating Root Admin',
            success: 'Updating Root Admin  Succeed',
            error: 'Updating Root Admin Failed',
        });

    return [submit, states(requestKey)];
}

/**
 * Change Root Admin
 * @returns [submit, state]
 */
export function useAPIAdminChangeRoot() {
    const { put, states } = useAPIRequest();

    const requestKey = 'admin.admin.changerootadmin';
    const submit = data =>
        put(requestKey, `/admins/change-root`, data, x => x, {
            // TODO: Translate this
            loading: 'Changing Root Admin',
            success: 'Changing Root Admin Succeed',
            error: 'Changing Root Admin Failed',
        });

    return [submit, states(requestKey)];
}
