import * as React from 'react';
import classNames from 'classnames';

export default function Plus(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            width="1em"
            height="1em"
            viewBox="0 0 18 18"
            fill="none"
            {...others}
        >
            <path
                d="M9 2.25V15.75"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.7499 9H2.24991"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
