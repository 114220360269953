import { useEffect, useRef, useState } from 'react';

const defaultOptions = {
    interval: 30 * 1000, // 30 seconds,
};

// Call useAPIData with interval
export default function useInterval([fetch, res, ...rest], _options = {}) {
    const options = { ...defaultOptions, ..._options };

    const [init, setInit] = useState(false);
    const [fetchProps, setFetchProps] = useState({ page: undefined, params: undefined });
    const timer = useRef(null);

    // start function
    const start = (page, params) => {
        setInit(true);
        setFetchProps({ page, params });

        // init fetch
        fetch(page, params);
    };

    // watch `fetchProps` to start interval
    useEffect(() => {
        if (!init) return;

        // cancel previous timer
        timer.current && clearInterval(timer.current);

        // start new timer
        timer.current = setInterval(() => {
            fetch(fetchProps.page, fetchProps.params);
        }, options.interval);

        // cleanup
        return function cleanup() {
            clearInterval(timer.current);
        };
    }, [fetchProps]);

    return [start, res, ...rest];
}
