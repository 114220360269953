import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useDataState from 'hooks/useDataState';
import InputForm from 'components/InputForm';

/**
 *
 * @param {Object} props
 * + `children` フォームの部品となるコンポーネント。InputFormコンポーネントに対しては、InputFormに代わりFormがデータ処理を行う
 * + `value` フォーム内のInputFormコンポーネントの名前（name属性）と、渡される値（value属性）のペアからなるオブジェクト。 (ex `{name: "山田太郎", age: "17"}`)
 * + `onChange` フォーム内のInputFormコンポーネントに付与されるonChange属性。
 * + `className` [Option] フォームの最も外側のdivに適用されるclassName属性。
 * + `proportion` フォーム内のInputFormコンポーネントに付与されるproportion属性。
 * + `disabled` フォーム内のInputFormコンポーネントに付与されるdisabled属性。一括でdisabledにできる。
 * @param {JSX.Element} props.children
 * @param {string} props.className
 * @param {Object} props.value
 * @param {(e:Event)=>any} props.onChange
 * @param {[number]} props.proportion
 * @param {boolean} props.disabled
 *
 * @example
 * const [clientData, handleClientData] = useState({name: "Postech株式会社", switch: true})
 * const handleChange = e => {
 *   setClientData({...clientData, [e.target.key]: e.target.value})
 * }
 *
 * <Form
 *   className="form-m-2 mx-3 my-4"
 *   value={clientData}
 *   onChange={handleChange}
 * >
 *   <InputForm
 *     type="editable"
 *     label="企業名"
 *     name="name"
 *   />
 *   <InputForm
 *     type="switch"
 *     id="switch"
 *     label="ファイルアップロード"
 *     name="allowFileUpload"
 *   />
 * </Form>
 */
export default function Form(props) {
    const {
        children,
        className,
        value,
        onChange,
        onStateChange,
        onConfirm,
        proportion,
        disabled,
    } = props;
    const [editing, setEditing] = useState(null);
    const handleStateChange = name => done => {
        onStateChange(done, name);
        if (!done) {
            setEditing(name);
        } else {
            setEditing(null);
        }
    };

    return (
        <div className={classNames('form', className)}>
            {React.Children.map(children, child => {
                if (child != null && child.type === InputForm && !child.props.ignoreForm) {
                    const { name, proportion: childProportion } = child.props;
                    return React.cloneElement(child, {
                        value: value[name],
                        onChange,
                        onConfirm,
                        proportion: childProportion ?? proportion,
                        onStateChange: handleStateChange(name),
                        disabled:
                            (editing != null && editing !== name) ||
                            disabled ||
                            child.props.disabled,
                    });
                } else {
                    return child;
                }
            })}
        </div>
    );
}

Form.propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    proportion: PropTypes.arrayOf(PropTypes.number),
    disabled: PropTypes.bool,
};

/**
 * Formコンポーネントにおけるvalueの管理を簡単にするhooks。
 * @param {Object} init 初期値
 * @returns {[Object, Function]} 現在のオブジェクトの値と、FormコンポーネントのonChange属性に与えるhandleChange関数が含まれる長さ2の配列。
 * @example
 * const [clientData, handleClientData] = useForm({name: "Postech株式会社", switch: true})
 *
 * <Form
 *   className="form-m-2 mx-3 my-4"
 *   value={clientData}
 *   onChange={handleClientData}
 * >
 *   <InputForm
 *     type="editable"
 *     label="企業名"
 *     name="name"
 *   />
 *   <InputForm
 *     type="switch"
 *     id="switch"
 *     label="ファイルアップロード"
 *     name="allowFileUpload"
 *   />
 * </Form>
 */
export const useForm = (init, schema) => {
    const [data, updateData] = useDataState(init);
    const [errors, setErrors] = useState({});

    const handleChange = e => {
        updateData(e.target.name, e.target.value);
    };

    const validate = (showError = true) => {
        if (schema) {
            try {
                return !!schema.validateSync(data, { abortEarly: false });
            } catch (err) {
                const _errors = err.inner.reduce((acc, cur) => {
                    // get the first error only
                    acc[cur.path] = acc[cur.path] ?? cur.message;
                    return acc;
                }, {});
                showError && setErrors(_errors);
                return false;
            }
        }
        return false;
    };

    const isValid = useMemo(() => validate(false), [data]);

    return [data, handleChange, updateData, validate, errors, isValid];
};
