import * as React from 'react';
import classNames from 'classnames';

export default function ArrowUp(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            {...others}
        >
            <path
                d="M3.49988 8.125L7.99988 3.625L12.4999 8.125"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99988 4.25L7.99988 13.375"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
