import React, { useCallback, useEffect, useState } from 'react';
import { throttle } from 'lodash';
import SearchInputField from './SearchInputField';

export default function TableToolbar(props) {
    // extract props
    const {
        showSearch = true,
        searchLabel = '検索',
        onClickSearch,
        filters,
        topActions,
        searchKeyword: _searchKeyword = '',
    } = props;

    /* --- SEARCH --- */

    const [serachKeyword, setSearchKeyword] = useState(_searchKeyword);
    const handleSearchKeywordChange = e => {
        setSearchKeyword(e.target.value);
    };

    const throttledOnSearch = useCallback(throttle(onClickSearch, 300), [onClickSearch]);

    useEffect(() => {
        throttledOnSearch(serachKeyword);
    }, [serachKeyword]);

    return (
        <div className="tool-wrapper">
            <div className="panel-left">
                {showSearch && onClickSearch != null && (
                    <SearchInputField
                        value={serachKeyword}
                        onChange={handleSearchKeywordChange}
                        placeholder={searchLabel}
                    />
                )}
                {filters && <div className="filter-wrapper">{filters}</div>}
            </div>
            {topActions && <div className="panel-right">{topActions}</div>}
        </div>
    );
}
