import React from 'react';
import logoutImage from 'assets/imgs/assetC1.png';
export default function LogoutPage() {
    return (
        <div className="page logout-template">
            <img src={logoutImage} />
            <p className="text-center ml-4 mb-0">
                <strong>ログアウトが完了しました</strong>
            </p>
        </div>
    );
}
