import React from 'react';

export default function MobileTableLayout(props) {
    const { data, recordRenderer = () => {} } = props;

    return (
        <div className="mobile-table-layout">
            <div className="mobile-table-layout__body">
                <div className="mobile-table-layout__body__rows">
                    {data.map((item, index) => (
                        <div className="mobile-table-layout__body__rows_record" key={index}>
                            {recordRenderer(item, index)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
