import PostBox from 'components/icons/PostBox';
import Posts from 'components/icons/Posts';
import SetUp from 'components/icons/SetUp';
import roleType from 'constants/roleType';
import Account from '../icons/Account';
import Division from '../icons/Division';
import UserGroup from '../icons/UserGroup';
import Whistleblower from '../icons/Whistleblower';
import Logout from 'components/icons/Logout';
import Sync from 'components/icons/Sync';

const ADMIN_SIDEBAR_DATA = [
    {
        label: '投稿',
        Icon: Posts,
        url: '/posts',
        id: 'posts',
        permission: [roleType.ROOT, roleType.DEFAULT],
    },
    {
        label: '内部通報',
        Icon: Whistleblower,
        url: '/reports',
        id: 'reports',
        permission: [roleType.INTERNAL_REPORT],
        isEnabled: ({ clientUsages }) => !clientUsages?.DisableInternalReport,
    },
    {
        label: '投稿設定',
        Icon: Division,
        id: 'post-setting',
        children: [
            {
                label: 'カテゴリ',
                url: '/categories',
                id: 'categories',
                permission: [roleType.ROOT, roleType.SUPPORT],
            },
            {
                label: 'グループ',
                url: '/groups',
                id: 'groups',
                permission: [roleType.ROOT, roleType.SUPPORT],
                isEnabled: ({ clientUsages }) => !clientUsages?.DisableUserGroup,
            },
            {
                label: '権限',
                url: '/roles',
                id: 'roles',
                permission: [roleType.ROOT, roleType.SUPPORT],
                isEnabled: ({ clientUsages }) => !clientUsages?.DisableRoleManagement,
            },
        ],
    },
    {
        label: 'メンバー設定',
        Icon: UserGroup,
        id: 'user-setting',
        children: [
            {
                label: 'ユーザー',
                url: '/users',
                id: 'users',
                permission: [roleType.ROOT, roleType.SUPPORT],
            },
            {
                label: '管理者',
                url: '/operators',
                id: 'operators',
                permission: [roleType.ROOT, roleType.SUPPORT],
            },
        ],
    },
    {
        label: 'その他',
        Icon: SetUp,
        id: 'settings',
        children: [
            {
                label: 'アナウンス',
                url: '/announcements',
                id: 'announcements',
                permission: [roleType.ROOT, roleType.SUPPORT],
                isEnabled: ({ clientUsages }) => !clientUsages?.DisableAnouncementFunction,
            },
            {
                label: '運用方針',
                url: '/contents',
                id: 'contents',
                permission: [roleType.ROOT, roleType.SUPPORT],
            },
            {
                label: 'テンプレート',
                url: '/templates',
                id: 'templates',
                permission: [roleType.ROOT, roleType.SUPPORT],
            },
            {
                label: 'セキュリティポリシー',
                url: '/password-rules',
                id: 'password-rules',
                permission: [roleType.ROOT, roleType.SUPPORT],
            },
            {
                label: 'IPアドレス制限',
                url: '/access_restrictions',
                id: 'access_restrictions',
                permission: [roleType.ROOT, roleType.SUPPORT],
                isEnabled: ({ clientUsages }) => !clientUsages?.DisableIpRestriction,
            },
        ],
    },
    { label: 'アカウント設定', Icon: Account, url: '/profile', id: 'profile' },
];

const USER_SIDEBAR_DATA = [
    { label: '投稿', Icon: Posts, url: '/posts', id: 'posts' },
    {
        label: '内部通報',
        Icon: Whistleblower,
        url: '/reports',
        id: 'reports',
        isEnabled: ({ clientUsages }) => !clientUsages?.DisableInternalReport,
    },
    { label: 'アカウント設定', Icon: Account, url: '/profile', id: 'profile' },
];

const SYSTEM_SIDEBAR_DATA = [{ label: '企業一覧', Icon: PostBox, url: '/clients', id: 'clients' }];

const SIDEBAR_DATA = {
    admin: ADMIN_SIDEBAR_DATA,
    user: USER_SIDEBAR_DATA,
    system: SYSTEM_SIDEBAR_DATA,
};

export const BOTTOM_SIDEBAR_DATA = [
    {
        label: '運用方針',
        Icon: SetUp,
        url: '/contents',
        id: 'contents',
        screen: 'sp',
        isEnabled: ({ user }) => !user?.roles, // is User: user does not have roles
    },
    {
        label: '管理者切替',
        Icon: Sync,
        url: '/switch',
        id: 'switch',
        screen: 'sp',
        isEnabled: ({ user }) => !!user?.is_linked,
    },
    { label: 'ログアウト', Icon: Logout, url: '/logout', id: 'logout' },
];

const filterByPermission = roleTypes => item => {
    if (!roleTypes) return true;

    const hasPermissionAttr = item.permission !== undefined;
    return hasPermissionAttr ? roleTypes?.some(type => item.permission.includes(type)) : true;
};

const filterByScreen = screen => item => {
    const hasScreenAttr = item.screen !== undefined;
    return hasScreenAttr ? item.screen === screen : true;
};

const filterByIsEnabled = ({ clientUsages, user, screen }) => item => {
    const hasIsEnabledAttr = item.isEnabled !== undefined;
    return hasIsEnabledAttr ? item.isEnabled({ clientUsages, user, screen }) : true;
};

/**
 * Build Menu (Filter & Mapping)
 *
 * @param {array} items
 * @param {{clientUsages, user, screen}} options
 * @returns Array
 */
export const buildMenu = (items, { clientUsages, user, screen }) => {
    const roleTypes = user?.roles?.map(role => role.type);

    return (
        items
            // filters
            .filter(filterByPermission(roleTypes))
            .filter(filterByScreen(screen))
            .filter(filterByIsEnabled({ clientUsages, user, screen }))
            // map (filter children recursively)
            .map(item => ({
                ...item,
                children:
                    item.children?.length > 0
                        ? buildMenu(item.children, { clientUsages, user, screen })
                        : undefined,
            }))
            // filter out empty children
            .filter(item => {
                const hasUrl = !!item.url;
                const hasChildren = item.children?.length > 0;
                return hasUrl || hasChildren;
            })
    );
};

/**
 * Get sidebar data by user type & client usages
 * @param {string} userType
 * @param {User} userData
 * @param {object} clientUsages
 * @param {string} screen ('pc'|'sp')
 * @returns array
 */
export const sidebarData = (userType, userData, clientUsages, screen = 'pc') => {
    const menuList = SIDEBAR_DATA[userType];

    return buildMenu(menuList, { clientUsages, user: userData, screen });
};
