import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from './Button';
import ModalClose from './icons/ModalClose';

Modal.setAppElement('#app');
const customStyles = {
    overlay: {
        zIndex: 1040,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        maxHeight: '90%',
        padding: '0px',
        border: 'none',
        borderRadius: '0px',
        width: '700px',
        maxWidth: 'calc(100% - 64px)',
    },
};
/**
 * 押すと確認モーダルが開く、ボタンコンポーネントです
 * @param {{
 *    modal: {
 *       body: JSX.Element,
 *       positiveLabel: string,
 *       negativeLabel: string,
 *       type: "primary" | "danger",
 *       className: string
 *    },
 *    onDetermined: function(0|1):any
 *    children: JSX.Element,
 * }} props
 *
 * `modal.body` モーダル上でユーザーに表示される、確認を促すメッセージ。
 * `modal.positiveLabel` モーダル上で「はい」の意味を表す文字列。「はい」「Yes」「同意」など。
 * `modal.negativeLabel` モーダル上で「いいえ」の意味を表す文字列。「いいえ」「No」「キャンセル」など。
 * `children` button要素。押すとモーダルが開く。
 * `className` button要素を囲むdivに適用されるclassName属性。
 * @example
 * <AlertModal
 *   modal={{
 *     body: "ユーザーを追加します。よろしいですか？",
 *     positiveLabel: "はい",
 *     negativeLabel: "いいえ"
 *   }}
 * >
 *   <button>押すとモーダルが開くよ</button>
 * </AlertModal>
 */

export default function AlertModal(props) {
    const {
        open: _open = false,
        className,
        modalClassName,
        title,
        icon,
        modal: {
            body: modalBody,
            positiveLabel: modalPositiveLabel,
            negativeLabel: modalNegativeLabel,
            type,
        },
        showFooter = true,
        showCloseButton = false,
        children,
        width,
        onConfirm = () => {},
        onCancel = () => {},
        onClose = () => {},
    } = props;

    const [open, setOpen] = useState(_open);
    const handleOpen = () => {
        setOpen(true);
    };
    const modalClose = () => {
        setOpen(false);
        onClose();
    };

    const handleConfirm = () => {
        modalClose();
        onConfirm(true);
    };

    const handleCancel = () => {
        modalClose();
        onCancel(false);
    };

    const handleClose = () => {
        modalClose();
    };

    useEffect(() => {
        setOpen(_open);
    }, [_open]);

    const _customStyles = {
        ...customStyles,
        content: {
            ...customStyles.content,
            width: width || customStyles.content.width,
        },
    };

    return (
        <React.Fragment>
            {children && (
                <div className={className}>
                    {React.cloneElement(children, {
                        onClick: handleOpen,
                    })}
                </div>
            )}
            {open && (
                <Modal
                    isOpen={open}
                    style={_customStyles}
                    shouldCloseOnOverlayClick={!showCloseButton}
                    shouldCloseOnEsc={!showCloseButton}
                    onRequestClose={handleClose}
                    className={modalClassName}
                >
                    <div className="alert-modal">
                        <div className="modal-header">
                            {icon && <div className="modal-icon">{icon}</div>}
                            {title && <p className="modal-title">{title}</p>}
                            {showCloseButton && (
                                <div className="modal-close-button" onClick={handleClose}>
                                    <ModalClose />
                                </div>
                            )}
                        </div>
                        <div className="modal-body">{modalBody}</div>
                        {showFooter && (
                            <div className="modal-footer">
                                {modalNegativeLabel && (
                                    <Button
                                        type="button"
                                        className="btn-outline-gray btn-lg btn-bold modal-footer-button"
                                        data-dismiss="modal"
                                        onClick={handleCancel}
                                    >
                                        {modalNegativeLabel}
                                    </Button>
                                )}
                                {modalPositiveLabel && (
                                    <Button
                                        className={`btn-${
                                            type === 'danger' ? 'danger' : 'primary'
                                        } btn-lg btn-bold modal-footer-button`}
                                        data-dismiss="modal"
                                        onClick={handleConfirm}
                                    >
                                        {modalPositiveLabel}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
}

AlertModal.propTypes = {
    modal: PropTypes.exact({
        body: PropTypes.node.isRequired,
        positiveLabel: PropTypes.string || '',
        negativeLabel: PropTypes.string || '',
        type: PropTypes.oneOf(['danger', 'primary']),
    }).isRequired,
    children: PropTypes.element,
    onDetermined: PropTypes.func,
};
