import * as React from 'react';
import classNames from 'classnames';

export default function Whistleblower(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            viewBox="0 0 24 24"
            fill="none"
            width="1em"
            height="1em"
            {...others}
        >
            <path
                d="M5.90571 7.53354L5.90571 7.53354L5.90684 7.53242L6.79873 6.64053C6.9076 6.53166 6.9076 6.35348 6.79873 6.2446L5.26945 4.71532L5.2691 4.71498C5.21651 4.6629 5.14549 4.63369 5.07149 4.63369C4.99748 4.63369 4.92646 4.6629 4.87387 4.71498L4.87352 4.71533L3.98163 5.60722L3.98163 5.60722L3.98128 5.60757C3.92921 5.66016 3.9 5.73117 3.9 5.80518C3.9 5.87919 3.92921 5.9502 3.98128 6.00279L3.98163 6.00314L5.51091 7.53242C5.62039 7.6419 5.79548 7.64032 5.90571 7.53354ZM20.096 4.71532L20.0957 4.71498C20.0431 4.6629 19.9721 4.63369 19.8981 4.63369C19.8241 4.63369 19.753 4.6629 19.7004 4.71498L19.7001 4.71533L18.1708 6.2446L18.1705 6.24495C18.1184 6.29754 18.0892 6.36856 18.0892 6.44257C18.0892 6.51658 18.1184 6.58759 18.1705 6.64018L18.1708 6.64053L19.0627 7.53242C19.1716 7.64129 19.3498 7.64129 19.4586 7.53242L20.9879 6.00315L20.989 6.00201C21.0958 5.89179 21.0974 5.7167 20.9879 5.60722L20.096 4.71532ZM5.19784 18.3964C5.19784 18.8503 5.56468 19.2171 6.01856 19.2171H18.9465C19.4004 19.2171 19.7672 18.8503 19.7672 18.3964V13.1486C19.7672 9.12495 16.5062 5.86396 12.4825 5.86396C8.45883 5.86396 5.19784 9.12495 5.19784 13.1486V18.3964ZM7.01946 13.1486C7.01946 10.1318 9.46568 7.68559 12.4825 7.68559C15.4994 7.68559 17.9456 10.1318 17.9456 13.1486V17.3955H10.1501V13.6441C10.1501 13.465 10.004 13.3189 9.82487 13.3189H8.83387C8.65477 13.3189 8.50865 13.465 8.50865 13.6441V17.3955H7.01946V13.1486ZM5.27532 20.4586C4.82144 20.4586 4.45459 20.8254 4.45459 21.2793V21.8198C4.45459 21.9741 4.58045 22.1 4.73477 22.1H20.2303C20.3846 22.1 20.5105 21.9741 20.5105 21.8198V21.2793C20.5105 20.8254 20.1436 20.4586 19.6897 20.4586H5.27532ZM11.8519 4.62252H13.1132C13.2675 4.62252 13.3933 4.49667 13.3933 4.34234V2.18018C13.3933 2.02585 13.2675 1.9 13.1132 1.9H11.8519C11.6976 1.9 11.5717 2.02585 11.5717 2.18018V4.34234C11.5717 4.49667 11.6976 4.62252 11.8519 4.62252Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.2"
                strokeLinejoin="round"
            />
        </svg>
    );
}
