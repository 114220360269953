import React from 'react';
import classNames from 'classnames';

export default function ButtonWithIcon(props) {
    const { children, className, Icon, PostIcon, ...others } = props;
    return (
        <div
            className={classNames('button-with-icon', className)}
            tabIndex="0"
            role="button"
            {...others}
        >
            {Icon}
            <div className="button-text">{children}</div>
            {PostIcon}
        </div>
    );
}
