import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/icons/Logo';
import TwoLinesLabelledInput from 'components/TwoLinesLabelledInput';
import CheckBox from './components/CheckBox';
import useTransition from 'hooks/useTransition';

export default function LoginPageLayout(props) {
    const { onConfirm } = props;

    const [userData, setUserData] = useState({ id: '', password: '' });

    const handleInput = e => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleCheckbox = e => {
        setUserData({ ...userData, [e.target.name]: e.target.checked });
    };

    const handleClick = () => {
        onConfirm(userData);
    };

    const { pushHistoryAbs } = useTransition();
    const forgetPassword = () => {
        pushHistoryAbs('/password/send');
    };

    return (
        <div className="page login-template">
            <div className="card p-4">
                <Logo className="card-img-top logo icon-primary" />
                <h2 className="card-subtitle text-center h6">-あなたの本音を届けよう-</h2>
                <div className="card-body">
                    <TwoLinesLabelledInput
                        label="ID（メールアドレス/電話番号）"
                        name="id"
                        value={userData.id}
                        onChange={handleInput}
                    />
                    <TwoLinesLabelledInput
                        label="パスワード"
                        password
                        name="password"
                        value={userData.password}
                        onChange={handleInput}
                    />
                    <p className="card-text mb-4 mt-1">
                        <small>
                            <u>
                                <a className="text-muted cursor-pointer" onClick={forgetPassword}>
                                    パスワードを忘れた方はこちら
                                </a>
                            </u>
                        </small>
                    </p>
                    <button
                        type="button"
                        className="btn btn-secondary btn-lg btn-block mb-2"
                        onClick={handleClick}
                    >
                        ログイン
                    </button>
                    <CheckBox
                        name="remember"
                        label="ログイン状態を維持する"
                        id="keeplogincheck"
                        onChange={handleCheckbox}
                        value={1}
                    />
                </div>
            </div>
            <div className="card p-4 mt-4">
                <div className="card-body">
                    <h2 className="card-subtitle h6">お知らせ</h2>
                </div>
            </div>
        </div>
    );
}

LoginPageLayout.propTypes = {
    onConfirm: PropTypes.func.isRequired,
};
