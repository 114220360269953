import React from 'react';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';

const ThreeDotsVerticalToggle = React.forwardRef(({ onClick }, ref) => (
    <button className="btn btn-link btn-menu-toggle" ref={ref} onClick={onClick}>
        <ThreeDotsVertical />
    </button>
));

ThreeDotsVerticalToggle.displayName = 'ThreeDotsVerticalToggle';

export default function DropdownActionCell(props) {
    const { actions = [], row } = props;
    return (
        <Dropdown>
            <Dropdown.Toggle as={ThreeDotsVerticalToggle} size="lg"></Dropdown.Toggle>
            <Dropdown.Menu>
                {actions.map(
                    ({ modal: ModalComponent, ...action }, i) =>
                        (!action.display || action.display(row)) &&
                        (ModalComponent ? (
                            <ModalComponent key={i} action={action} data={row.original || row} />
                        ) : (
                            <DropdownActionCellItem
                                key={i}
                                action={action}
                                data={row.original || row}
                            />
                        ))
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export const DropdownActionCellItem = ({ action, data, onClick }) => {
    return (
        <Dropdown.Item
            className={classNames('btn btn-link bold', action.className)}
            onClick={() => {
                onClick ? onClick() : action.onClick && action.onClick(data);
            }}
            style={{ justifyContent: 'left' }}
        >
            {action.label}
        </Dropdown.Item>
    );
};

// HOC
export const withModalActionCell = (ModalComponent, onSuccess, onCancel) => {
    const DropdownActionCellItemWithModal = ({ action, data }) => {
        return (
            <ModalComponent data={data} onSuccess={onSuccess} onCancel={onCancel}>
                <DropdownActionCellItem action={action} data={data} />
            </ModalComponent>
        );
    };

    return DropdownActionCellItemWithModal;
};
