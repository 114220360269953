import { authTypeConstants } from 'constants/auth';

const MIX_API_ENDPOINT_BASE = process.env.MIX_API_ENDPOINT_BASE;
const calcAPIBase = (userType, clientId) => {
    if (userType === authTypeConstants.USER) {
        return `${MIX_API_ENDPOINT_BASE}/app/${clientId}`;
    }
    if (userType === authTypeConstants.ADMIN) {
        return `${MIX_API_ENDPOINT_BASE}/app/${clientId}/admin`;
    }
    return `${MIX_API_ENDPOINT_BASE}/system`;
};

export default calcAPIBase;
