import React, { useImperativeHandle } from 'react';
import CheckAll from './icons/CheckAll';
import CheckNone from './icons/CheckNone';

// Styled checkbox
const Checkbox = React.forwardRef((props, ref) => {
    const { checked, onChange, disabled, readonly, name, label } = props;

    useImperativeHandle(ref, () => ({
        click: () => {
            onChange({ target: { name, checked: !checked } });
        },
    }));

    const handleClick = () => {
        if (readonly) return;

        onChange({ target: { name, checked: !checked } });
    };

    return (
        <div className="styled-checkbox">
            {checked ? (
                <CheckAll onClick={handleClick} disabled={disabled} />
            ) : (
                <CheckNone onClick={handleClick} disabled={disabled} />
            )}
            <label className="form-check-label">{label}</label>
        </div>
    );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
