import * as React from 'react';
import classNames from 'classnames';

export default function Sync(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            {...others}
        >
            <path
                d="M23.7712 13.9885C23.7712 8.58922 19.3831 4.21094 13.9712 4.21094C12.499 4.20913 11.0453 4.53942 9.71838 5.17721C8.39148 5.81501 7.22552 6.74389 6.3073 7.89469M4.2002 12.3791V14.0088C4.2002 19.4141 8.58613 23.7891 14.0002 23.7891C15.4682 23.7869 16.9173 23.4573 18.2418 22.8242C19.5663 22.1911 20.7328 21.2705 21.6564 20.1294"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
            <path
                d="M1.75024 14L4.15649 11.5938L6.67212 14"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
            <path
                d="M26.2501 14L23.8439 16.4062L21.3282 14"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
        </svg>
    );
}
