import * as React from 'react';
import classNames from 'classnames';

export default function CheckNone(props) {
    const { className, disabled, ...others } = props;
    return (
        <svg
            className={classNames('icon checkbox checkbox-none', className, disabled && 'disabled')}
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="white"
            {...others}
        >
            <rect x="0.75" y="1.25" width="16.5" height="16.5" rx="1.25" fill="white" />
            <rect
                x="0.75"
                y="1.25"
                width="16.5"
                height="16.5"
                rx="1.25"
                stroke="currentColor"
                fill="white"
                strokeWidth="1.5"
            />
        </svg>
    );
}
