import * as React from 'react';
import classNames from 'classnames';

export default function ArrowLeft(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            width="1em"
            height="1em"
            viewBox="0 0 32 32"
            fill="none"
            {...others}
        >
            <path
                d="M15.2503 25L6.25031 16L15.2503 7"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
            <path
                d="M7.50012 16H25.7501"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
