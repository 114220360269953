import React, { useState, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuth } from 'dataProvider/authData';
import { usePathData } from 'dataProvider/pathData';
import { useRouteCallback } from '../hooks/useRouteCallbackHandler';
import { authTypeConstants } from 'constants/auth';

/**
 * Default Private Route
 */
export default function PrivateRoute({
    component: Component,
    authType,
    redirectTo,
    registrationPage,
    ...rest
}) {
    const { auth } = useAuth();
    const [isNewUser, setIsNewUser] = useState();
    const { callbackUrl, setThisPageAsCallback } = useRouteCallback();
    const urlQuery = rest.location?.search?.split('?url_query=')[1];
    // Set isNewUser State
    useEffect(() => {
        if (auth?.user) {
            setIsNewUser(auth.user?.is_new);
        }
    }, [auth?.user]);

    return (
        <Route
            {...rest}
            render={props => {
                // Not Authenticated
                if ((!auth?.isAuthenticated || auth.authType != authType) && !urlQuery) {
                    return <Redirect to={setThisPageAsCallback(redirectTo)} />;
                }
                // Authenticated, but A New User && Registration Page Exists
                // |--> redirect to RegistrationPage
                else if (isNewUser && registrationPage && rest.path != registrationPage) {
                    return <Redirect to={setThisPageAsCallback(registrationPage)} />;
                }
                // Authenticated, Old User --> render original component
                else {
                    if (callbackUrl && !isNewUser) {
                        return <Redirect to={callbackUrl} />;
                    } else {
                        return <Component {...props} />;
                    }
                }
            }}
        />
    );
}

/**
 * PrivateRoute for System Route
 */
export function S_PrivateRoute({ ...rest }) {
    return <PrivateRoute authType={authTypeConstants.SYSTEM} redirectTo="/login" {...rest} />;
}

/**
 * PrivateRoute for Company User Route
 */
export function U_PrivateRoute({ ...rest }) {
    const { clientId } = usePathData();
    const rel = path => `/${clientId}${path}`;

    return (
        <PrivateRoute
            authType={authTypeConstants.USER}
            redirectTo={rel('/login')}
            registrationPage={rel('/welcome')}
            {...rest}
        />
    );
}

/**
 * PrivateRoute for Company Admin Route
 */
export function A_PrivateRoute({ ...rest }) {
    const { clientId } = usePathData();
    const rel = path => `/${clientId}/admin${path}`;

    return (
        <PrivateRoute
            authType={authTypeConstants.ADMIN}
            redirectTo={rel('/login')}
            registrationPage={rel('/register')}
            {...rest}
        />
    );
}
