import * as React from 'react';
import classNames from 'classnames';

export default function ModalClose(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            {...others}
        >
            <path
                d="M1 1L16 16M16 1L1 16"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}
