import React, { useEffect } from 'react';
import { useGlobalData } from 'dataProvider/globalData';
import { useMediaQuery } from 'react-responsive';

export default React.memo(
    function MediaQueryManager(props) {
        const { children } = props;

        const handleScreenSizeChange = isDesktop => {
            updateGlobalData('screen', isDesktop ? 'pc' : 'sp');
        };

        const isDesktop = useMediaQuery(
            {
                query: '(min-width: 576px)',
            },
            undefined,
            handleScreenSizeChange
        );

        const [_, updateGlobalData] = useGlobalData();
        useEffect(() => {
            updateGlobalData('screen', isDesktop ? 'pc' : 'sp');
        }, []);

        return <React.Fragment>{children}</React.Fragment>;
    },
    () => true
);
