import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DataForm from './DataForm';
import PasswordInputField from './PasswordInputField';
import EditableInputField from './EditableInputField';
import classNames from 'classnames';
import { EyeSlashFill, EyeFill } from 'react-bootstrap-icons';
import SearchInputField from './SearchInputField';
import Checkbox from './Checkbox';

// eslint-disable-next-line react/display-name
const InpuFormBaseFunc = (label, button, error, helper, proportion, className) => (
    main,
    readMode = false,
    readModeValue = undefined
) => (
    <DataForm className={className} proportion={proportion}>
        {/* first item */}
        {label}
        {/* second item */}
        <div className="row">
            <div className="col-sm-12">{!readMode ? main : readModeValue}</div>
            {helper && (
                <div className="col-sm-12">
                    <small className="info">{helper}</small>
                </div>
            )}
            {error && (
                <div className="col-sm-12">
                    <small className="danger">{error}</small>
                </div>
            )}
        </div>
        {/* third item */}
        {button}
    </DataForm>
);

/**
 * typeに応じた2カラムor3カラムの入力フィールドを生成するコンポーネント。
 *
 * 1. `normal` [default] 入力フィールドに通常のinput要素を利用
 *  + 指定する引数（type, label, button, value, placeholder, onChange, name, disabled, proportion, className）
 * 2. `password` 入力フィールドにパスワードマスクを利用
 *  + 指定する引数（type, label, button, value, onChange, name, disabled, proportion, className）
 * 3. `text` 入力フィールドに文字列を表示（入力不能）
 *  + 指定する引数（type, label, button, value, proportion, className）
 * 4. `editable` 入力フィールドに編集ボタン付き入力フィールド（EditableInputField）を利用
 *  + 指定する引数（type, label, button, value, placeholder, onChange, name, disabled, onStateChange, onConfirm, proportion, className）
 * 5. `select` 入力フィールドにselect要素を利用
 *  + 指定する引数（type, label, button, value, onChange, name, options, proportion, className）
 * 6 `select-text` 入力フィールドにselectのOptionsのラベルをテキストとして表示
 *  + 指定する引数（type, label, button, value, onChange, name, options, proportion, className）
 * 7. `editable-select` 入力フィールドに編集ボタン付き入力フィールドを利用した上で、select要素を利用
 *  + 指定する引数（type, label, button, value, onChange, name, disabled, onStateChange, proportion, className）
 * 8. `switch` 入力フィールドにswitchボタンを利用
 *  + 指定する引数（type, label, button, name, value, onChange, disabled, helper, id ）
 * 9. `checkboxes` Multiple checkboxes
 *  + 指定する引数（type, label, name, value, onChange, options, disabled ）
 * 10. `custom` ユーザー定義の入力フィールドを利用
 *  + 指定する引数（children, proportion, className）
 *
 * @param {Object} props
 * @param {("normal"|"password"|"text"|"editable"|"select"|"editable-select"|"custom")} [props.type="normal"]
 * @param {string} props.label
 * @param {JSX.Element} props.button
 * @param {number[]} [props.proportion]
 * @param {string} [props.className]
 * @param {[{value: string, label: string, disabled: boolean}]} [props.options]
 * @param {function("locked"|"editing")} [props.onStateChange]
 * @param {string} [props.value]
 * @param {function(Event)} [props.onChange]
 * @param {function(any, any)} [props.onConfirm]
 * @param {string} [props.name]
 * @param {string} [props.placeholder]
 * @param {boolean} [props.disabled]
 * @param {string} [props.helper]
 * @param {string} [props.inputClassName]
 * @param {boolean} [props.readMode]
 * @param {JSX.Element} [props.children]
 */

export default function InputForm(props) {
    const {
        type = 'normal',
        label,
        button,
        proportion,
        className,
        error,
        helper,
        inputClassName,
        readMode = false,
        ...restProps
    } = props;
    const InputFormBase = InpuFormBaseFunc(label, button, error, helper, proportion, className);

    //通常のテキストボックス
    if (type === 'normal') {
        const { value, onChange, name, placeholder, disabled } = restProps;
        const MainContent = (
            <input
                className={`form-control ${inputClassName}`}
                {...{ value, onChange, name, placeholder, disabled }}
            />
        );
        return InputFormBase(MainContent, readMode, readMode && (value || ''));
    }

    //パスワード用テキストボックス
    if (type == 'password') {
        const { value, onChange, name, disabled } = restProps;
        const MainContent = (
            <PasswordInputField
                {...{ name, value, onChange, disabled }}
                inputRender={() => <input className={`form-control ${inputClassName}`} />}
                buttonRender={hidden => (
                    <button className="btn btn-secondary" type="button">
                        {hidden ? <EyeFill /> : <EyeSlashFill />}
                    </button>
                )}
            />
        );
        return InputFormBase(
            MainContent,
            readMode,
            readMode && [...(value || '')].map(_char => '*').join('')
        );
    }

    //テキストのみを表示
    if (type === 'text') {
        const { value } = restProps;
        const MainContent = (
            <span className={`form-control-plaintext ${inputClassName}`}>{value}</span>
        );
        return InputFormBase(MainContent, readMode && (value || ''));
    }

    //編集ボタン付きテキストボックスを使用
    if (type === 'editable') {
        const {
            value,
            onChange,
            onStateChange = () => {},
            onConfirm = () => {},
            name,
            placeholder,
            disabled,
        } = restProps;
        const _onConfirm = (name, value) => onConfirm(name, value, label);
        const MainContent = (
            <EditableInputField
                {...{ name, value, onChange, onStateChange, onConfirm: _onConfirm, placeholder }}
                inputRender={() => (
                    <input className={`form-control ${inputClassName}`} placeholder={placeholder} />
                )}
                buttonRender={locked => (
                    <button
                        className={`btn btn${locked ? '-outline' : ''}-primary`}
                        type="button"
                        disabled={disabled}
                    >
                        {locked ? '編集' : '確定'}
                    </button>
                )}
            />
        );
        return InputFormBase(MainContent, readMode, readMode && (value || ''));
    }

    //セレクターを使用
    if (type === 'select') {
        const {
            name,
            value,
            onChange,
            options,
            disabled,
            reInitOnOptionChanged = true,
        } = restProps;

        const handleChange = e => {
            const ev = {
                target: {
                    name: e.target.name,
                    // return real type instead of string
                    value: options[e.target.selectedIndex].value,
                },
            };
            onChange(ev);
        };

        const MainContent = (
            <select
                className="custom-select"
                name={name}
                value={value}
                onChange={handleChange}
                disabled={disabled}
            >
                {options.map(({ value, label, disabled: optionDisabled }) => (
                    <option key={value} value={value} disabled={optionDisabled}>
                        {label}
                    </option>
                ))}
            </select>
        );
        useEffect(() => {
            if (Array.isArray(options) && options.length > 0 && reInitOnOptionChanged) {
                const e = {
                    target: {
                        name: name,
                        value: value || options[0].value,
                    },
                };
                onChange(e);
            }
        }, [options]);

        return InputFormBase(
            MainContent,
            readMode,
            readMode && (options.find(option => option.value === value)?.label || '-')
        );
    }

    //Optionsのラベルをテキストとして表示
    if (type === 'select-text') {
        const { value, options } = restProps;
        const MainContent = (
            <div className="wrap-anywhere">
                {options.find(option => option.value === value).label}
            </div>
        );
        return InputFormBase(
            MainContent,
            readMode,
            (readMode && options.find(option => option.value === value).label) || '-'
        );
    }

    //編集ボタン付きのセレクターを使用
    if (type === 'editable-select') {
        const { name, value, onChange, onStateChange, onConfirm, disabled, options } = restProps;
        const MainContent = (
            <EditableInputField
                {...{ name, value, onChange, onStateChange, onConfirm }}
                inputRender={() => (
                    <select className="custom-select" name={name} value={value} onChange={onChange}>
                        {options.map(({ value, label }) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                )}
                buttonRender={locked => (
                    <button
                        className={`btn btn${locked ? '-outline' : ''}-primary`}
                        type="button"
                        disabled={disabled}
                    >
                        {locked ? '編集' : '確定'}
                    </button>
                )}
            />
        );
        return InputFormBase(
            MainContent,
            readMode,
            readMode && (options.find(option => option.value === value).label || '-')
        );
    }

    //Toggleボタンを使用
    if (type === 'switch') {
        const { id, helper, name, value, onChange, disabled } = restProps;
        const handleClick = e => {
            if (disabled) return;
            //e.target.valueへのアクセスで、e.target.checkedの値を返すプロキシを生成
            const _e = new Proxy(e, {
                get: function(obj, prop, receiver) {
                    if (prop === 'target') {
                        return new Proxy(obj.target, {
                            get: function(obj, prop, ..._rest) {
                                if (prop === 'value') {
                                    return obj.checked;
                                } else {
                                    return obj[prop];
                                }
                            },
                        });
                    } else {
                        return Reflect.get(obj, prop, receiver);
                    }
                },
            });
            onChange(_e);
        };
        const MainContent = (
            <div className="custom-control custom-switch">
                <input
                    type="checkbox"
                    className="custom-control-input cursor-pointer"
                    name={name}
                    id={id ?? name}
                    value={value}
                    checked={value}
                    onChange={handleClick}
                    onClick={handleClick}
                    disabled={disabled}
                />
                <label
                    className={classNames('custom-control-label', disabled && 'disabled')}
                    htmlFor={id ?? name}
                    title="ボタンは無効化されています"
                >
                    {helper}
                </label>
            </div>
        );
        return InputFormBase(MainContent, readMode, readMode && (value ? 'ON' : 'OFF'));
    }

    // Checkboxes (multiple options)
    if (type === 'checkboxes') {
        const {
            name,
            value,
            onChange,
            options,
            disabled,
            sortedByChecked = false,
            withSearch = false,
            defaultGroupName,
        } = restProps;

        // search value
        const [searchValue, setSearchValue] = useState('');

        // Handle search input change
        const handleSearchChange = e => {
            setSearchValue(e.target.value);
        };

        // Sorted & Filtered Option List
        const sortedOptions = useMemo(() => {
            const filteredOptions = options.filter(
                item =>
                    !searchValue ||
                    item.label.toLowerCase().includes(searchValue.toLowerCase()) ||
                    (defaultGroupName &&
                        item.label.toLowerCase().includes(defaultGroupName.toLowerCase()))
            );

            if (!sortedByChecked) return filteredOptions;

            const checked = filteredOptions.filter(item => value.includes(item.value));
            const unchecked = filteredOptions.filter(item => !value.includes(item.value));
            return [...checked, ...unchecked];
        }, [options, value, sortedByChecked, searchValue]);

        const handleChanges = (i, e) => {
            const { name, checked } = e.target;
            const _value = sortedOptions[i].value;

            const newValue = checked ? [...value, _value] : value.filter(v => v !== _value);
            onChange({ target: { name, value: newValue } });
        };

        const MainContent = (
            <div>
                {withSearch && (
                    <SearchInputField
                        className="mb-2"
                        value={searchValue}
                        onChange={handleSearchChange}
                    />
                )}
                <ul className="list-group checkboxes">
                    {sortedOptions.map(({ value: optionValue, label, disabled: _disabled }, i) => (
                        <li
                            key={i}
                            className={classNames(
                                'list-group-item',
                                (disabled || _disabled) && 'disabled'
                            )}
                        >
                            <Checkbox
                                name={name}
                                label={label}
                                checked={value.includes(optionValue)}
                                onChange={e => handleChanges(i, e)}
                                readonly={disabled || _disabled}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        );
        return InputFormBase(
            MainContent,
            readMode,
            readMode &&
                (value || []).map(v => options.find(option => option.value === v).label).join(', ')
        );
    }

    //ユーザ定義オブジェクト（children props）を使用
    if (type === 'custom') {
        const { children, ...others } = restProps;
        //childrenがReact要素
        if (React.isValidElement(children)) {
            const customElm = React.cloneElement(children, others);
            return InputFormBase(customElm);
        }
        //chidrenが関数（render prop）
        return InputFormBase(children(others));
    }
}

InputForm.propTypes = {
    type: PropTypes.oneOf([
        'normal',
        'password',
        'text',
        'editable',
        'select',
        'editable-select',
        'switch',
        'checkboxes',
        'custom',
        'select-text',
    ]),
    label: PropTypes.node.isRequired,
    button: PropTypes.element,
    onChange: PropTypes.func,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onStateChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.exact({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
            label: PropTypes.string,
            disabled: PropTypes.bool,
        })
    ),
    proportion: PropTypes.arrayOf(PropTypes.number),
    helper: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.array]),
    ignoreForm: PropTypes.bool,
    inputClassName: PropTypes.string,
};
