import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Search from './icons/Search';

/**
 * Search Input
 * @param {object} props
 * `disabled` Disabled state。
 * `value` 入力フィールドに付与されるvalue属性。
 * `onChange` 入力フィールドに付与されるonChange属性。
 * @param {boolean} props.disabled
 * @param {string} props.value
 * @param {function(boolean)} props.onChange
 */

export default function SearchInputField(props) {
    const { disabled, value, placeholder, onChange, className } = props;

    return (
        <div className={classNames('search-input', className)}>
            <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                    <div className="input-group-text">
                        <Search />
                    </div>
                </div>
                <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder={placeholder || '検索'}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}

SearchInputField.propType = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
};
