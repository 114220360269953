import React from 'react';

export default function URLForm(props) {
    const { prefix, value, onChange, disabled, name, type, placeholder } = props;
    return (
        <div className="url-input-form">
            {type === 'text' ? (
                <div>
                    {prefix}
                    <span className="font-weight-bold">{value}</span>
                </div>
            ) : (
                <React.Fragment>
                    <div className="url-prefix">{prefix}</div>
                    <input
                        className="form-control"
                        {...{ value, onChange, disabled, name, placeholder }}
                    />
                </React.Fragment>
            )}
        </div>
    );
}
