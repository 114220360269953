import React, { createContext, useContext } from 'react';
import useAPIPostStateManager from 'hooks/useAPIRequestStateManager';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { usePathData } from 'dataProvider/pathData';
import Toast from './components/PostStateToast';
import { showOrUpdateToast } from 'utils/toastUtil';
import {validateOtpResponse} from 'utils/validateOtpResponse'

const PostContext = createContext({ post: () => {}, postStates: () => ({}) });

/**
 * Postリクエストを管理するコンポーネント。主な実装は`hooks/useAPIPostStateManager.js`で、このフックから提供されるpost関数とstates関数をグローバルに提供する役割を持つ。
 * また、リクエストの状態に応じてトーストを表示する役割も担う。
 *
 * @param {object} props
 */
export default function RequestStateProvider(props) {
    const [_request, requestStates] = useAPIPostStateManager();
    const { spaRoute } = usePathData();

    const request = method =>
        /**
         *
         * @param {...any} props 最後の引数以外は`useAPIPostStateManager`フックの引数として渡される。最後の引数はトーストに表示するメッセージ（`toastMessage`）を指定する
         * @param {string} toastMessage.loading Postリクエスト中にトーストに表示するメッセージ
         * @param {string} toastMessage.success Postリクエスト成功時にトーストに表示するメッセージ
         * @param {string} toastMessage.error Postリクエスト失敗時にトーストに表示するメッセージ
         *
         * @example
         * post("system.clients.create", "/system/clients", clientData, () => ({
         *name: clientData.name,
         *  route: clientData.route,
         *  admin: {
         *    email: clientData.email,
         *     phone_number: clientData.phone_number,
         *   },
         *   client_usages:[
         *     clientData.usernameTypeOptions === "onlyNickname" ? [1] : [],
         *    clientData.usernameTypeOptions === "onlyRealname" ? [2] : [],
         *     clientData.allowFileUpload ? [3] : []
         *   ].flat()
         * }), {
         *   loading: "企業の追加処理中です",
         *   success: "企業の追加処理を完了しました",
         *   error: "企業の追加処理に失敗しました",
         * })
         */
        async (...props) => {
            const toastMessage = props.slice(-1)[0];
            const postArgs = props.slice(0, -1);

            // トーストを生成
            let toastId;
            if (toastMessage && toastMessage.loading) {
                toastId = showOrUpdateToast(
                    toastId,
                    <Toast
                        message={toastMessage.loading}
                        showButton={true}
                        showSpinner={true}
                        link={spaRoute}
                        route={spaRoute}
                    />,
                    { closeButton: false, draggable: false, toastId: props[0] /* post key*/ }
                );
            } else {
                console.log('Silent action :', props[0]);
            }

            // useAPIPostStateManagerのRequest関数
            return _request(method)(...postArgs)
                .then(res => {
                    if (toastMessage && toastMessage.success) {
                        toastId = showOrUpdateToast(
                            toastId,
                            <Toast
                                message={toastMessage.success}
                                link={spaRoute}
                                route={spaRoute}
                                toastId={toastId}
                            />,
                            { autoClose: 5000, closeButton: true }
                        );
                    } else if (toastId) {
                        toast.dismiss(toastId);
                    }
                    return res;
                })
                .catch(err => {
                    if (
                        err.response.status === 401 &&
                        err.response.data?.message === 'reset-required'
                    ) {
                        const errorMessage = err.response.data?.errors?.message
                        console.log(errorMessage, '@errorMessage')
                        window.location.href = window.location.href.replace(
                            'login',
                            `password/send${errorMessage
                                ? `?message=${errorMessage}`
                                : ''}`
                        );
                    }else if(err.response.status === 401 && validateOtpResponse(err.response.data?.message).isValid) {
                        const validatedRes = validateOtpResponse(err.response.data?.message)
                        window.sessionStorage.setItem(`${validatedRes.section}.otp.token`, validatedRes.token)
                        window.location.href = window.location.href.replace(
                            'login',
                            'otp'
                        )
                    }else if (toastMessage && toastMessage.error) {
                        toastId = showOrUpdateToast(
                            toastId,
                            <Toast
                                message={toastMessage.error}
                                showButton={true}
                                link={spaRoute}
                                route={spaRoute}
                                closeInTargetPage
                            />,
                            { className: 'toast-error', closeButton: true }
                        );
                    } else if (toastId) {
                        toast.dismiss(toastId);
                    }
                    throw err;
                });
        };

    return (
        <React.Fragment>
            <PostContext.Provider
                value={{
                    get: request('get'),
                    post: request('post'),
                    put: request('put'),
                    deleteCall: request('delete'),
                    states: requestStates,
                }}
                {...props}
            />
            <ToastContainer
                position="bottom-right"
                autoClose={false}
                hideProgressBar
                transition={Slide}
                newestOnTop
                closeOnClick={false}
            />
        </React.Fragment>
    );
}

/**
 * PostStateProviderで提供されたpost関数と、postStates関数を得るカスタムフック
 */
export const useAPIRequest = () => {
    const { get, post, put, deleteCall, states } = useContext(PostContext);
    return { get, post, put, deleteCall, states };
};
