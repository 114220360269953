import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * ローディング中のサークルを表示するコンポーネント
 * @param {object} props
 * @param {boolean} props.isLoading
 * @param {JSX.Element?} props.children
 * @param {string?} props.className
 *
 * + `isLoading`: ローディング中か否か
 * + `children`: ローディング中ではない場合に表示するコンポーネント。デフォルトでは`null`
 */

export default function LoadingSpinner(props) {
    const { isLoading, children = null, className } = props;

    return (
        <React.Fragment>
            {isLoading ? (
                <div className={classNames('loading-circle', className)}>
                    <div className={'spinner-border text-primary'} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                children
            )}
        </React.Fragment>
    );
}

LoadingSpinner.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
};
