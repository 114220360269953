import React from 'react';

import NotificationButton from './components/NotificationButton';
import HeaderHeadline from './components/HeaderHeadline';
import LoginButton from './components/LoginButton';
import { getHeaderInfo, NOTIFY_DATA } from './headerData';
import { usePathData } from 'dataProvider/pathData';
import LogoWhite from 'components/icons/LogoWhite';
import SwitchAccountButton from './components/SwitchAccountButton';
import { authTypeConstants } from 'constants/auth';

export default function Header(props) {
    const { auth, className, showLogin, showNotification, showSwitchButton } = props;
    const { userType, spaRoute, base } = usePathData();

    const { title, Icon } = getHeaderInfo(spaRoute) || { title: '', Icon: null };

    // only for authenticated & registered user
    if (auth) {
        return (
            <div id="header-auth" className={className}>
                {/* Header headline */}
                <HeaderHeadline label={title} icon={Icon && <Icon className="icon-white" />} />

                {/* Policy */}
                {userType == authTypeConstants.USER && (
                    <a href={`${base}/contents`} className="btn btn-link text-white">
                        運用方針
                    </a>
                )}

                {/* Notification */}
                {showNotification !== false && userType != authTypeConstants.SYSTEM && (
                    <NotificationButton messages={NOTIFY_DATA} />
                )}

                {/* Switch account */}
                {showSwitchButton && <SwitchAccountButton />}
            </div>
        );
    } else {
        return (
            <div id="header-unauth">
                <div className="logo-link-wrapper">
                    <LogoWhite className="postech-logo" />
                </div>
                {/* show login button if user is not authenticated yet */}
                {showLogin !== false && spaRoute !== '/login' && (
                    <LoginButton className="login-button" />
                )}
            </div>
        );
    }
}
