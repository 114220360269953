import * as React from 'react';
import classNames from 'classnames';

export default function Trash(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            {...others}
        >
            <path
                d="M6.125 6.125L7.21875 23.625C7.2707 24.6362 8.00625 25.375 8.96875 25.375H19.0312C19.9976 25.375 20.7194 24.6362 20.7812 23.625L21.875 6.125"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.375 6.125H23.625"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M10.5 6.125V3.93751C10.4995 3.765 10.5331 3.5941 10.5989 3.43464C10.6647 3.27517 10.7613 3.13028 10.8833 3.0083C11.0053 2.88632 11.1502 2.78966 11.3096 2.72388C11.4691 2.6581 11.64 2.6245 11.8125 2.62501H16.1875C16.36 2.6245 16.5309 2.6581 16.6904 2.72388C16.8498 2.78966 16.9947 2.88632 17.1167 3.0083C17.2387 3.13028 17.3353 3.27517 17.4011 3.43464C17.4669 3.5941 17.5005 3.765 17.5 3.93751V6.125"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14 9.625V21.875"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.0625 9.625L10.5 21.875"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.9375 9.625L17.5 21.875"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
