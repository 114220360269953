import * as React from 'react';
import classNames from 'classnames';

export default function Posts(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            viewBox="0 0 24 24"
            fill="none"
            width="1em"
            height="1em"
            {...others}
        >
            <path d="M4.095 20.055L7.266 18.9975L7.821 19.275C9.096 19.911 10.5135 20.25 12 20.25C16.965 20.25 21 16.341 21 12C21 7.659 16.965 3.75 12 3.75C7.035 3.75 3 7.659 3 12C3 13.62 3.585 15.264 4.6605 16.6695L5.178 17.3445L4.0935 20.0535L4.095 20.055ZM3.0345 21.99C2.89876 22.0355 2.75284 22.0412 2.61397 22.0064C2.47511 21.9716 2.3491 21.8978 2.25084 21.7937C2.15257 21.6896 2.08615 21.5595 2.05942 21.4189C2.03269 21.2782 2.04677 21.1329 2.1 21L3.468 17.58C2.22 15.945 1.5 13.989 1.5 12C1.5 7.029 6 2.25 12 2.25C18 2.25 22.5 7.029 22.5 12C22.5 16.971 18 21.75 12 21.75C10.3173 21.7547 8.65665 21.3664 7.1505 20.616L3.0345 21.9885V21.99Z" />
            <path d="M11.9998 13.2008C11.8422 13.2008 11.6862 13.1697 11.5406 13.1094C11.395 13.0491 11.2627 12.9607 11.1513 12.8493C11.0398 12.7379 10.9515 12.6056 10.8911 12.46C10.8308 12.3144 10.7998 12.1584 10.7998 12.0008C10.7998 11.8432 10.8308 11.6872 10.8911 11.5416C10.9515 11.396 11.0398 11.2637 11.1513 11.1523C11.2627 11.0408 11.395 10.9524 11.5406 10.8921C11.6862 10.8318 11.8422 10.8008 11.9998 10.8008C12.3181 10.8008 12.6233 10.9272 12.8483 11.1523C13.0734 11.3773 13.1998 11.6825 13.1998 12.0008C13.1998 12.319 13.0734 12.6243 12.8483 12.8493C12.6233 13.0744 12.3181 13.2008 11.9998 13.2008ZM16.4998 13.2008C16.3422 13.2008 16.1862 13.1697 16.0406 13.1094C15.895 13.0491 15.7627 12.9607 15.6513 12.8493C15.5398 12.7379 15.4515 12.6056 15.3911 12.46C15.3308 12.3144 15.2998 12.1584 15.2998 12.0008C15.2998 11.8432 15.3308 11.6872 15.3911 11.5416C15.4515 11.396 15.5398 11.2637 15.6513 11.1523C15.7627 11.0408 15.895 10.9524 16.0406 10.8921C16.1862 10.8318 16.3422 10.8008 16.4998 10.8008C16.8181 10.8008 17.1233 10.9272 17.3483 11.1523C17.5734 11.3773 17.6998 11.6825 17.6998 12.0008C17.6998 12.319 17.5734 12.6243 17.3483 12.8493C17.1233 13.0744 16.8181 13.2008 16.4998 13.2008ZM7.4998 13.2008C7.34222 13.2008 7.18618 13.1697 7.04059 13.1094C6.89499 13.0491 6.76271 12.9607 6.65128 12.8493C6.53985 12.7379 6.45145 12.6056 6.39115 12.46C6.33084 12.3144 6.2998 12.1584 6.2998 12.0008C6.2998 11.8432 6.33084 11.6872 6.39115 11.5416C6.45145 11.396 6.53985 11.2637 6.65128 11.1523C6.76271 11.0408 6.89499 10.9524 7.04059 10.8921C7.18618 10.8318 7.34222 10.8008 7.4998 10.8008C7.81806 10.8008 8.12329 10.9272 8.34833 11.1523C8.57338 11.3773 8.69981 11.6825 8.69981 12.0008C8.69981 12.319 8.57338 12.6243 8.34833 12.8493C8.12329 13.0744 7.81806 13.2008 7.4998 13.2008Z" />
        </svg>
    );
}
