import React, { createContext, useRef, useEffect, useMemo } from 'react';
import { useGlobalData } from './globalData';
import { useAPIUserRefreshToken } from 'repo/user/authRepo';
import { useAPIUserRefreshToken as useAPIAdminRefreshToken } from 'repo/admin/authRepo';
import { useAPIUserRefreshToken as useAPISystemRefreshToken } from 'repo/system/authRepo';
import { useCookies } from 'react-cookie';
import { getJSONItem } from 'utils/manageLocalStorage';
import { authTypeConstants } from 'constants/auth';
import { usePathData } from './pathData';

// Auth Context
export const AuthContext = createContext([{}, () => {}]);

export const buildAuthData = (
    authData = {
        authType: null,
        token: null,
        tokenExpiresIn: null,
        user: null,
        isAuthenticated: null,
    }
) => ({
    authType: authData.authType ?? null,
    token: authData.token ?? null,
    tokenExpiresIn: authData.tokenExpiresIn ?? null,
    user: authData.user ?? null,
    isAuthenticated: authData.isAuthenticated ?? null,
});

// useAuth Hook
export const useAuth = () => {
    const [{ auth: _auth }, updateGlobalData] = useGlobalData();
    const appPath = usePathData();
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    // auth based on app path
    const auth = useMemo(() => {
        return _auth && _auth.authType !== appPath.userType ? buildAuthData() : _auth;
    }, [_auth, appPath]);

    // LOGOUT
    const logout = () => {
        // Clear Auth Data
        updateGlobalData('auth', buildAuthData());
        removeCookie('token');
    };

    // Set Auth Data
    const setAuth = arg => {
        const { authType, token, tokenExpiresIn, user, isAuthenticated: _isAuthenticated } =
            arg ?? {};

        // set true if token & user not null
        const isAuthenticated = _isAuthenticated ?? (token != null && user != null);

        const authData = buildAuthData({ authType, token, tokenExpiresIn, user, isAuthenticated });

        setCookie('token', token);

        updateGlobalData('auth', authData);
    };

    // Update partial attributes
    const updateAuth = obj => {
        const newAuth = Object.assign(auth, obj);
        setAuth(newAuth);
    };

    // Update partial attributes of user
    const updateAuthUser = obj => {
        setAuth({ ...auth, user: { ...auth.user, ...obj } });
    };

    // Get Token from Local Storage
    // & Resync Auth Data with Cookie if needed
    const getToken = () => {
        if (cookies.token != auth.token) {
            // resync from storage
            const localAuth = getJSONItem('auth');
            setAuth(localAuth);
        }
        return auth.token;
    };

    // Handle 401 event (unauthorized)
    const handle401 = () => {
        if (auth && auth.authType !== appPath.userType) {
            // TODO: do something?
        } else {
            logout();
        }
    };

    return { logout, auth, setAuth, updateAuth, updateAuthUser, getToken, handle401 };
};

/**
 * AuthProvider React Component
 *
 * @param {any} props
 * @return JSX
 */
export function AuthProvider(props) {
    const { auth } = useAuth();
    const [refreshUserToken] = useAPIUserRefreshToken();
    const [refreshAdminToken] = useAPIAdminRefreshToken();
    const [refreshSystemToken] = useAPISystemRefreshToken();

    // Refresh token function
    const refreshRef = useRef(() => {});
    refreshRef.current = () => {
        if (auth.authType == authTypeConstants.USER) {
            refreshUserToken();
        } else if (auth.authType == authTypeConstants.ADMIN) {
            refreshAdminToken();
        } else if (auth.authType == authTypeConstants.SYSTEM) {
            refreshSystemToken();
        }
    };

    // check expire
    useEffect(() => {
        if (auth.tokenExpiresIn) {
            const now = new Date().getTime();
            if (now < auth.tokenExpiresIn) {
                // schedule at 1 minute ahead (60 seconds)
                const nextRefreshAt = auth.tokenExpiresIn - now - 60000;
                setTimeout(() => {
                    refreshRef.current();
                }, Math.max(nextRefreshAt, 0));
            }
        }
    }, [auth.tokenExpiresIn]);

    return <AuthContext.Provider {...props} />;
}
