import React from 'react';
import AlertModal from 'components/AlertModal';

/**
 * 有効化・無効化を行うボタン
 * @param {object} props
 * @param {Function} props.onClick
 * @param {boolean} props.disabled
 * @param {object} props.ui
 * @param {string} props.ui.targetName
 */
export default function StatusChangeButton(props) {
    const {
        isCurrentValid,
        onClick,
        ui: { targetName },
        disabled,
    } = props;
    return (
        <AlertModal
            modal={{
                body: (
                    <p>
                        {targetName}を
                        <span className="danger">{isCurrentValid ? '無効' : '有効'}</span>
                        にします。よろしいですか？
                    </p>
                ),
                positiveLabel: isCurrentValid ? '無効にする' : '有効にする',
                negativeLabel: 'キャンセル',
                type: isCurrentValid ? 'danger' : 'primary',
            }}
            onConfirm={onClick}
        >
            <button
                className={`btn btn-outline-${isCurrentValid ? 'danger' : 'primary'} mx-2`}
                disabled={disabled}
            >
                アカウントを{isCurrentValid ? '無効' : '有効'}にする
            </button>
        </AlertModal>
    );
}
