import React from 'react';
import IconButton from 'components/IconButton';
import NotificationButton from './components/NotificationButton';
import MenuIcon from 'components/icons/Menu';
import { NOTIFY_DATA } from './headerData';
import { usePathData } from 'dataProvider/pathData';
import Logo from 'components/icons/Logo';

export default function HeaderMobile(props) {
    const { auth, className, toggleSidebar, showNotification } = props;
    const { userType } = usePathData();

    return (
        <div id="header-mobile" className={className}>
            <div className="logo-link-wrapper">
                <Logo className="postech-logo" />
            </div>

            {auth && (
                <div className="header-actions">
                    {showNotification !== false && userType != 'system' && (
                        <NotificationButton messages={NOTIFY_DATA} />
                    )}

                    <IconButton onClick={toggleSidebar}>
                        <MenuIcon />
                    </IconButton>
                </div>
            )}
        </div>
    );
}
