export const validateOtpResponse = (input) => {
    const prefix = "otp-required:";

    if (input.startsWith(prefix)) {
        const inputSplit  = input.split(":")
        if(inputSplit.length === 3) {
            return {isValid: true, section: inputSplit[1], token: inputSplit[2]}
        }
    }

    return { isValid: false, token: null, section: null };
}

