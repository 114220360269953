import React, { useEffect } from 'react';
import classNames from 'classnames';
import Page from 'templates/Page';
import Form from 'components/Form';
import InputForm from 'components/InputForm';
import AlertModal from 'components/AlertModal';
import BackButton from 'components/BackButton';
import LoadingCircle from 'components/LoadingCircle';
import URLForm from '../components/URLForm';
import { useAPIRequest } from 'dataProvider/postStateData/requestStateData';
import TextareaAutoSize from 'react-textarea-autosize';
import { useClientsForm, usernameTypeOptions } from '../form/useClientsForm';
import { EMAIL_WORD_COUNT, SMS_WORD_COUNT } from '../form/useClientsForm';
import { CREATE_FORM_DEFAULT_VALUES, CREATE_FORM_VALIDATION } from '../form/constants';
import { convertFormIntoPayload } from '../form/utils';
import { eFormMode, eLimitedTextName } from '../form/enums';
import { useHistory } from 'react-router-dom';

const ROUTE_PREFIX = process.env.MIX_CLIENT_URL_ORIGIN_DISPLAY;

// PostKey
const requestKey = 'system.clients.create';

export default function ClientsCreate() {
    const history = useHistory();
    const { post, states } = useAPIRequest();
    const { formData, handleChangeData, planOptions, handleLimitedTextInput } = useClientsForm(
        CREATE_FORM_DEFAULT_VALUES,
        eFormMode.create
    );

    // Post Data
    const handleConfirm = () => {
        post(
            requestKey,
            '/clients',
            formData,
            form => convertFormIntoPayload(form, eFormMode.create),
            {
                loading: '企業の追加処理中です',
                success: '企業の追加処理が完了しました',
                error: '企業の追加処理に失敗しました',
            }
        );
    };

    useEffect(() => {
        if (states(requestKey).success) {
            history.replace('/clients');
        }
    }, [states(requestKey).state]);

    // View
    return (
        <Page className="page client-create-page page-narrow-1">
            <h1 className="page-title">企業追加</h1>
            <div className="card styled-card my-4">
                <Form
                    className="form-m-5"
                    value={formData}
                    onChange={handleChangeData}
                    disabled={states(requestKey).loading}
                >
                    <h1 className="header">基本情報</h1>
                    <InputForm label="企業名" name="name" placeholder="みんばこ" />
                    <InputForm type="custom" label="URL" name="route" placeholder="minbako">
                        <URLForm prefix={ROUTE_PREFIX} />
                    </InputForm>
                    <InputForm
                        label="管理者メールアドレス"
                        name="email"
                        placeholder="admin@sample.com"
                    />
                    <InputForm
                        label="管理者電話番号"
                        name="phone_number"
                        placeholder="0312345678"
                        disabled={formData.disableInvitationByPhone}
                    />
                    <div className="card-divider" />
                    <h1 className="header">設定</h1>
                    <InputForm
                        type="select"
                        label="Setting Type"
                        name="plan_id"
                        key="plan_id"
                        options={planOptions}
                    />
                    <InputForm
                        id="switch-allow-file-upload"
                        type="switch"
                        label="ファイルアップロードを許可する"
                        name="allowFileUpload"
                    />
                    <InputForm
                        type="select"
                        label="ユーザー名の制限"
                        name="usernameType"
                        options={usernameTypeOptions}
                    />
                    <InputForm
                        type="switch"
                        label="カテゴリに内部通報を含めない"
                        name="notIncludeReportRole"
                    />
                    <InputForm
                        type="switch"
                        label="CSVファイルのダウンロードを無効にする"
                        name="disableCSVDownload"
                    />
                    <InputForm
                        type="switch"
                        label="IPアドレス制限を無効にする"
                        name="disableAccessRestriction"
                    />
                    <InputForm
                        type="switch"
                        label="権限管理を無効にする"
                        name="disableRoleManagement"
                    />
                    <InputForm
                        type="switch"
                        label="返信の修正機能を無効にする"
                        name="disableReplyModification"
                    />
                    <InputForm
                        type="switch"
                        label="管理者の追加を無効にする"
                        name="disableAddAdmin"
                    />
                    <InputForm
                        type="switch"
                        label="グルーピング機能を無効にする"
                        name="disableUserGroup"
                    />
                    <InputForm
                        type="switch"
                        label="投稿者のグループ表示を無効にする"
                        name="disableGroupFilter"
                    />
                    <InputForm
                        type="switch"
                        label="電話番号での招待を無効にする"
                        name="disableInvitationByPhone"
                        key="disableInvitationByPhone"
                    />
                    <InputForm
                        type="switch"
                        label="クローズ機能を無効にする"
                        name="disablePostClose"
                        key="disablePostClose"
                    />
                    <InputForm
                        type="switch"
                        label="アナウンス機能を無効にする"
                        name="disableAnouncementFunction"
                        key="disableAnouncementFunction"
                    />
                    <InputForm
                        type="switch"
                        label="ユーザーのURL招待を無効にする"
                        name="disableUserInvitationUrlFunction"
                        key="disableUserInvitationUrlFunction"
                    />
                    <InputForm
                        type="switch"
                        label="セキュリティポリシーを無効にする"
                        name="disableSecurityPolicy"
                        key="disableSecurityPolicy"
                    />
                    <InputForm
                        type="switch"
                        label="外部投稿を無効にする"
                        name="disableExternalPost"
                        key="disableExternalPost"
                    />
                    <InputForm
                        type="custom"
                        label="カテゴリ数の制限"
                        name="categories_threshold"
                        key="limitCategoryCount"
                    >
                        {props => (
                            <input {...props} type="number" className="form-control" min={-1} />
                        )}
                    </InputForm>
                    <InputForm
                        type="custom"
                        label="ユーザーの招待数の上限"
                        name="users_threshold"
                        key="limitUserCount"
                    >
                        {props => (
                            <input {...props} type="number" className="form-control" min={-1} />
                        )}
                    </InputForm>
                    <div className="card-divider" />
                    <h1 className="header">Admin テンプレート</h1>
                    <div>
                        <h3 className="h6 mb-2">メール用テンプレート</h3>
                        <div className="input-area">
                            <TextareaAutoSize
                                name="admin_email_template"
                                className="form-control styled-input-white contents-textarea"
                                value={formData.admin_email_template}
                                onChange={handleLimitedTextInput(eLimitedTextName.email)}
                                disabled={states(requestKey).loading}
                                minRows={3}
                            />
                            <div
                                className={classNames(
                                    'counter text-right',
                                    formData.admin_email_template.length > EMAIL_WORD_COUNT &&
                                    'danger'
                                )}
                            >
                                {formData.admin_email_template.length}/{EMAIL_WORD_COUNT}文字
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3 className="h6 mb-2">SMS用テンプレート</h3>
                        <div className="input-area">
                            <TextareaAutoSize
                                name="admin_sms_template"
                                className="form-control styled-input-white contents-textarea"
                                value={formData.admin_sms_template}
                                onChange={handleLimitedTextInput(eLimitedTextName.sms)}
                                disabled={states(requestKey).loading}
                                minRows={3}
                            />
                            <div
                                className={classNames(
                                    'counter text-right',
                                    formData.admin_sms_template.length > SMS_WORD_COUNT && 'danger'
                                )}
                            >
                                {formData.admin_sms_template.length}/{SMS_WORD_COUNT}文字
                            </div>
                        </div>
                    </div>
                    <div className="card-divider" />
                    <h1 className="header">User テンプレート</h1>
                    <div>
                        <h3 className="h6 mb-2">メール用テンプレート</h3>
                        <div className="input-area">
                            <TextareaAutoSize
                                name="user_email_template"
                                className="form-control styled-input-white contents-textarea"
                                value={formData.user_email_template}
                                onChange={handleLimitedTextInput(eLimitedTextName.email)}
                                disabled={states(requestKey).loading}
                                minRows={3}
                            />
                            <div
                                className={classNames(
                                    'counter text-right',
                                    formData.user_email_template.length > EMAIL_WORD_COUNT &&
                                    'danger'
                                )}
                            >
                                {formData.user_email_template.length}/{EMAIL_WORD_COUNT}文字
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3 className="h6 mb-2">SMS用テンプレート</h3>
                        <div className="input-area">
                            <TextareaAutoSize
                                name="user_sms_template"
                                className="form-control styled-input-white contents-textarea"
                                value={formData.user_sms_template}
                                onChange={handleLimitedTextInput(eLimitedTextName.sms)}
                                disabled={states(requestKey).loading}
                                minRows={3}
                            />
                            <div
                                className={classNames(
                                    'counter text-right',
                                    formData.user_sms_template.length > SMS_WORD_COUNT && 'danger'
                                )}
                            >
                                {formData.user_sms_template.length}/{SMS_WORD_COUNT}文字
                            </div>
                        </div>
                    </div>
                    <LoadingCircle isLoading={states(requestKey).loading} />
                    {states(requestKey).error && (
                        <pre className="error-message text-center">
                            {states(requestKey).errorMessage}
                        </pre>
                    )}
                    <AlertModal
                        className="text-center mt-4"
                        modal={{
                            body: '企業を追加します。よろしいですか？',
                            positiveLabel: 'はい',
                            negativeLabel: 'いいえ',
                        }}
                        onConfirm={handleConfirm}
                    >
                        <button
                            className="btn btn-secondary btn-stress btn-lg px-5"
                            disabled={
                                states(requestKey).loading || !CREATE_FORM_VALIDATION(formData)
                            }
                        >
                            {states(requestKey).loading ? '処理中' : '追加する'}
                        </button>
                    </AlertModal>
                </Form>
            </div>
            <BackButton />
        </Page>
    );
}
