import React from 'react';
import PropTypes from 'prop-types';
import useDataState from 'hooks/useDataState';
import { createContext, useContext } from 'react';

/**
 * ContextAPIを便利に使うラッパーです。複数コンポーネント内で共通のStateを用いる場合に使います。グローバルなデータを想定しています。
 * 単にデータを子孫コンポーネントへ渡すだけでなく、子孫コンポーネントからの値の変更も行いたい場合に使います。
 *
 * + `GlobalProvider`: 子孫要素に加え、親要素からも値を参照できます。値と更新関数（setState）を共にプロパティとして渡します。
 * + `GlobalProviderBase`: 子孫要素でのみ値を参照できます。親コンポーネントでは値を読み書きすることはできません。
 *
 * @exapmle ContextProviderの設定。（GlobalProvider）
 *
 * function ParentComponent(){
 *  const [state, setState] = useState({ userToken: null })
 *  return (
 *    <GlobalProvider value={state} setValue={setState}>
 *      <ChildrenComponent/>
 *    </GlobalProvider>
 *  )
 * }
 *
 * @exapmle ContextProviderの設定。（GlobalProviderBase））
 *
 * function ParentComponent(){
 *  return (
 *    <GlobalProviderBase init={{userToken: "abc"}}>
 *      <ChildrenComponent/>
 *    </GlobalProviderBase>
 *  )
 * }
 *
 * @example 値を利用する。更新する。（useGlobalData）
 *
 * import { useGlobalData } from 'dataProvider/globalData'
 *
 * //Reactコンポーネント内で。
 * const [data, updateData] = useGlobalData()
 * //グローバルデータの参照
 * console.log(data.token)
 * //グローバルデータの更新（第一引数にkey、第二引数にvalue）
 * updateData("token", "token0123456789abc")
 *
 */

const GlobalContext = createContext([{}, () => {}]);
const GlobalProviderRow = GlobalContext.Provider;

const useGlobalData = () => {
    const [contextData, updateContextData] = useContext(GlobalContext);
    const updateData = (key, value) => {
        updateContextData(key, value);
    };
    return [contextData, updateData];
};

/**
 * @param {object} props
 * + `init` 初期データ
 * + `storeAtLocalStorage` ローカルストレージに保存するキーを配列で指定する。ここに指定したキーは、データが書き換えられるたびにlocalStorageに同名のキーで保存される。
 *    また、初回マウント時にローカルストレージにそのキーに対応する値が存在していれば、その値でinitの値を上書きする
 * @param {object} props.init
 */
const GlobalProviderBase = props => {
    const { init = {}, storeAtLocalStorage, ...others } = props;
    const [value, updateValue] = useDataState(init, storeAtLocalStorage);

    return <GlobalProviderRow value={[value, updateValue]} {...others} />;
};

GlobalProviderBase.propTypes = {
    init: PropTypes.object,
};

/**
 * @param {object} props
 * + `value` useState()[0]。値
 * + `setValue` useState()[1]。値の更新関数
 * @param {any} props.value
 * @param {function} props.setValue
 */
const GlobalProvider = props => {
    const { value, setValue, ...others } = props;
    const updateValue = (newKey, newValue) => setValue({ ...value, [newKey]: newValue });

    return <GlobalProviderRow value={[value, updateValue]} {...others} />;
};

GlobalProvider.propTypes = {
    value: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
};

export { GlobalProviderBase, GlobalProvider, useGlobalData };
