import React, { useMemo } from 'react';
import classNames from 'classnames';
import IconButton from './IconButton';
import ArrowLeft from './icons/ArrowLeft';
import ArrowRight from './icons/ArrowRight';

/**
 * ページ送りボタンを実装するコンポーネント
 * @param {object} props
 * + `onClickPage` ページ番号をクリックした場合に呼ばれる関数
 * + `currentPage` 現在のページ番号
 * + `totalPage` 全体のページ数
 * @param {(id: int) => (e: event) => any} props.onClickPage
 * @param {int} props.currentPage
 * @param {int} props.totalPage
 */

export default function Pagination(props) {
    const { onClickPage, currentPage, totalPage } = props;

    const pageItems = useMemo(() => {
        let items = [];
        if (currentPage <= 4) {
            // first 3 pages
            for (let i = 1; i <= Math.min(7, totalPage); i++) {
                items.push(i);
            }
        } else if (currentPage >= totalPage - 2) {
            // last 3 pages
            for (let i = Math.max(totalPage - 4, 1); i <= totalPage; i++) {
                items.push(i);
            }
        } else {
            for (
                let i = Math.max(currentPage - 2, 1);
                i <= Math.min(currentPage + 2, totalPage);
                i++
            ) {
                items.push(i);
            }
        }

        if (items[0] > 1) {
            if (items[0] > 2) {
                // add `...` to navigation
                items = ['...', ...items];
            }
            items = [1, ...items];
        }

        const lastItem = items[items.length - 1];
        if (lastItem !== totalPage) {
            if (lastItem < totalPage - 1) {
                // add `...` to navigation
                items.push('...');
            }
            items.push(totalPage);
        }
        return items;
    }, [totalPage, currentPage]);

    return (
        <nav className="pagination-buttons">
            <IconButton
                className={'left'}
                onClick={currentPage > 1 ? onClickPage(currentPage - 1) : undefined}
                disabled={totalPage == null || currentPage === 1}
            >
                <ArrowLeft />
            </IconButton>
            {pageItems.map((page, i) => (
                <IconButton
                    key={i}
                    className={classNames(
                        page === currentPage ? 'selected' : '',
                        page == '...' ? 'notlink' : ''
                    )}
                    onClick={page != '...' ? onClickPage(page) : undefined}
                >
                    <span>{page}</span>
                </IconButton>
            ))}
            <IconButton
                className={'right'}
                onClick={currentPage < totalPage ? onClickPage(currentPage + 1) : undefined}
                disabled={totalPage == null || currentPage === totalPage}
            >
                <ArrowRight />
            </IconButton>
        </nav>
    );
}
