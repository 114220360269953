import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar';
import { useGlobalData } from 'dataProvider/globalData';
import { useAuth } from 'dataProvider/authData';
import 'react-toastify/dist/ReactToastify.css';
import HeaderMobile from 'components/Header/HeaderMobile';

/**
 * ヘッダとサイドバーを提供
 */

export default function MainLayout(props) {
    const { children } = props;
    const [{ screen }] = useGlobalData();

    const {
        auth: { isAuthenticated, user },
    } = useAuth();

    const isUserRegistered = useMemo(() => {
        return isAuthenticated && !user.is_new;
    }, [isAuthenticated, user]);

    const [hideSidebar, setHideSidebar] = useState(false);

    const handleSidebarStateToggle = () => {
        setHideSidebar(hide => !hide);
    };

    const handleClose = () => setHideSidebar(true);
    const onClickSidebarLink = () => {
        if (screen === 'sp') setHideSidebar(true);
    };

    useEffect(() => {
        if (screen === 'pc') {
            setHideSidebar(false);
        } else {
            setHideSidebar(true);
        }
    }, [screen]);

    return (
        <React.Fragment>
            {// for authenticated user & not an unregistered user
            isUserRegistered && (
                <Sidebar
                    hide={hideSidebar}
                    onClickLink={onClickSidebarLink}
                    onClickOverlay={handleClose}
                />
            )}
            <div className={classNames('main', !isUserRegistered || hideSidebar ? 'hide' : 'show')}>
                {screen !== 'sp' ? (
                    <Header
                        auth={isUserRegistered}
                        showNotification={isUserRegistered}
                        showLogin={!isAuthenticated}
                        showSwitchButton={user?.is_linked}
                        toggleSidebar={handleSidebarStateToggle}
                    />
                ) : (
                    <HeaderMobile
                        auth={isUserRegistered}
                        showNotification={isUserRegistered}
                        showLogin={!isAuthenticated}
                        toggleSidebar={handleSidebarStateToggle}
                    />
                )}
                {children}
            </div>
        </React.Fragment>
    );
}
