import React from 'react';
import PropTypes from 'prop-types';

/**
 * アイコンボタンに表示するバッジ
 * @param {*} props
 * + `number` 通知数。
 * @param {number} props.number
 */

export default function Badge(props) {
    const { number } = props;

    return (
        <div className={number === 0 ? 'empty-notify-badge' : 'notify-badge'}>
            {number >= 100 ? '+' : number}
        </div>
    );
}

Badge.propTypes = {
    number: PropTypes.number,
};
