import React, { useEffect, useState } from 'react';
import BaseFrame from 'templates/PageFrame';
import LogoutPageLayout from 'templates/LogoutLayout/LogoutLayout';
import { useAuth } from 'dataProvider/authData';
import useTransition from 'hooks/useTransition';

export default function LogoutPage() {
    const { logout, auth } = useAuth();
    const [wasAuthenticated] = useState(auth.isAuthenticated);
    const { pushHistorySibling } = useTransition();

    useEffect(() => {
        if (wasAuthenticated) {
            logout();
        } else {
            pushHistorySibling('/login');
        }
    }, []);

    return (
        <BaseFrame title="Logout">
            <LogoutPageLayout />
        </BaseFrame>
    );
}
