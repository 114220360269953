import React from 'react';
import AlertModal from 'components/AlertModal';

/**
 * 削除ボタン
 * @param {object} props
 * @param {Function} props.onClick
 * @param {boolean} props.disabled
 * @param {object} props.ui
 * @param {string} props.ui.targetName
 */
export default function DeleteButton(props) {
    const {
        onClick,
        disabled,
        ui: { targetName },
    } = props;

    return (
        <AlertModal
            modal={{
                body: `${targetName}を削除します。よろしいですか？`,
                positiveLabel: '削除する',
                negativeLabel: 'キャンセル',
                type: 'danger',
            }}
            onConfirm={onClick}
        >
            <button className={`btn btn-outline-danger mx-2`} disabled={disabled}>
                {targetName}を削除する
            </button>
        </AlertModal>
    );
}
