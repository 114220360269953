import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';

/**
 * postechのsvg画像を用いたチェックボックスコンポーネント。
 * @param {object} props
 * @param {import('react').RefObject} [props.inputRef]
 * @param {import('react').Attributes} [props.inputProps]
 * @param {string} [props.label]
 * @param {string} [props.name]
 * @param {any} [props.value]
 * @param {Function} [props.onChange]
 *
 * @example
 * const inputRef = useRef()
 * <StyledCheckbox
 *   inputRef={inputRef}
 *   label="選択肢1"
 * />
 */

export default function StyledCheckbox(props) {
    const { value, onChange, name, inputRef, inputProps = null, label } = props;
    const checked = inputProps != null ? inputProps.checked : value;

    const defaultRef = useRef();
    const _inputRef = inputRef || defaultRef;

    const handleLabelClicked = e => {
        // check if the label is not an anchor tag
        if (e.target.tagName !== 'A') {
            if (_inputRef.current) {
                _inputRef.current.click();
            }
        }
    };

    return (
        <label className="custom-checkbox styled-checkbox">
            <Checkbox
                name={name}
                checked={checked}
                onChange={onChange}
                ref={_inputRef}
                {...inputProps}
            />
            <div className="checkbox-label" onClick={handleLabelClicked}>
                {label}
            </div>
        </label>
    );
}

StyledCheckbox.propTypes = {
    inputRef: PropTypes.exact({ current: PropTypes.any }),
    inputProps: PropTypes.object,
    label: PropTypes.node,
};
