import * as React from 'react';
import classNames from 'classnames';

export default function LogOut(props) {
    const { className, ...others } = props;
    return (
        <svg
            className={classNames('icon', className)}
            viewBox="0 0 24 24"
            width="24"
            height="24"
            {...others}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.0001 12C13.0001 12.553 12.5521 13 12.0001 13C11.4481 13 11.0001 12.553 11.0001 12V2C11.0001 1.447 11.4481 1 12.0001 1C12.5521 1 13.0001 1.447 13.0001 2V12ZM15.239 3.5413C15.492 3.0503 16.096 2.8593 16.586 3.1113C19.925 4.8383 22 8.2433 22 12.0003C22 17.5133 17.514 22.0003 12 22.0003C6.486 22.0003 2 17.5133 2 12.0003C2 8.2433 4.075 4.8383 7.415 3.1113C7.904 2.8583 8.508 3.0493 8.762 3.5413C9.016 4.0313 8.823 4.6343 8.333 4.8883C5.66 6.2693 4 8.9943 4 12.0003C4 16.4113 7.589 20.0003 12 20.0003C16.411 20.0003 20 16.4113 20 12.0003C20 8.9943 18.34 6.2693 15.668 4.8883C15.177 4.6343 14.985 4.0313 15.239 3.5413Z"
            />
            <mask
                id="mask0_320_6510"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="2"
                y="1"
                width="20"
                height="22"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.0001 12C13.0001 12.553 12.5521 13 12.0001 13C11.4481 13 11.0001 12.553 11.0001 12V2C11.0001 1.447 11.4481 1 12.0001 1C12.5521 1 13.0001 1.447 13.0001 2V12ZM15.239 3.5413C15.492 3.0503 16.096 2.8593 16.586 3.1113C19.925 4.8383 22 8.2433 22 12.0003C22 17.5133 17.514 22.0003 12 22.0003C6.486 22.0003 2 17.5133 2 12.0003C2 8.2433 4.075 4.8383 7.415 3.1113C7.904 2.8583 8.508 3.0493 8.762 3.5413C9.016 4.0313 8.823 4.6343 8.333 4.8883C5.66 6.2693 4 8.9943 4 12.0003C4 16.4113 7.589 20.0003 12 20.0003C16.411 20.0003 20 16.4113 20 12.0003C20 8.9943 18.34 6.2693 15.668 4.8883C15.177 4.6343 14.985 4.0313 15.239 3.5413Z"
                />
            </mask>
        </svg>
    );
}
