import * as React from 'react';
import classNames from 'classnames';

export default function UserGroup(props) {
    const { className, ...others } = props;
    return (
        // eslint-disable-next-line react/no-unknown-property
        <svg
            className={classNames('icon', className)}
            vwidth="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...others}
        >
            <path
                d="M16.8795 20.3547L16.2178 15.057C16.124 14.3066 15.7594 13.6163 15.1923 13.1159C14.6253 12.6155 13.8951 12.3396 13.1388 12.3398H10.8601C10.1042 12.3401 9.37445 12.6163 8.80787 13.1166C8.2413 13.6169 7.87691 14.3069 7.78319 15.057L7.12045 20.3547C7.08408 20.6458 7.11005 20.9412 7.19663 21.2215C7.28321 21.5017 7.42842 21.7604 7.62263 21.9802C7.81683 22.2 8.05558 22.376 8.32302 22.4965C8.59046 22.6169 8.88047 22.6791 9.17379 22.6789H14.8272C15.1204 22.679 15.4103 22.6167 15.6776 22.4961C15.945 22.3756 16.1836 22.1996 16.3777 21.9798C16.5718 21.76 16.7169 21.5014 16.8034 21.2212C16.8899 20.941 16.9159 20.6457 16.8795 20.3547V20.3547Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
            <path
                d="M12.0002 8.20345C13.7132 8.20345 15.1019 6.81476 15.1019 5.10172C15.1019 3.38869 13.7132 2 12.0002 2C10.2871 2 8.89844 3.38869 8.89844 5.10172C8.89844 6.81476 10.2871 8.20345 12.0002 8.20345Z"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
            />
            <path
                d="M3.72846 11.3036C4.87048 11.3036 5.79628 10.3778 5.79628 9.23578C5.79628 8.09376 4.87048 7.16797 3.72846 7.16797C2.58644 7.16797 1.66064 8.09376 1.66064 9.23578C1.66064 10.3778 2.58644 11.3036 3.72846 11.3036Z"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
            />
            <path
                d="M20.2709 11.3036C21.413 11.3036 22.3388 10.3778 22.3388 9.23578C22.3388 8.09376 21.413 7.16797 20.2709 7.16797C19.1289 7.16797 18.2031 8.09376 18.2031 9.23578C18.2031 10.3778 19.1289 11.3036 20.2709 11.3036Z"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
            />
            <path
                d="M3.72874 14.4063H3.41236C2.92287 14.4062 2.44923 14.5798 2.0757 14.8961C1.70217 15.2125 1.45298 15.6511 1.37246 16.1339L1.02817 18.2017C0.978762 18.498 0.994486 18.8015 1.07425 19.091C1.15401 19.3806 1.2959 19.6493 1.49004 19.8785C1.68419 20.1077 1.92593 20.2918 2.19844 20.4181C2.47096 20.5443 2.76772 20.6097 3.06807 20.6097H6.83046M20.2713 14.4063H20.5876C21.0771 14.4062 21.5508 14.5798 21.9243 14.8961C22.2978 15.2125 22.547 15.6511 22.6275 16.1339L22.9718 18.2017C23.0212 18.498 23.0055 18.8015 22.9258 19.091C22.846 19.3806 22.7041 19.6493 22.51 19.8785C22.3158 20.1077 22.0741 20.2918 21.8016 20.4181C21.529 20.5443 21.2323 20.6097 20.9319 20.6097H17.1695"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </svg>
    );
}
