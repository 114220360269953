import React from 'react';
import { authTypeConstants } from 'constants/auth';
import { useAuth } from 'dataProvider/authData';
import useTransition from 'hooks/useTransition';
import Sync from 'components/icons/Sync';

export default function SwitchAccountButton({ ...rest }) {
    const {
        auth: { authType },
    } = useAuth();
    const { pushHistoryAbs } = useTransition();

    const handleClick = () => {
        // redirect to `/switch`
        pushHistoryAbs(`/switch`);
    };

    return (
        <button
            className="btn btn-outline-white btn-switch-account"
            onClick={handleClick}
            {...rest}
        >
            <Sync style={{ width: '24px', height: '24px' }} />
            {authType === authTypeConstants.USER ? '管理者切替' : 'ユーザー切替'}
        </button>
    );
}
