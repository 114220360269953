import React, { useEffect, createContext, useContext, useState } from 'react';
import { useAPIData } from 'hooks/useAPIData';
import { useGlobalData } from 'dataProvider/globalData';
import Usage from 'constants/usage';

const ClientContext = createContext({});
export default function ClientDataProvider(props) {
    const { children } = props;

    const [
        {
            auth: { isAuthenticated },
        },
    ] = useGlobalData();

    // fetch data
    const [fetch, res] = useAPIData('/client-detail', x => x, {});
    const ClientContextProvider = ClientContext.Provider;
    useEffect(() => {
        if (isAuthenticated) {
            fetch();
        }
    }, [isAuthenticated]);

    return <ClientContextProvider value={res.data}>{children}</ClientContextProvider>;
}

const useClientData = () => {
    return useContext(ClientContext);
};

const useClienHasUsage = id => {
    const { client_usages } = useClientData();
    const [hasUsage, setHasUsage] = useState(false);

    useEffect(() => {
        const _hasUsage = Array.isArray(client_usages)
            ? client_usages.find(usage => usage.usage_id == id) != null
            : null;
        setHasUsage(_hasUsage);
    }, [client_usages]);

    return [hasUsage];
};

const useClientUsages = () => {
    const { client_usages } = useClientData();
    const [clientUsages, setClientUsages] = useState(
        Object.keys(Usage).reduce((obj, key) => {
            obj[key] = false;
            return obj;
        }, {})
    );
    const [usageLoaded, setLoaded] = useState(false);

    useEffect(() => {
        const _loaded = Array.isArray(client_usages);
        setLoaded(_loaded);
        setClientUsages(
            Object.keys(Usage).reduce(
                (obj, key) => {
                    obj[key] =
                        _loaded && !!client_usages.find(usage => usage.usage_id == Usage[key]);
                    return obj;
                },
                { ...clientUsages }
            )
        );
    }, [client_usages]);

    return { clientUsages, usageLoaded };
};

export { useClientData, useClienHasUsage, useClientUsages };
