import React from 'react';

const nl2br = text =>
    text
        .split(/(?:\r\n|\r|\n)/)
        .reduce(
            (res, frag, i, arr) => [...res, frag, ...(i < arr.length - 1 ? [<br key={i} />] : [])],
            []
        );

export default nl2br;
