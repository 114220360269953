import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import Table, { useTableInstance } from 'components/ManipulatableTable';
import LoadingCircle from 'components/LoadingCircle';
import Pagination from 'components/Pagination';
import Button from 'components/Button';
import Plus from 'components/icons/Plus';
import DropdownActionCell from './DropdownActionCell';
import DownloadCSV from 'components/icons/DownloadCSV';
import AlertModal from 'components/AlertModal';
import Trash from 'components/icons/Trash';
import MobileTableLayout from './MobileTableLayout';
import TableToolbar from 'components/TableToolbar';

export default function TableLayout(props) {
    const {
        data,
        column,
        actions = [],
        topActions,
        filters,
        loading,
        currentPage = 0,
        searchKeyword = '',
        totalPage,
        labelActionDetail = '詳細',
        labelActionDelete = 'を削除する',
        modalBodyDelete = 'を削除します。よろしいですか？',
        modalTitleDelete = '削除確認',
        showSearch = true,
        showEmptyMessage = true,
        useSort,
        useRowSelect,
        isList,
        recordRenderer,
        onClickDetail,
        onClickAuthorize,
        onClickUnauthorize,
        onClickCreate,
        onClickDelete,
        onClickDeleteRecord,
        onClickPage = () => {} /* 全ページでAPI実装し終わるまではとりあえず */,
        onClickCSV,
        onClickSearch,
        onClickSort,
        className,
        ui: {
            title,
            searchLabel,
            createLabel,
            deleteModelLabelBuilder = () => '選択した項目を削除します。よろしいですか？',
            authorizeModelLabelBuilder = () => '選択した項目を有効化します。よろしいですか？',
            unauthorizeModelLabelBuilder = () => '選択した項目を無効化します。よろしいですか？',
        },
    } = props;

    /* --- ACTIONS COLUMN --- */
    const [deleteRecord, setDeleteRecord] = useState();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const _actions = useMemo(() => {
        const _actions = [...actions];

        // append `Delete` action button
        if (onClickDeleteRecord) {
            _actions.unshift({
                label: labelActionDelete,
                onClick: data => {
                    setDeleteRecord(data);
                    setIsDeleteModalOpen(true);
                },
                className: 'text-attention',
            });
        }

        // append `Show Detail` action button
        if (onClickDetail) {
            _actions.unshift({
                label: labelActionDetail,
                onClick: onClickDetail,
                className: 'text-bluegray',
            });
        }

        return _actions;
    }, [actions]);

    const _columns = useMemo(() => {
        const _columns = [...column];

        // Define Action Column
        if (_actions.length > 0) {
            _columns.push({
                accessor: '_action',
                Cell: ({ row }) => <DropdownActionCell actions={_actions} row={row} />,
                textAlign: 'center',
                className: 'column-shrink',
            });
        }

        return _columns;
    }, [column]);

    const instance = useTableInstance({
        data,
        columns: _columns,
        useSort,
        useRowSelect,
    });

    /* --- SORT --- */
    const [isInitial, setIsInitial] = useState(true);

    useEffect(() => {
        if (instance.state.sortBy?.length > 0) {
            onClickSort && onClickSort(instance.state.sortBy[0].id, !instance.state.sortBy[0].desc);
        } else if (!isInitial) {
            onClickSort && onClickSort(undefined, true);
        }

        isInitial && setIsInitial(false);
    }, [instance.state.sortBy]);

    return (
        <div className={classNames('page table-template', className)}>
            <div className="card">
                <TableToolbar
                    showSearch={showSearch}
                    searchLabel={searchLabel}
                    onClickSearch={onClickSearch}
                    filters={filters}
                    topActions={
                        <>
                            {topActions}
                            {onClickCreate && (
                                <Button
                                    className="btn-primary"
                                    onClick={onClickCreate}
                                    icon={<Plus />}
                                >
                                    {createLabel}
                                </Button>
                            )}
                            {onClickCSV && (
                                <Button
                                    className="btn-download"
                                    icon={<DownloadCSV />}
                                    onClick={onClickCSV}
                                />
                            )}
                        </>
                    }
                    searchKeyword={searchKeyword}
                />

                {isList ? (
                    <MobileTableLayout data={data} recordRenderer={recordRenderer} />
                ) : (
                    <Table
                        title={title}
                        instance={instance}
                        onClickDelete={onClickDelete}
                        onClickAuthorize={onClickAuthorize}
                        onClickUnauthorize={onClickUnauthorize}
                        deleteModelLabelBuilder={deleteModelLabelBuilder}
                        authorizeModelLabelBuilder={authorizeModelLabelBuilder}
                        unauthorizeModelLabelBuilder={unauthorizeModelLabelBuilder}
                    />
                )}
                <LoadingCircle isLoading={loading} />
                {showEmptyMessage && !loading && data.length === 0 && (
                    <div className="text-center my-3">該当するデータはありません</div>
                )}
                {!loading && data.length > 0 && (
                    <Pagination
                        onClickPage={onClickPage}
                        currentPage={currentPage}
                        totalPage={totalPage}
                    />
                )}
            </div>

            {/* Delete Modal */}
            {onClickDeleteRecord && (
                <AlertModal
                    open={isDeleteModalOpen}
                    title={modalTitleDelete}
                    icon={<Trash />}
                    modal={{
                        body: <h3 className="text-bluegray">{modalBodyDelete}</h3>,
                        positiveLabel: '削除する',
                        negativeLabel: 'キャンセル',
                    }}
                    onConfirm={() => onClickDeleteRecord(deleteRecord)}
                    onClose={() => {
                        setDeleteRecord(null);
                        setIsDeleteModalOpen(false);
                    }}
                />
            )}
        </div>
    );
}
