import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import MediaQueryManager from 'components/MediaQueryManager';
import { GlobalProviderBase } from 'dataProvider/globalData';
import BaseComponents from 'app/BaseComponents';
import { S_PrivateRoute } from 'app/PrivateRoute';
import S_LoginPage from 'containers/LoginPage/S_LoginPage';
import LogoutPage from 'containers/LogoutPage/LogoutPage';
import PasswordRoute from 'containers/PasswordRoute/PasswordRoute';
import ClientsRoute from 'containers/ClientsRoute/ClientsRoute';
import Page404 from 'containers/Page404/Page404';
import { buildAuthData, AuthProvider } from 'dataProvider/authData';
import { CookiesProvider } from 'react-cookie';
import PathDataProvider from 'dataProvider/pathData';
import RequestStateProvider from 'dataProvider/postStateData/requestStateData';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import S_DefaultPage from '../containers/DefaultRoute/S_DefaultPage';
import OtpPage from 'containers/OtpPage/OtpPage';
import { authTypeConstants } from 'constants/auth';

// Initial Global Data
const initGlobalData = {
    subDomain: 'admin',
    auth: buildAuthData(),
};

const storeAtLocalStorage = ['auth'];

function SystemRoute(props) {
    return (
        <GlobalProviderBase init={initGlobalData} storeAtLocalStorage={storeAtLocalStorage}>
            <CookiesProvider>
                <MediaQueryManager>
                    <Router>
                        <PathDataProvider>
                            <RequestStateProvider>
                                <AuthProvider>
                                    <BaseComponents>
                                        <SystemRouteInner {...props} />
                                    </BaseComponents>
                                </AuthProvider>
                            </RequestStateProvider>
                        </PathDataProvider>
                    </Router>
                </MediaQueryManager>
            </CookiesProvider>
        </GlobalProviderBase>
    );
}

function SystemRouteInner() {
    return (
        <Switch>
            <Route exact path={'/login'} component={S_LoginPage} />
            <Route exact path={'/logout'} component={LogoutPage} />
            <Route path={'/password'} component={PasswordRoute} />
            <Route exact path={'/otp'} render={(props) => <OtpPage section={authTypeConstants.SYSTEM} {...props} />} />
            <S_PrivateRoute exact path={'/'} component={S_DefaultPage} />
            <S_PrivateRoute path={'/clients'} component={ClientsRoute} />
            <Route path={'/404'} component={Page404} />
            <Redirect to={'/404'} />
        </Switch>
    );
}

Sentry.init({
    dsn: process.env.MIX_SENTRY_FRONTEND,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

ReactDOM.render(<SystemRoute />, document.getElementById('app'));
