import React, { useMemo } from 'react';
import { useTable, useSortBy, useRowSelect, useGlobalFilter } from 'react-table';
import StyledCheckbox from './StyledCheckbox';
import AlertModal from 'components/AlertModal';
import ArrowUp from './icons/ArrowUp';
import ArrowDown from './icons/ArrowDown';
import classNames from 'classnames';

const IndeterminateCheckbox = React.forwardRef(function CheckBox({ indeterminate, ...rest }, ref) {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <StyledCheckbox inputRef={resolvedRef} inputProps={rest} />;
});

export const useTableInstance = ({
    data,
    columns: _columns,
    useGlobalFilter: _useGlobalFilter,
    useSort: _useSort,
    useRowSelect: _useRowSelect,
}) => {
    //useSort, useGlobalFilterから除外するcolumnを設定
    const columns = _columns.map(_item => {
        let item = _item;
        if (Array.isArray(_useGlobalFilter) && !_useGlobalFilter.includes(item.accessor))
            item = { ...item, disableGlobalFilter: true };
        if (Array.isArray(_useSort) && !_useSort.includes(item.accessor))
            item = { ...item, disableSortBy: true };
        return item;
    });

    const args = [
        {
            data: data,
            columns: useMemo(() => columns, [_columns]),
            manualSort: true,
            autoResetSortBy: false,
        },
        _useGlobalFilter ? useGlobalFilter : null,
        _useSort ? useSortBy : null,
        _useRowSelect ? useRowSelect : null,
        _useRowSelect
            ? hooks => {
                  hooks.visibleColumns.push(columns => [
                      {
                          id: 'selection',
                          Header({ getToggleAllRowsSelectedProps }) {
                              return (
                                  <div>
                                      <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                                  </div>
                              );
                          },
                          Cell({ row }) {
                              return (
                                  <div>
                                      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                                  </div>
                              );
                          },
                          textAlign: 'center',
                      },
                      ...columns,
                  ]);
              }
            : null,
    ].filter(item => item != null);
    return useTable(...args);
};

export const GlobalFilter = props => {
    const { InputForm, inputFormProps, instance } = props;

    return (
        <InputForm
            value={instance.state.globalFilter || ''}
            onChange={e => {
                instance.setGlobalFilter(e.target.value);
            }}
            {...inputFormProps}
        />
    );
};

export default function ManipulatableTable(props) {
    const {
        instance,
        title,
        onClickDelete,
        onClickAuthorize,
        onClickUnauthorize,
        deleteModelLabelBuilder,
        authorizeModelLabelBuilder,
        unauthorizeModelLabelBuilder,
    } = props;
    const _useRowSelect = instance.selectedFlatRows != null;
    const _useSort = instance.sortedRows != null;

    //定義内で使用する変数
    const selectedRowIds = _useRowSelect ? instance.state.selectedRowIds : {};
    const isSelected = Object.keys(selectedRowIds).length !== 0;
    const isFiltered = instance.state.globalFilter != null && instance.state.globalFilter != '';

    const OperationButton = (
        onClick,
        buttonLabel,
        disabled,
        modalBodyBuilder,
        modalType,
        positiveLabel,
        negativeLabel = 'キャンセル'
    ) => {
        return (
            <AlertModal
                modal={{
                    body: modalBodyBuilder(instance.selectedFlatRows.map(data => data.original)),
                    positiveLabel,
                    negativeLabel,
                    type: modalType,
                }}
                onConfirm={onClick(instance.selectedFlatRows.map(data => data.original))}
            >
                <button
                    className={`btn btn-${modalType === 'danger' ? 'danger' : 'primary'} btn-sm`}
                    disabled={disabled}
                >
                    {buttonLabel}
                </button>
            </AlertModal>
        );
    };

    return (
        <table className="table manipulatable-table text-center" {...instance.getTableProps()}>
            <thead>
                {(isFiltered || isSelected) && (
                    <tr>
                        <th
                            className={isFiltered || isSelected ? 'selected-row' : ''}
                            colSpan={instance.visibleColumns.length}
                        >
                            {isFiltered && !isSelected && <br />}
                            {isSelected && (
                                <div className="header-selected">
                                    <p className="text-left ml-2 mb-0">
                                        {Object.keys(selectedRowIds).length}件選択中
                                    </p>
                                    {onClickAuthorize != null &&
                                        OperationButton(
                                            onClickAuthorize,
                                            '有効化',
                                            false,
                                            authorizeModelLabelBuilder,
                                            'primary',
                                            '有効化する'
                                        )}
                                    {onClickUnauthorize != null &&
                                        OperationButton(
                                            onClickUnauthorize,
                                            '無効化',
                                            false,
                                            unauthorizeModelLabelBuilder,
                                            'danger',
                                            '無効化する'
                                        )}
                                    {onClickDelete != null &&
                                        OperationButton(
                                            onClickDelete,
                                            '削除',
                                            false,
                                            deleteModelLabelBuilder,
                                            'danger',
                                            '削除する'
                                        )}
                                </div>
                            )}
                        </th>
                    </tr>
                )}
                {instance.headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, colIdx) => (
                            <th
                                {...column.getHeaderProps(
                                    _useSort ? column.getSortByToggleProps() : {}
                                )}
                                className={classNames(
                                    'table-header' +
                                        (_useSort && !column.disableSortBy ? ' sortable' : '') +
                                        (column.isSorted
                                            ? ' space-arrange-left'
                                            : ' space-arrange'),
                                    column.className || ''
                                )}
                                style={{ textAlign: column.textAlign, ...(column.style || {}) }}
                                key={colIdx}
                            >
                                {column.render('Header')}
                                <span>
                                    {column.isSorted ? (
                                        column.isSortedDesc ? (
                                            <ArrowDown />
                                        ) : (
                                            <ArrowUp />
                                        )
                                    ) : (
                                        ' '
                                    )}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...instance.getTableBodyProps()}>
                {instance.rows.map(row => {
                    instance.prepareRow(row);

                    return (
                        <tr
                            className={
                                Object.keys(selectedRowIds).includes(row.id) ? 'selected-row' : ''
                            }
                            key={row.id}
                            {...row.getRowProps()}
                        >
                            {row.cells.map((cell, i) => (
                                <td
                                    className={classNames(
                                        'table-cell',
                                        typeof cell.column.className === 'function'
                                            ? cell.column.className(cell)
                                            : cell.column.className || ''
                                    )}
                                    key={i}
                                    {...{
                                        ...cell.getCellProps(),
                                        style: { textAlign: cell.column.textAlign },
                                    }}
                                >
                                    {cell.render('Cell', cell)}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
