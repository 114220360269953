import React from 'react';

export default function CheckBox(props) {
    const { label, name, onChange, value: initValue, id } = props;
    return (
        <div className="form-check">
            <input
                className="form-check-input ml-6"
                name={name}
                type="checkbox"
                value={initValue}
                onChange={onChange}
                id={id}
            />
            <label className="form-check-label" htmlFor={id}>
                <small className="text-muted">{label}</small>
            </label>
        </div>
    );
}
