import React, { useReducer } from 'react';
import classNames from 'classnames';
import ButtonWithIcon from 'components/ButtonWithIcon';
import Accordion from 'react-bootstrap/Accordion';
import ExpandMoreIcon from 'components/icons/ExpandMore';
import ExpandLessIcon from 'components/icons/ExpandLess';

// サイドバーで拡大状態の時に使うボタン。クリックするとサブメニューを展開する。
export default function ExpandMenuAccordion(props) {
    const { children, label, contentId, Icon, handleClickLink, className } = props;
    const [open, reverseOpen] = useReducer(open => !open, false);

    const handleOpen = () => {
        reverseOpen();
    };

    const customToggle = props => {
        const isSelectedGroup = children.find(({ id }) => id === contentId);

        return (
            <ButtonWithIcon
                className={classNames(
                    'sidebar-item',
                    isSelectedGroup && !open ? ' active-item' : '',
                    className
                )}
                Icon={<Icon />}
                PostIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClickCapture={handleOpen}
                {...props}
            >
                {label}
            </ButtonWithIcon>
        );
    };

    return (
        <Accordion className={className}>
            <Accordion.Toggle as={customToggle} variant="link" eventKey="0">
                {label}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0" className="">
                <React.Fragment>
                    {children.map(({ id, label, url }) => (
                        <ButtonWithIcon
                            key={id}
                            className={'sidebar-item' + (contentId === id ? ' active-item' : '')}
                            Icon={<div className="icon">-</div>}
                            onClick={handleClickLink(url)}
                        >
                            {label}
                        </ButtonWithIcon>
                    ))}
                </React.Fragment>
            </Accordion.Collapse>
        </Accordion>
    );
}
