import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useTransition from 'hooks/useTransition';
import { usePathData } from 'dataProvider/pathData';
import { toast } from 'react-toastify';

/**
 *
 * @param {object} props
 * @param {string} props.message
 * @param {boolean} props.showSpinner
 * @param {boolean} props.showButton
 * @param {string} props.link
 * @param {string} props.route
 * @param {string|number} props.toastId
 * @param {boolean} closeInTargetPage
 *
 * + `message` トーストに表示するメッセージ
 * + `showSpinner` ロード中のスピナーを表示するか
 * + `showButton` 詳細ボタンを表示するか
 * + `link` 詳細ボタンを押した時に遷移するURL
 * + `route` トーストを呼び出したAPIコールを行ったページのURL
 * + `toastId` このトーストのID
 * + `closeInTargetPage` `link`先であるページに遷移した場合にこのトーストを消すかどうか
 */

export default function PostStateToast(props) {
    const { message, showSpinner, link, route, showButton, toastId, closeInTargetPage } = props;
    const { pushHistoryAbs } = useTransition();
    const { spaRoute: currentRoute } = usePathData();

    const handleClick = () => {
        pushHistoryAbs(link);
    };

    const [timer, setTimer] = useState(null);
    useEffect(() => {
        if (closeInTargetPage && currentRoute === link) {
            const timer = setTimeout(() => toast.dismiss(toastId), 3000);
            setTimer(timer);
        } else {
            clearTimeout(timer);
        }
        return () => clearTimeout(timer);
    }, [currentRoute, closeInTargetPage]);

    return (
        <React.Fragment>
            {showSpinner && (
                <div className="toast-spinner spinner-border spinner-border-sm" role="status" />
            )}
            <div>{message}</div>
            {showButton && route !== currentRoute && (
                <button className="detail-button btn btn-link" onClick={handleClick}>
                    詳細
                </button>
            )}
        </React.Fragment>
    );
}

PostStateToast.propTypes = {
    message: PropTypes.string.isRequired,
    showSpinner: PropTypes.bool,
    link: PropTypes.string,
    route: PropTypes.string,
    showButton: PropTypes.bool,
    toastId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    closeInTargetPage: PropTypes.bool,
};
