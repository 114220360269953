import { useState, useEffect } from 'react';
import axios from 'axios';
import { usePathData } from 'dataProvider/pathData';
import { useAuth } from 'dataProvider/authData';
import calcAPIBase from 'utils/calcAPIBase';

/**
 * API呼び出しの状態
 * + `PENDING`: 呼び出し中
 * + `FULFILLED: 呼び出し成功
 * + `REJECTED: 呼び出し失敗（エラー発生）
 */
export const ASYNC_STATE = {
    PENDING: 'pending',
    FULFILLED: 'fulfilled',
    REJECTED: 'rejected',
    WAITING: 'waiting',
};

/**
 * API呼び出し結果を表すオブジェクトを作成する関数
 * @param {object} args
 * @param {any} args.data API呼び出し結果
 * @param {string} state API呼び出しの状態
 */
export const resBuilder = ({
    data,
    state,
    currentPage = null,
    errMessage,
    original = null,
    cancelCall = () => {},
}) => ({
    data,
    state,
    loading: state === ASYNC_STATE.PENDING,
    error: state === ASYNC_STATE.REJECTED,
    waiting: state === ASYNC_STATE.WAITING,
    success: state === ASYNC_STATE.FULFILLED,
    errorMessage: state === ASYNC_STATE.REJECTED ? errMessage ?? 'no error' : 'no error',
    currentPage,
    original,
    cancelCall,
});

/**
 * Pagination付きのGETを行うカスタムフック
 * @param {string} path apiのエンドポイント。`http://..../api`より後のパスを指定する。(ex `/system/clients`)
 * @param {function} callback レスポンスの整形関数。APIのレスポンスにcallbackを適用する。
 * @param {any} initData 初期データ。APIの結果待ち中はこの値が返される。初期値は`null`。
 * @param {any} errData エラー発生時のデータ。APIコール/コールバック関数の適用でエラーが発生した場合、この値が返される。初期値は`initData`
 */
const useAPIPageData = (path, callback = a => a, initData = null, errData = initData) => {
    // Export
    const [result, setResult] = useState(
        resBuilder({ data: initData, state: ASYNC_STATE.WAITING })
    );
    const [metaData, setMetaData] = useState({});

    // Inner
    const { clientId, userType } = usePathData();
    const [parameters, setParameters] = useState({});
    const { handle401, getToken } = useAuth();

    // API url
    const apiBase = calcAPIBase(userType, clientId);
    const apiUrl = apiBase + path;
    const signal = axios.CancelToken.source();

    /**
     *
     * @param {int} page ページ番号
     * @param {*} _params パラメータ。省略した場合、前回の呼び出しと同じパラメータが適用される！！
     */
    const fetch = async (page = null, _params = null) => {
        if (!page) page = result.currentPage ?? 1;
        if (page === 'next') page = result.currentPage + 1;
        if (page === 'prev') page = result.currentPage - 1;

        const params = { page, ...(_params ?? parameters) };
        if (_params != null) setParameters(_params);

        const cancelCall = () => {
            signal.cancel('Api call canceled');
        };

        // cancel previous call
        if (result.loading) {
            result.cancelCall();
        }

        setResult(
            resBuilder({
                data: initData,
                state: ASYNC_STATE.PENDING,
                currentPage: page,
                cancelCall,
            })
        );
        try {
            const token = getToken();
            const res = await axios.get(`${apiUrl}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                params,
                cancelToken: signal.token,
            });
            // TODO: res.dataが空の場合の対応
            setResult(
                resBuilder({
                    data: callback(res.data.data.data),
                    state: ASYNC_STATE.FULFILLED,
                    currentPage: res.data.data.current_page,
                    original: res,
                })
            );
            setMetaData({
                total: res.data.data.total,
                per_page: res.data.data.per_page,
                last_page: res.data.data.last_page,
            });
            return callback(res.data.data.data);
        } catch (err) {
            if (err.response?.status == 401) {
                handle401();
            }
            if (!axios.isCancel(err)) {
                setResult(prev =>
                    resBuilder({
                        data: errData,
                        state: ASYNC_STATE.REJECTED,
                        currentPage: prev.data?.current_page,
                        original: err,
                    })
                );
            }
            throw err;
        }
    };

    const fetchOnlyMetadata = params => {
        const token = getToken();
        axios
            .get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                params,
                cancelToken: signal.token,
            })
            .then(res => {
                setMetaData({
                    total: res.data.data.total,
                    per_page: res.data.data.per_page,
                    last_page: res.data.data.last_page,
                });
            });
        return () => {
            console.log('Cleanup on fetch');
            signal.cancel('Api call canceled');
        };
    };

    return [fetch, result, metaData, fetchOnlyMetadata];
};
export default useAPIPageData;

export const useAPIData = (path, callback = a => a, initData = null, errData = initData) => {
    const [result, setResult] = useState(
        resBuilder({ data: initData, state: ASYNC_STATE.WAITING })
    );
    const { clientId, userType } = usePathData();
    const { handle401, getToken } = useAuth();

    const apiBase = calcAPIBase(userType, clientId);
    const apiUrl = apiBase + path;

    const signal = axios.CancelToken.source();

    const fetch = async params => {
        const token = getToken();

        const cancelCall = () => {
            signal.cancel('Api call canceled');
        };

        // cancel previous call
        if (result.loading) {
            result.cancelCall();
        }

        setResult(resBuilder({ data: initData, state: ASYNC_STATE.PENDING, cancelCall }));
        try {
            const res = await axios.get(`${apiUrl}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                params,
                cancelToken: signal.token,
            });
            setResult(
                resBuilder({
                    data: callback(res.data.data),
                    state: ASYNC_STATE.FULFILLED,
                    original: res,
                })
            );
            return callback(res.data.data);
        } catch (err) {
            if (err.response?.status == 401) {
                handle401();
            }
            if (!axios.isCancel(err)) {
                setResult(_prev =>
                    resBuilder({ data: errData, state: ASYNC_STATE.REJECTED, original: err })
                );
            }
            throw err;
        }
    };

    useEffect(() => {
        return () => {
            console.log('Cleanup on useAPIData');
            signal.cancel('Api call canceled');
        };
    }, []);

    return [fetch, result];
};
