import React, { useState, useEffect } from 'react';
import IconButton from 'components/IconButton';
import Notification from 'components/icons/Notification';
import Badge from 'components/Badge';
import {
    useAPINotificationList,
    useAPINotificationRead,
    useAPINotificationReadAll,
} from 'repo/user/notificationRepo';
import useTransition from 'hooks/useTransition';
import { Link } from 'react-router-dom';
import { remove } from 'lodash';
import useInterval from 'hooks/useInterval';

export default function NotificationButton() {
    const [readNotificaton] = useAPINotificationRead();
    const [readAllNotifications] = useAPINotificationReadAll();
    const [run, res] = useInterval(useAPINotificationList());

    const { pushHistoryAbs } = useTransition();

    // 実際に表示するメッセージ（「全て見る」を押すことで増減）
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);

    // 通知ドロップダウンの開閉
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(!open);
        // 開くときに強制的に一部表示に切り替える
        if (!open && showAll) toggleShowAll();
    };

    // 通知の全表示/一部表示の切り替え
    const [showAll, _setShowAll] = useState(false);
    const toggleShowAll = () => {
        setOpen(false);
        pushHistoryAbs('/notifications');
    };

    const handleNotificationClick = ({ id, action_link }) => {
        readNotificaton(id).then(() => {
            // remove from list
            remove(notifications, item => item.id == id);
            setNotifications([...notifications]);
        });

        // mark as read
        const notification = notifications.find(item => item.id == id);
        notification.read_at = new Date().toDateString();
        setNotifications([...notifications]);

        // decrease unread count
        setUnreadCount(unreadCount - 1);

        pushHistoryAbs(action_link);

        // close notification list
        setOpen(false);

        return false;
    };

    const handleReadAllNotifications = () => {
        readAllNotifications().then(() => {
            // mark all as read
            notifications.forEach(item => (item.read_at = new Date().toDateString()));
            setNotifications([...notifications]);
        });

        // decrease unread count
        setUnreadCount(0);

        // close notification list
        setOpen(false);
    };

    // start fetch
    useEffect(() => {
        run(1);
    }, []);

    useEffect(() => {
        if (res && res.success) {
            // add unique data
            const data = [
                ...res.data.filter(item => !notifications.find(msg => msg.id == item.id)),
                ...notifications,
            ];
            setNotifications(data);
            setUnreadCount(res.original.data.data.meta.unread_count);
        }
    }, [res]);

    return (
        <React.Fragment>
            <IconButton
                className={'notification-button' + (open ? ' open' : '')}
                onClick={handleOpen}
                Badge={<Badge number={unreadCount} />}
                title="通知"
            >
                <Notification className="icon-white" />
            </IconButton>
            {open && (
                <div className="notification-menu card">
                    <ul className="list-group">
                        <li className="list-group-item menu-item-aside d-flex">
                            <span className="font-weight-bold">お知らせ</span>
                            <button
                                className="btn btn-link close-button"
                                onClick={handleReadAllNotifications}
                            >
                                全て既読にする
                            </button>
                        </li>
                        {notifications.map((notification, i) => (
                            <li
                                key={i}
                                className={`list-group-item ${notification.read_at ? 'read' : ''}`}
                            >
                                {!notification.read_at && <i className="circle-indicator" />}
                                <Link
                                    to={notification.action_link}
                                    onClick={() => handleNotificationClick(notification)}
                                >
                                    {notification.data.title}
                                </Link>
                            </li>
                        ))}
                        <li className="list-group-item menu-item-aside bold text-center">
                            <span className="cursor-pointer" onClick={toggleShowAll}>
                                {showAll ? '一部をおりたたむ' : '全ての通知をみる'}
                            </span>
                        </li>
                    </ul>
                </div>
            )}
        </React.Fragment>
    );
}
