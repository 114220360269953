import { authTypeConstants } from 'constants/auth';
import { useAuth } from 'dataProvider/authData';
import { useAPIRequest } from 'dataProvider/postStateData/requestStateData';

/**
 * Get User Detail
 * @return [fetch, state]
 */
export function useAPIUserDetail() {
    const { get, states } = useAPIRequest();

    const requestKey = 'user.auth.me';
    const fetch = () =>
        get(requestKey, '/auth/me', null, null, {
            loading: 'ユーザー情報を取得しています',
            success: 'ユーザー情報の取得が完了しました',
            error: 'ユーザー情報の取得に失敗しました',
        });

    return [fetch, states(requestKey)];
}

/**
 * Post User Registration
 * @return [fetch, state]
 */
export function useAPIUserRegistration() {
    const { post, states } = useAPIRequest();
    const { updateAuthUser } = useAuth();

    const requestKey = 'user.auth.register';
    const fetch = data =>
        post(requestKey, '/auth/register', data, x => x, {
            loading: 'ユーザー情報を登録しています',
            success: 'ユーザー情報の登録が完了しました',
            error: 'ユーザー情報の登録に失敗しました',
        }).then(() => {
            updateAuthUser(data);
        });

    return [fetch, states(requestKey)];
}

/**
 * Post User Registration via Invitation URL
 * @return [fetch, state]
 */
export function useAPIUserRegistrationViaInvitationURL() {
    const { post, states } = useAPIRequest();
    const { updateAuthUser } = useAuth();

    const requestKey = 'user.auth.register.viaInvitationURL';
    const fetch = data =>
        post(requestKey, '/auth/register_via_url', data, x => x, {
            loading: 'ユーザー情報を登録しています',
            success: 'ユーザー情報の登録が完了しました',
            error: 'ユーザー情報の登録に失敗しました',
        }).then(() => {
            updateAuthUser(data);
        });

    return [fetch, states(requestKey)];
}

/**
 * Post User Login
 * @return [fetch, state]
 */
export function useAPIUserLogin() {
    const { post, states } = useAPIRequest();
    const { setAuth } = useAuth();

    const requestKey = 'user.auth.login';
    const fetch = data =>
        post(requestKey, '/auth/login', data, x => x, {
            loading: 'ログイン処理中です',
            success: 'ログインが完了しました',
            error: 'ログインに失敗しました',
        }).then(res => {
            setAuth({
                token: res.data.data.access_token,
                authType: authTypeConstants.USER,
                user: res.data.data.user,
                tokenExpiresIn: new Date().getTime() + res.data.data.expires_in * 1000, // in millisecond
            });
        });

    return [fetch, states(requestKey)];
}

/**
 * Refresh Token
 * @return [fetch, state]
 */
export function useAPIUserRefreshToken() {
    const { post, states } = useAPIRequest();
    const { auth, setAuth } = useAuth();

    const requestKey = 'user.auth.refreshToken';
    const fetch = () =>
        post(requestKey, '/auth/refresh', {}, x => x, null).then(res => {
            auth.token = res.data.data.access_token;
            auth.tokenExpiresIn = new Date().getTime() + res.data.data.expires_in * 1000; // in millisecond
            setAuth(auth);
        });

    return [fetch, states(requestKey)];
}

/**
 * Switch Account
 * @returns [fetch, state]
 */
export function useAPISwitchAccount() {
    const { post, states } = useAPIRequest();
    const { setAuth } = useAuth();

    const requestKey = 'user.auth.switchAccount';
    const fetch = async data => {
        const res = await post(requestKey, '/auth/switch', data, x => x, {
            loading: 'アカウント切り替え中です',
            success: 'アカウント切り替えが完了しました',
            error: 'アカウント切り替えに失敗しました',
        });

        const newAuthData = {
            token: res.data.data?.access_token,
            authType: authTypeConstants.ADMIN,
            user: res.data.data?.user,
            tokenExpiresIn: new Date().getTime() + res.data.data?.expires_in * 1000, // in millisecond
        };
        setAuth(newAuthData);

        return newAuthData;
    };

    return [fetch, states(requestKey)];
}
