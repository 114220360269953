import React from 'react';
import { Redirect } from 'react-router-dom';
import BaseFrame from 'templates/PageFrame';
import LoginPageLayout from 'templates/LoginLayout/LoginLayout';
import { useAuth } from 'dataProvider/authData';
import { useAPIUserLogin } from 'repo/system/authRepo';
import { useRouteCallback } from '../../hooks/useRouteCallbackHandler';
import { authTypeConstants } from 'constants/auth';

export default function S_LoginPage() {
    const { auth } = useAuth();
    const [login] = useAPIUserLogin();
    const { addCallbackToUrl } = useRouteCallback();

    // Handle Login Action
    const handleLogin = ({ id, password, remember }) => {
        login({ email: id, password: password, remember: remember });
    };

    return auth.isAuthenticated && auth?.authType == authTypeConstants.SYSTEM ? (
        <Redirect to={addCallbackToUrl('/')} />
    ) : (
        <BaseFrame>
            <LoginPageLayout onConfirm={handleLogin} />
        </BaseFrame>
    );
}
