import classNames from 'classnames';
import ChangeRootAccountButton, {
    useChangeRootAccountForClient,
} from 'components/ChangeRootAccountButton';
import InputForm from 'components/InputForm';
import { mailTemplateType } from 'constants/mailTemplateType';
import { useAPIRequest } from 'dataProvider/postStateData/requestStateData';
import { useAPIData } from 'hooks/useAPIData';
import useTransition from 'hooks/useTransition';
import React, { useEffect } from 'react';
import TextareaAutoSize from 'react-textarea-autosize';
import DataEditLayout, {
    DeleteButton,
    ResendButton,
    StatusChangeButton,
} from 'templates/DataEditLayout/DataEditLayout';
import URLForm from '../components/URLForm';
import { EDIT_FORM_VALIDATION, UPDATE_FORM_DEFAULT_VALUES } from '../form/constants';
import { eFormMode, eLimitedTextName } from '../form/enums';
import {
    EMAIL_WORD_COUNT,
    SMS_WORD_COUNT,
    useClientsForm,
    usernameTypeOptions,
} from '../form/useClientsForm';
import { convertFormIntoPayload, convertUsageIdsIntoForm } from '../form/utils';

const ROUTE_PREFIX = process.env.MIX_CLIENT_URL_ORIGIN_DISPLAY;

export default function ClientsDetail(props) {
    const { pushHistoryParent } = useTransition();
    const { put: _put, deleteCall, states } = useAPIRequest();
    const {
        formData,
        handleChangeData,
        planOptions,
        handleLimitedTextInput,
        resetForm,
    } = useClientsForm(UPDATE_FORM_DEFAULT_VALUES);
    const requestKey = `system.clients.detail.${props.match.params.clientId}`;
    const changeRootAdminInstance = useChangeRootAccountForClient(
        formData.id,
        formData.root_admin,
        () => {
            fetch();
        }
    );

    // Fetch Data Hook
    const [fetch, res] = useAPIData(
        `/clients/${props.match.params.clientId}`,
        res => ({
            ...convertUsageIdsIntoForm(res.client_usages.map(item => item.usage_id)),
            id: res.id,
            name: res.name,
            route: res.route,
            root_admin: res.root_admin,
            root_admin_email: res.root_admin?.email || '',
            root_admin_phone_number: res.root_admin?.phone_number || '',
            plan_id: res.plan?.id,
            createdAt: res.created_at,
            updatedAt: res.updated_at,
            is_suspended: !res.is_suspended ? 'valid' : 'invalid',
            admin_email_template:
                res.mail_templates.find(item => item.type == mailTemplateType.RegistrationAdmin)
                    ?.body_email ?? '',
            admin_sms_template:
                res.mail_templates.find(item => item.type == mailTemplateType.RegistrationAdmin)
                    ?.body_sms ?? '',
            user_email_template:
                res.mail_templates.find(item => item.type == mailTemplateType.RegistrationUser)
                    ?.body_email ?? '',
            user_sms_template:
                res.mail_templates.find(item => item.type == mailTemplateType.RegistrationUser)
                    ?.body_sms ?? '',
            categories_threshold: res.categories_threshold || 0,
            users_threshold: res.users_threshold || 0,
        }),
        UPDATE_FORM_DEFAULT_VALUES
    );

    // Init
    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        if (res.success) {
            resetForm(res.data);
        }
    }, [res.success]);

    // API Put
    const handleUpdate = () => {
        _put(
            requestKey,
            '/clients/' + formData.id,
            formData,
            form => convertFormIntoPayload(form, eFormMode.update),
            {
                loading: `企業の登録情報を変更中です`,
                success: `企業の登録情報の変更が完了しました`,
                error: `企業の登録情報の変更に失敗しました`,
            }
        );
    };

    // Handle Resend Email Action
    const handleResendMail = () => {
        _put(requestKey, `/clients/${formData.id}/reset-password`, formData, () => { }, {
            loading: `登録メールを再送中です`,
            success: `登録メールを再送しました`,
            error: `登録メールの再送に失敗しました`,
        });
    };

    // Handle Status Change Action
    const handleChangeStatus = () => {
        const isCurrentValid = formData.is_suspended === 'valid';
        const newClientData = {
            ...formData,
            is_suspended: isCurrentValid ? 'invalid' : 'valid',
        };
        const message = isCurrentValid ? '無効化' : '有効化';
        _put(
            requestKey,
            '/clients/' + formData.id,
            newClientData,
            formData => ({
                name: formData.name,
                plan_id: formData.plan_id,
                route: formData.route,
                is_suspended: isCurrentValid,
                categories_threshold: formData.categories_threshold,
                users_threshold: formData.users_threshold,
            }),
            {
                loading: `企業の${message}処理中です`,
                success: `企業の${message}が完了しました`,
                error: `企業の${message}に失敗しました`,
            }
        ).then(() => {
            // updateClientData(newClientData);
        });
    };

    // Handle Delete Action
    const handleDelete = () => {
        deleteCall(requestKey, '/clients/' + formData.id, formData, () => { }, {
            loading: `企業を削除しています`,
            success: `企業を削除しました`,
            error: `企業の削除に失敗しました`,
        }).then(() => {
            pushHistoryParent();
        });
    };

    // View
    const BottomButtons = () => {
        const isCurrentValid = formData.is_suspended === 'valid';
        const disabled = res.loading || states(requestKey).loading;
        return (
            <div className="d-flex">
                <ResendButton onClick={handleResendMail} disabled={disabled} />
                <ChangeRootAccountButton {...changeRootAdminInstance} />
                <StatusChangeButton
                    isCurrentValid={isCurrentValid}
                    onClick={handleChangeStatus}
                    ui={{ targetName: 'クライアント' }}
                    disabled={disabled}
                />
                <DeleteButton
                    onClick={handleDelete}
                    disabled={disabled}
                    ui={{ targetName: 'クライアント' }}
                />
            </div>
        );
    };

    return (
        <DataEditLayout
            title="企業情報"
            className="page client-detail-page page-narrow-2"
            data={formData}
            handleData={handleChangeData}
            onUpdateConfirm={handleUpdate}
            validation={EDIT_FORM_VALIDATION}
            loading={res.loading}
            loadError={res.error}
            pending={states(requestKey).loading}
            requestError={states(requestKey).error}
            requestErrorMessage={states(requestKey).errorMessage}
            buttomArea={<BottomButtons />}
            modal={{
                body: '企業情報を変更します。よろしいですか。',
                positiveLabel: 'はい',
                negativeLabel: 'いいえ',
            }}
        >
            {editMode => [
                <h1 className="header" key="basic-info-header">
                    基本情報
                </h1>,
                <InputForm type="text" label="ID" name="id" key="id" />,
                <InputForm
                    type={editMode ? 'normal' : 'text'}
                    label="企業名"
                    name="name"
                    key="name"
                />,
                <InputForm type="custom" label="URL" name="route" key="route">
                    <URLForm type={editMode ? 'normal' : 'text'} prefix={ROUTE_PREFIX} />
                </InputForm>,
                <InputForm
                    disabled
                    type="text"
                    label="管理者メールアドレス"
                    name="root_admin_email"
                    key="root_admin_email"
                    placeholder="admin@sample.com"
                />,
                <InputForm
                    disabled
                    type="text"
                    label="管理者電話番号"
                    name="root_admin_phone_number"
                    key="root_admin_phone_number"
                    placeholder="0312345678"
                />,
                <InputForm
                    disabled
                    type="text"
                    label="登録日時"
                    name="createdAt"
                    key="createdAt"
                />,
                <InputForm type="text" label="最終更新" name="updatedAt" key="updatedAt" />,
                <div key="spacer2" className="card-divider" />,
                <h1 className="header" key="settings-header">
                    設定
                </h1>,
                <InputForm
                    type="select"
                    label="Setting Type"
                    name="plan_id"
                    key="plan_id"
                    options={planOptions}
                    reInitOnOptionChanged={false}
                />,
                <InputForm
                    type="switch"
                    label="ファイルアップロードを許可する"
                    name="allowFileUpload"
                    key="allowFileUpload"
                />,
                <InputForm
                    type="select"
                    label="ユーザー名の制限"
                    name="usernameType"
                    key="usernameType"
                    options={usernameTypeOptions}
                />,
                <InputForm
                    type="switch"
                    label="カテゴリに内部通報を含めない"
                    name="notIncludeReportRole"
                    key="notIncludeReportRole"
                />,
                <InputForm
                    type="switch"
                    label="CSVファイルのダウンロードを無効にする"
                    name="disableCSVDownload"
                    key="disableCSVDownload"
                />,
                <InputForm
                    type="switch"
                    label="IPアドレス制限を無効にする"
                    name="disableAccessRestriction"
                    key="disableAccessRestriction"
                />,
                <InputForm
                    type="switch"
                    label="権限管理を無効にする"
                    name="disableRoleManagement"
                    key="disableRoleManagement"
                />,
                <InputForm
                    type="switch"
                    label="返信の修正機能を無効にする"
                    name="disableReplyModification"
                    key="disableReplyModification"
                />,
                <InputForm
                    type="switch"
                    label="管理者の追加を無効にする"
                    name="disableAddAdmin"
                    key="disableAddAdmin"
                />,
                <InputForm
                    type="switch"
                    label="グルーピング機能を無効にする"
                    name="disableUserGroup"
                    key="disableUserGroup"
                />,
                <InputForm
                    type="switch"
                    label="投稿者のグループ表示を無効にする"
                    name="disableGroupFilter"
                    key="disableGroupFilter"
                />,
                <InputForm
                    type="switch"
                    label="電話番号での招待を無効にする"
                    name="disableInvitationByPhone"
                    key="disableInvitationByPhone"
                />,
                <InputForm
                    type="switch"
                    label="クローズ機能を無効にする"
                    name="disablePostClose"
                    key="disablePostClose"
                />,
                <InputForm
                    type="switch"
                    label="アナウンス機能を無効にする"
                    name="disableAnouncementFunction"
                    key="disableAnouncementFunction"
                />,
                <InputForm
                    type="switch"
                    label="ユーザーのURL招待を無効にする"
                    name="disableUserInvitationUrlFunction"
                    key="disableUserInvitationUrlFunction"
                />,
                <InputForm
                    type="switch"
                    label="セキュリティポリシーを無効にする"
                    name="disableSecurityPolicy"
                    key="disableSecurityPolicy"
                />,
                <InputForm
                    type="switch"
                    label="外部投稿を無効にする"
                    name="disableExternalPost"
                    key="disableExternalPost"
                />,
                <InputForm
                    type="custom"
                    label="カテゴリ数の制限"
                    name="categories_threshold"
                    key="limitCategoryCount"
                >
                    {props => <input {...props} type="number" className="form-control" min={-1} />}
                </InputForm>,
                <InputForm
                    type="custom"
                    label="ユーザーの招待数の上限"
                    name="users_threshold"
                    key="limitUserCount"
                >
                    {props => <input {...props} type="number" className="form-control" min={-1} />}
                </InputForm>,
                <div key="spacer3" className="card-divider" />,
                <h1 className="header" key="template-header">
                    Admin テンプレート
                </h1>,
                <div key="admin_email_template">
                    <h3 className="h6 mb-2">メール用テンプレート</h3>
                    <div className="input-area">
                        {editMode ? (
                            <React.Fragment>
                                <TextareaAutoSize
                                    name="admin_email_template"
                                    className="form-control styled-input-white contents-textarea"
                                    value={formData.admin_email_template}
                                    onChange={handleLimitedTextInput(eLimitedTextName.email)}
                                    disabled={states(requestKey).loading}
                                    minRows={3}
                                />
                                <div
                                    className={classNames(
                                        'counter text-right',
                                        formData.admin_email_template.length > EMAIL_WORD_COUNT &&
                                        'danger'
                                    )}
                                >
                                    {formData.admin_email_template.length}/{EMAIL_WORD_COUNT}文字
                                </div>
                            </React.Fragment>
                        ) : (
                            <pre
                                className="input-area-text styled-input-white contents-textarea"
                                style={{ minHeight: '150px' }}
                            >
                                {formData.admin_email_template}
                            </pre>
                        )}
                    </div>
                </div>,
                <div key="admin_sms_template">
                    <h3 className="h6 mb-2">SMS用テンプレート</h3>
                    <div className="input-area">
                        {editMode ? (
                            <React.Fragment>
                                <TextareaAutoSize
                                    name="admin_sms_template"
                                    className="form-control styled-input-white contents-textarea"
                                    value={formData.admin_sms_template}
                                    onChange={handleLimitedTextInput(eLimitedTextName.sms)}
                                    disabled={states(requestKey).loading}
                                    minRows={3}
                                />
                                <div
                                    className={classNames(
                                        'counter text-right',
                                        formData.admin_sms_template.length > SMS_WORD_COUNT &&
                                        'danger'
                                    )}
                                >
                                    {formData.admin_sms_template.length}/{SMS_WORD_COUNT}文字
                                </div>
                            </React.Fragment>
                        ) : (
                            <pre
                                className="input-area-text styled-input-white contents-textarea"
                                style={{ minHeight: '150px' }}
                            >
                                {formData.admin_sms_template}
                            </pre>
                        )}
                    </div>
                </div>,
                <div key="spacer3" className="card-divider" />,
                <h1 className="header" key="template-header">
                    User テンプレート
                </h1>,
                <div key="user_email_template">
                    <h3 className="h6 mb-2">メール用テンプレート</h3>
                    <div className="input-area">
                        {editMode ? (
                            <React.Fragment>
                                <TextareaAutoSize
                                    name="user_email_template"
                                    className="form-control styled-input-white contents-textarea"
                                    value={formData.user_email_template}
                                    onChange={handleLimitedTextInput(eLimitedTextName.email)}
                                    disabled={states(requestKey).loading}
                                    minRows={3}
                                />
                                <div
                                    className={classNames(
                                        'counter text-right',
                                        formData.user_email_template.length > EMAIL_WORD_COUNT &&
                                        'danger'
                                    )}
                                >
                                    {formData.user_email_template.length}/{EMAIL_WORD_COUNT}文字
                                </div>
                            </React.Fragment>
                        ) : (
                            <pre
                                className="input-area-text styled-input-white contents-textarea"
                                style={{ minHeight: '150px' }}
                            >
                                {formData.user_email_template}
                            </pre>
                        )}
                    </div>
                </div>,
                <div key="user_sms_template">
                    <h3 className="h6 mb-2">SMS用テンプレート</h3>
                    <div className="input-area">
                        {editMode ? (
                            <React.Fragment>
                                <TextareaAutoSize
                                    name="user_sms_template"
                                    className="form-control styled-input-white contents-textarea"
                                    value={formData.user_sms_template}
                                    onChange={handleLimitedTextInput(eLimitedTextName.sms)}
                                    disabled={states(requestKey).loading}
                                    minRows={3}
                                />
                                <div
                                    className={classNames(
                                        'counter text-right',
                                        formData.user_sms_template.length > SMS_WORD_COUNT &&
                                        'danger'
                                    )}
                                >
                                    {formData.user_sms_template.length}/{SMS_WORD_COUNT}文字
                                </div>
                            </React.Fragment>
                        ) : (
                            <pre
                                className="input-area-text styled-input-white contents-textarea"
                                style={{ minHeight: '150px' }}
                            >
                                {formData.user_sms_template}
                            </pre>
                        )}
                    </div>
                </div>,
            ]}
        </DataEditLayout>
    );
}
