import React, { useState, useEffect } from 'react';
import Logo from 'components/icons/Logo';
import TwoLinesLabelledInput from 'components/TwoLinesLabelledInput';
import { useAPIRequest } from 'dataProvider/postStateData/requestStateData';
import useData from 'hooks/useDataState';
import useTransition from 'hooks/useTransition';
import PasswordRuleMessage from 'templates/RegisterLayout/components/PasswordRuleMessage';

const requestKey = 'password.reset';

export default function PasswordReset(props) {
    const token = props.match.params.token;
    const [isTokenUnset, setIsTokenUnset] = useState(false);
    const [serverValidationErrors, setServerValidationErrors] = useState(null);

    useEffect(() => {
        if (token == null || token === '') {
            setIsTokenUnset(true);
        }
    }, []);

    // 入力データ
    const [data, setData] = useData({ password: '', passwordConfirmation: '' });

    const handleChange = e => {
        setData({ ...data, [e.target.name]: e.target.value });
        setError(false);
    };

    // Post
    const { post, states } = useAPIRequest();

    const sendPassword = () => {
        post(
            requestKey,
            '/auth/password/reset',
            {
                token,
                password: data.password,
                password_confirmation: data.passwordConfirmation,
            },
            x => x,
            {
                loading: `パスワードを再設定しています`,
                success: `パスワードの再設定が完了しました`,
                error: `パスワードの再設定に失敗しました`,
            }
        );
    };

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        if (states(requestKey).success) {
            setSuccess(true);
        } else if (states(requestKey).errors) {
            setServerValidationErrors(states(requestKey).errors);
        } else if (states(requestKey).error) {
            setError(true);
        } else {
            setError(false);
        }
    }, [states(requestKey).state]);

    // ページ遷移
    const { pushHistoryAbs } = useTransition();
    const moveToLoginPage = () => {
        pushHistoryAbs('/login');
    };

    return (
        <div className="page login-template">
            <div className="card p-4">
                <Logo className="card-img-top logo icon-primary" />
                <div className="card-body">
                    {success ? (
                        <React.Fragment>
                            <div className="text-center">パスワードの再設定が完了しました。</div>
                            <div className="mt-5 text-center">
                                <button
                                    type="button"
                                    className="btn btn-block btn-secondary px-4"
                                    onClick={moveToLoginPage}
                                >
                                    ログインページへ移動
                                </button>
                            </div>
                        </React.Fragment>
                    ) : isTokenUnset ? (
                        <div className="text-center">
                            ※URLが間違っている可能性があります。
                            <br />
                            再度ご確認ください。
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="text-center mb-5">
                            <PasswordRuleMessage token={token} />
                            </div>
                            <TwoLinesLabelledInput
                                label="新しいパスワードを設定してください。"
                                password
                                name="password"
                                value={data.password}
                                onChange={handleChange}
                                error={serverValidationErrors?.password}
                            />
                            <TwoLinesLabelledInput
                                label="もう一度新しいパスワードを入力してください。"
                                password
                                name="passwordConfirmation"
                                value={data.passwordConfirmation}
                                onChange={handleChange}
                                error={serverValidationErrors?.passwordConfirmation}
                            />
                            {error && (
                                <div className="text-center mt-2">
                                    <small className="danger">エラーが発生しました</small>
                                </div>
                            )}
                            <div className="mt-5 text-center">
                                <button
                                    type="button"
                                    className="btn btn-block btn-secondary px-4"
                                    onClick={sendPassword}
                                    disabled={states(requestKey).loading}
                                >
                                    パスワードを変更
                                </button>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}
