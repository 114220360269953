import MainLayout from './MainLayout';
import BlankLayout from './BlankLayout';

const DEFAULT_LAYOUT = MainLayout;

// Layouts for specific routes
export const LAYOUT_DATA = [{ route: '/switch', layout: BlankLayout }];

/**
 * Get a layout for a route
 *
 * @param {string} route
 * @returns {React.Component}
 */
export const getLayout = route => {
    const layout = LAYOUT_DATA.find(({ route: _route }) => {
        // find a route with the same prefix
        return route.slice(0, _route.length) === _route;
    });

    return layout ? layout.layout : DEFAULT_LAYOUT;
};
