export default `この度、当社でクラウド目安箱サービスを導入することとなりました。

普段の業務の中で、匿名で会社に伝えたいことがございましたら、こちらのサービスからご投稿ください。
外部のサービスを利用することで匿名性を保証することが可能になっておりますので、安心してご利用ください。
投稿は、運営メンバーで責任を持って対応させていただきます。

下記のログインというボタンをクリックした後、メールアドレスとパスワードを入力してログインをお願いします。
ログイン後、基本情報を登録していただくことで、ご利用可能になります。
基本情報の登録にて実名の記入もございますが、実名投稿時のみに利用するものとなっていますので、ご安心ください。
`;
