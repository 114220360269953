import React from 'react';
import AlertModal from 'components/AlertModal';

/**
 * メール再送ボタン
 * @param {object} props
 * @param {Function} props.onClick
 * @param {boolean} props.disabled
 */
export default function ResendButton(props) {
    const { onClick, disabled } = props;

    return (
        <AlertModal
            modal={{
                body: '登録メールを再送します。よろしいですか？',
                positiveLabel: '再送する',
                negativeLabel: 'キャンセル',
            }}
            onConfirm={onClick}
        >
            <button className="btn btn-outline-primary mx-2" disabled={disabled}>
                登録メールを再送する
            </button>
        </AlertModal>
    );
}
